import { Component, OnInit } from '@angular/core';
import { AthleteCardComponent } from '../../shared/components/athlete-card/athlete-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ActivatedRoute } from '@angular/router';
import { AthleteService } from '../../services/athlete/athlete.service';
import { AllUsers } from '../../models/athlete/athlete.model';
import { CardTemplateComponent } from '../../shared/components/card-template/card-template.component';


@Component({
  selector: 'app-view-all',
  standalone: true,
  imports: [
    AthleteCardComponent,
    MatCardModule,
    MatGridListModule,
    MatSelectModule,
    MatFormFieldModule,
    CardTemplateComponent
    
  ],
  templateUrl: './view-all.component.html',
  styleUrl: './view-all.component.css'
})
export class ViewAllComponent implements OnInit  {
  loading=false
  allUsers: AllUsers[]=[]
  i=0
  id=""
  value!: number
constructor(private readonly athleteService: AthleteService, 
  private route: ActivatedRoute,) {}




  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.id = params['id']; // Access the 'id' parameter from the URL
    });
    this.value = Number(this.id)
    await this.getAllUsers()
  }

  //currently we pull all users so we need a way to view athletes and other roles from that.

  async getAllUsers() {
    try {
      this.loading = true
      const { data: allUsers, error, status } = await this.athleteService.viewAllUsers()

      if (error && status !== 406) {
        throw error
      }

      if (allUsers) {
        this.allUsers = allUsers
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }
}

