/*export interface CartItem {
    eventName: string;
    ticketName: string;
    priceName: string;
    ticketId: string;
    ticketPriceId: string;
    priceCents: number;
    quantity: number;
  }*/

export interface BaseCartItem {
  id: string; // Unique identifier for the cart item (can be UUID)
  type: 'ticket' | 'registration' | 'physical'; // Discriminator for the item type
  quantity: number;
}

// For Ticket Items
export interface TicketCartItem extends BaseCartItem {
  type: 'ticket';
  ticketId: string;
  ticketPriceId: string;
  attendees: AttendeeInfo[]; // New field
}

// For Physical Items
export interface PhysicalCartItem extends BaseCartItem {
  type: 'physical';
  ticketId: string;
  physicalPriceId: string;
  attendees: AttendeeInfo[]; // New field
}

// For Registration Items
export interface RegistrationCartItem extends BaseCartItem {
  type: 'registration';
  registrationId: string;
  registrationPriceId: string;
  registrants: RegistrantInfo[]; // New field
}

// Union Type for All Cart Items
export type CartItem = TicketCartItem | RegistrationCartItem | PhysicalCartItem;

export type NewCartItem =
  | Omit<TicketCartItem, 'id'>
  | Omit<RegistrationCartItem, 'id'>
  | Omit<PhysicalCartItem, 'id'>;

  // Attendee and Registrant Info Interfaces
export interface AttendeeInfo {
  [key: string]: any; // Dynamic fields based on required information
}

export interface RegistrantInfo {
  [key: string]: any; // Dynamic fields based on required information
}

// DetailedCartItem Interface
export interface DetailedCartItem {
  cartItem: CartItem;
  eventName: string;
  itemName: string; // ticketName or registrationType
  priceCents: number;
  feeCents: number;
  priceName?: string; // Optional, based on item type
  ticket_fields: RequiredFields[]; // Optional: Fields required for attendees or registrants
}

// Interface for required fields
export interface RequiredFields{
  field_id: Fields
  required: boolean
 }
 export interface Fields{
   name: string
   label: string
   type: string
 }

export function isTicketCartItem(item: CartItem): item is TicketCartItem {
  return item.type === 'ticket';
}