<!-- success.component.html -->

<div class="success-page mat-typography">
  <mat-card class="success-card">
    <mat-card-header>
      <mat-icon color="primary" aria-hidden="true">check_circle</mat-icon>
      <mat-card-title>Payment Successful!</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Thank you for your purchase.</p>
     <!-- <p><strong>Session ID:</strong> {{ checkoutSession.session_id }}</p>
      
      <div *ngIf="checkoutSession.cart_it">
        <h2>Your Cart</h2>
        <mat-list>
          <mat-list-item *ngFor="let item of checkoutSession.cart_it">
            <span>{{ item.name }} - {{ item.quantity }} x {{ item.price | currency }}</span>
          </mat-list-item>
        </mat-list>
        <p><strong>Total:</strong> {{ calculateTotal(checkoutSession.cart_it) | currency }}</p>
      </div>-->
    </mat-card-content>
    <mat-card-actions>
      <a mat-raised-button color="primary" href="/">Return to Home</a>
    </mat-card-actions>
  </mat-card>

  <div *ngIf="isLoading" class="loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Loading your session details...</p>
  </div>

  <!--<div *ngIf="!isLoading && errorMessage" class="error">
    <mat-icon color="warn">error</mat-icon>
    <p>{{ errorMessage }}</p>
    <a mat-raised-button href="/">Return to Home</a>
  </div>-->
</div>
