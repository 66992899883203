import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OldEventService, EventDTO, Event } from '../../services/old-event.service';

@Component({
  selector: 'app-add-event',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule 
  ],
  templateUrl: './add-event.component.html',
  styleUrl: './add-event.component.css'
})
export class AddEventComponent implements OnInit {

  @Input() meetId!: string; // Receive meetId from parent component
  eventForm: FormGroup;
  isLoading = false;
  events: Event[] = [];

  constructor(
    private fb: FormBuilder,
    private eventService: OldEventService,
    private snackBar: MatSnackBar
  ) {
    this.eventForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      fee: [0, [Validators.required, Validators.min(0)]],
      isFlatFee: [false]
    });
  }

  ngOnInit(): void {
    this.loadEvents();
  }

  loadEvents() {
    // Implement fetching events by meetId if needed
    // For demonstration, assume events are passed from parent or fetched via service
  }

  onSubmit() {
    if (this.eventForm.invalid) {
      return;
    }
    this.isLoading = true;
    const eventData: EventDTO = {
      name: this.eventForm.value.name,
      description: this.eventForm.value.description,
      fee: this.eventForm.value.fee,
      isFlatFee: this.eventForm.value.isFlatFee
    };
    this.eventService.addEvent(this.meetId, eventData).subscribe({
      next: (event) => {
        this.isLoading = false;
        this.snackBar.open('Event added successfully!', 'Close', { duration: 3000 });
        this.events.push(event);
        this.eventForm.reset({ fee: 0, isFlatFee: false });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(`Error: ${error.error.message}`, 'Close', { duration: 5000 });
      }
    });
  }

  // Implement deleteEvent if needed
}