import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { AuthSession } from '@supabase/supabase-js'
import { CommonModule } from '@angular/common';
import { AthleteCardComponent } from '../athlete-card/athlete-card.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileService } from '../../../services/profile/profile.service';
import { AthleteService } from '../../../services/athlete/athlete.service';
import { AuthenticationService } from '@mosaicdash/services';
import { Profile } from '../../../models/profile/profile.model';
import { Social, CardData, TeamData } from '../../../models/athlete/athlete.model';
import { FinancesComponent } from '../finances/finances.component';
import { CreateEventComponent } from "../create-event/create-event.component";
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { Athlete } from '../../../models/new-athlete/new-athlete.model';


@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    AthleteCardComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    FinancesComponent,
    CreateEventComponent,
    MatSelectModule,
    MatOptionModule
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {
  loading = false
  profile!: Profile
  social!: Social
  cardData!: CardData
  teamData!: TeamData
  userData = false
  publicData = false
  notes = false
  userID = ""
  buttonClicked = 0
  step = 0;
  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 3; // Total number of tabs change when we turn on finance
  selectedAthlete!: Athlete
  athletes: Athlete[] = []
  testV = ''



  @Input()
  session!: AuthSession



  /* get cardPhotoUrl() {
     return this.updateCardPhotoUrlForm.value.cardPhoto_url as string
   }
 
   async updateCardPhoto(event: string): Promise<void> {
     this.updateCardPhotoUrlForm.patchValue({
       cardPhoto_url: event,
     })
     await this.updateProfile()
   }*/




  updateProfileForm = this.formBuilder.group({
    full_name: '',
  })

  /*updateCardPhotoUrlForm = this.formBuilder.group({
    cardPhoto_url: '',
  })*/

  updateSocialForm = this.formBuilder.group({
    strava: '',
    world_athletics: '',
    milesplit: '',
    tfrrs: '',
    instagram: '',
  })

  /* updateCardDataForm = this.formBuilder.group({
     name: '',
     pronunciation: '',
     nickname: '',
     line1: '',
     line2: '',
     notes: '',
   })
 
   updateTeamDataForm = this.formBuilder.group({
     team_name: '',
     team_mascot: '',
     coach: '',
     event: '',
   })*/

  constructor(
    private readonly profileService: ProfileService,
    private readonly athleteService: AthleteService,
    private readonly authentication: AuthenticationService,
    private formBuilder: FormBuilder

  ) { }

  async ngOnInit(): Promise<void> {
    await this.getUserID()
    await this.getProfile()
    await this.getSocial()
    await this.getAthletes()
    this.selectedAthlete = this.athletes[0]!

    // await this.getCardData()
    // await this.getTeamData()
    //await this.getCardPhotoUrl()

    const { full_name } = this.profile
    this.updateProfileForm.patchValue({
      full_name,
    })

    const { strava, world_athletics, milesplit, tfrrs, instagram } = this.social
    this.updateSocialForm.patchValue({
      strava,
      world_athletics,
      milesplit,
      tfrrs,
      instagram,
    })

    /* const { name, pronunciation, nickname, line1, line2, notes, } = this.cardData
     this.updateCardDataForm.patchValue({
       name,
       pronunciation,
       nickname,
       line1,
       line2,
       notes,
     })
 
     const { team_name, team_mascot, coach, event } = this.teamData
     this.updateTeamDataForm.patchValue({
       team_name,
       team_mascot,
       coach,
       event
     })*/

    /*const { cardPhoto_url } = this.cardPhoto
    this.updateCardPhotoUrlForm.patchValue({
      cardPhoto_url,
    })*/

  }

  async getUserID() {
    try {
      this.loading = true
      const userID = this.session.user.id //switch to public id maybe add qr code

      if (userID) {
        this.userID = userID
      }

    } finally {
      this.loading = false
    }
  }

  async getProfile() {
    try {
      this.loading = true
      const { user } = this.session
      const { data: profile, error, status } = await this.profileService.getProfile(user)

      if (error && status !== 406) {
        throw error
      }

      if (profile) {
        this.profile = profile
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async updateProfile(): Promise<void> {
    try {
      this.loading = true
      const { user } = this.session

      const full_name = this.updateProfileForm.value.full_name as string

      const { error } = await this.profileService.updateProfile({
        id: user.id,
        full_name,
      })
      if (error) throw error
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async getSocial() {
    try {
      this.loading = true
      const { user } = this.session
      const { data: social, error, status } = await this.athleteService.getSocial(user)

      if (error && status !== 406) {
        throw error
      }

      if (social) {
        this.social = social
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async updateSocial(): Promise<void> {
    try {
      this.loading = true
      const { user } = this.session

      const strava = this.updateSocialForm.value.strava as string
      const world_athletics = this.updateSocialForm.value.world_athletics as string
      const milesplit = this.updateSocialForm.value.milesplit as string
      const tfrrs = this.updateSocialForm.value.tfrrs as string
      const instagram = this.updateSocialForm.value.instagram as string

      const { error } = await this.athleteService.updateSocial({
        id: user.id,
        strava,
        world_athletics,
        milesplit,
        tfrrs,
        instagram,
      })
      if (error) throw error
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async getAthletes() {
    try {
      this.loading = true;

      // Await the promise returned by getAthleteUser
      const athletes = await this.athleteService.getAthleteUser(this.userID);

      if (athletes && athletes.length > 0) {
        this.athletes = athletes;
      } else {
        // Handle the case where no athlete IDs are found
        this.testV = "No athlete IDs found.";
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(`Error fetching athlete IDs: ${error.message}`);
      } else {
        alert('An unexpected error occurred.');
      }
    } finally {
      this.loading = false;
    }
  }


  async signOut() {
    await this.authentication.signOut()
  }

  // Swipe event handlers
  onSwipeLeft() {
    if (this.selectedIndex < this.tabCount - 1) {
      this.selectedIndex++;
    }
  }

  onSwipeRight() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  onSelectAthlete(athlete: Athlete): void {
    this.selectedAthlete = athlete;
  }
}

/* async getCardData() {
   try {
     this.loading = true
     const { user } = this.session
     const { data: cardData, error, status } = await this.athleteServices.getCardData(user)

     if (error && status !== 406) {
       throw error
     }

     if (cardData) {
       this.cardData = cardData
     }
   } catch (error) {
     if (error instanceof Error) {
       alert(error.message)
     }
   } finally {
     this.loading = false
   }
 }

 async updateCardData(): Promise<void> {
   try {
     this.loading = true
     const { user } = this.session

     const name = this.updateCardDataForm.value.name as string
     const pronunciation = this.updateCardDataForm.value.pronunciation as string
     const nickname = this.updateCardDataForm.value.nickname as string
     const line1 = this.updateCardDataForm.value.line1 as string
     const line2 = this.updateCardDataForm.value.line2 as string
     const notes = this.updateCardDataForm.value.notes as string
    

     const { error } = await this.athleteServices.updateCardData({
       id: user.id,
       name,
       pronunciation,
       nickname,
       line1,
       line2,
       notes,
     })
     if (error) throw error
   } catch (error) {
     if (error instanceof Error) {
       alert(error.message)
     }
   } finally {
     this.loading = false
   }
 }
 async getTeamData() {
   try {
     this.loading = true
     const { user } = this.session
     const { data: teamData, error, status } = await this.athleteServices.getTeamData(user)

     if (error && status !== 406) {
       throw error
     }

     if (teamData) {
       this.teamData = teamData
     }
   } catch (error) {
     if (error instanceof Error) {
       alert(error.message)
     }
   } finally {
     this.loading = false
   }
 }

 async updateTeamData(): Promise<void> {
   try {
     this.loading = true
     const { user } = this.session

     const team_name = this.updateTeamDataForm.value.team_name as string
     const team_mascot = this.updateTeamDataForm.value.team_mascot as string
     const coach = this.updateTeamDataForm.value.coach as string
     const event = this.updateTeamDataForm.value.event as string

     const { error } = await this.athleteServices.updateTeamData({
       id: user.id,
       team_name,
       team_mascot,
       coach,
       event,
     })

     if (error) throw error
   } catch (error) {
     if (error instanceof Error) {
       alert(error.message)
     }
   } finally {
     this.loading = false
   }
 }*/





