<div class="terms-of-service">
    <h1>Terms of Service</h1>
    <p>Effective Date: 05/20/24</p>
  
    <h2>1. Introduction</h2>
    <p>Welcome to mosaic-sports, a project by mosaicdash LLC ("we", "our", "us"). These Terms of Service ("Terms") govern your use of our website and services ("Services"). By accessing or using our Services, you agree to be bound by these Terms.</p>
  
    <h2>2. Eligibility</h2>
    <p>You must be at least 18 years old to use our Services. If you are under 18, you may only use our Services with the involvement and consent of a parent or guardian. By using our Services, you represent and warrant that you meet the eligibility requirements.</p>
  
    <h2>3. Account Registration</h2>
    <p>To access certain features of our Services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
  
    <h2>4. User Responsibilities</h2>
    <p>You are responsible for your use of our Services and for any content you post. You agree not to:</p>
    <ul>
      <li>Use our Services for any illegal or unauthorized purpose.</li>
      <li>Post or transmit any content that is defamatory, obscene, abusive, or otherwise objectionable.</li>
      <li>Interfere with or disrupt the operation of our Services.</li>
      <li>Attempt to gain unauthorized access to our systems or networks.</li>
    </ul>
  
    <h2>5. Payment and Billing</h2>
    <p>Certain features of our Services may require payment. By using these features, you agree to pay the applicable fees. We use third-party payment processors (e.g., Stripe) to process payments. You agree to provide accurate and complete payment information. Please note that event entry payments are not participation fees and are nonrefundable.</p>
  
    <h2>6. Cancellation and Termination</h2>
    <p>We may terminate or suspend your access to our Services at any time, without prior notice or liability, for any reason whatsoever, including if you breach these Terms. You may cancel your account at any time by contacting us at <a href="mailto:contact@mosaic-sports.com">contact&#64;mosaic-sports.com</a>.</p>
  
    <h2>7. Intellectual Property</h2>
    <p>All content and materials on our Services, including but not limited to text, graphics, logos, and software, are the property of mosaicdash LLC or its licensors and are protected by intellectual property laws. You agree not to use, reproduce, or distribute any content from our Services without our express written permission.</p>
  
    <h2>8. Limitation of Liability</h2>
    <p>To the maximum extent permitted by law, mosaicdash LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our Services; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our Services; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Services by any third party; (e) any errors or omissions in any content; or (f) any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via our Services, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
  
    <h2>9. Governing Law</h2>
    <p>These Terms shall be governed and construed in accordance with the laws of the State of New York, United States, without regard to its conflict of law provisions.</p>
  
    <h2>10. Changes to These Terms</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised Terms.</p>
  
    <h2>11. Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us:</p>
    <ul class="contact-info">
      <li>By email: <a href="mailto:contact@mosaic-sports.com">contact&#64;mosaic-sports.com</a></li>
      <li>By phone: 347-746-7653</li>
    </ul>
  </div>
  
