<div class="container" style="padding: 50px 0 100px 0">
  <app-account *ngIf="session; else auth" [session]="session"></app-account>
  <ng-template #auth>
    <app-auth></app-auth>
  </ng-template>
</div>

<!--Update to new @if-->


<!--<form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" class="form-widget">
    <div>
      <label for="email">Email</label>
      <input id="email" type="text" [value]="session.user.email" disabled />
    </div>
    <div>
      <label for="username">Name</label>
      <input formControlName="username" id="username" type="text" />
    </div>
    <div>
      <label for="website">Website</label>
      <input formControlName="website" id="website" type="url" />
    </div>
  
    <div>
      <button type="submit" class="button primary block" [disabled]="loading">
        {{ loading ? 'Loading ...' : 'Update' }}
      </button>
    </div>
  
    <div>
      <button class="button block" (click)="signOut()">Sign Out</button>
    </div>
  </form>





-->