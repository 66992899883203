<app-card-template [title]="ticket ? ticket.title : 'Create New Ticket Type'"
  [subtitle]="ticket?.ticket_prices?.[currentPriceIndex]?.label || ''"
  [subsubtitle]="currentTicketPrice ? ((currentTicketPrice.price+currentTicketPrice.fee) / 100 | currency)!.toString() : ''"
  [description]="ticket?.ticket_prices?.[currentPriceIndex]?.label || ''" [isEditing]="isEditing"
  [showContent]="ticket ? true : false">

  <!--[image]="ticket ? 'assets/sub-event-image.jpg' : 'assets/create-sub-event.jpg'" -->
  <!-- Projected Content for Display Mode -->
  @if(!isEditing && ticket){
  <div card-body>
    <p>Price: {{ ticket.ticket_prices![currentPriceIndex]!.price / 100 | currency}}</p>
    <p>Fee: {{ ticket.ticket_prices![currentPriceIndex]!.fee / 100 | currency }}</p>
    <p>Type: {{ ticket.title }}</p>
    <!-- Add other Ticket details -->
    <!--<button mat-raised-button color="primary" (click)="buyTicket()">Buy Ticket</button>-->
    @if(currentCartQuantity === 0){
    <form [formGroup]="eventTicketBuyForm" class="buy-form">
      <mat-form-field appearance="fill">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" formControlName="quantity" min="1">
        @if(eventTicketBuyForm.get('quantity')?.hasError('required')){
        <mat-error>
          Quantity is required
        </mat-error>
        }
        @if(eventTicketBuyForm.get('quantity')?.hasError('min')){
        <mat-error>
          Quantity must be at least 1
        </mat-error>
        }
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="addToCart()" [disabled]="eventTicketBuyForm.invalid">
        Add to Cart
      </button>
    </form>
    }@else {
    <div class="cart-controls">
      <button mat-icon-button color="primary" aria-label="Decrease Quantity" (click)="decreaseQuantity()"
        [disabled]="currentCartQuantity <=1">
        <mat-icon>remove</mat-icon>
      </button>
      <span class="quantity-display">{{ currentCartQuantity }}</span>
      <button mat-icon-button color="primary" aria-label="Increase Quantity" (click)="increaseQuantity()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button color="warn" aria-label="Remove from Cart" (click)="removeFromCart()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
  </div>
  }
  <!-- Projected Action Buttons -->
  <div card-actions>
    <!-- Display Mode Action Buttons -->
    @if(!isEditing && ticket)
    {
    <ng-container>

      <!-- Edit Ticket Info Button (Pencil Icon) -->
      <button mat-icon-button color="primary" aria-label="Edit Ticket" (click)="editTicket()" matTooltip="Edit Ticket"
        [disabled]="!editable" matTooltip="Edit Ticket">
        <mat-icon>edit</mat-icon>
      </button>

      <!-- Add other buttons as needed -->

      <!-- Navigation Buttons -->
      <!--<button mat-icon-button color="primary" aria-label="Previous Ticket Price" (click)="previousPrice()"
        [disabled]="!displayLeftArrow()" matTooltip="Previous Price" class="arrow">
        <mat-icon>arrow_back</mat-icon>
      </button>-->
      <a mat-icon-button color="warn" aria-label="Buy Ticket" [routerLink]="['/cart']" matTooltip="Goto Cart" [disabled]="currentCartQuantity<=0">
        <mat-icon>shopping_cart</mat-icon>
      </a>
      <!--<button mat-icon-button color="primary" aria-label="Next Ticket Price" (click)="nextPrice()"
        [disabled]="!displayRightArrow()" matTooltip="Next Price" class="arrow">
        <mat-icon>arrow_forward</mat-icon>
      </button>-->

      <!-- Edit Ticket Price Button (Dollar Sign Icon) -->
      <button mat-icon-button color="accent" aria-label="Edit Ticket Price" (click)="editTicketPrice()"
        [disabled]="!editable" matTooltip="Edit Pricing">
        <mat-icon>attach_money</mat-icon>
      </button>

    </ng-container>
    }
 
  </div>
</app-card-template>

<!-- Add New Ticket Button -->
@if(!ticket && !isEditing && editable){
<div class="add-button">
  <button mat-raised-button color="accent" (click)="toggleEditMode()">Add New Ticket Type</button>
</div>
}   

<!-- Edit Mode Action Buttons -->
    <!--@if(isEditing){
    <ng-container>
      <button mat-raised-button color="primary" type="button" (click)="saveNewTicket()"
        [disabled]="eventTicketEditForm.invalid">
        Create
      </button>
      <button mat-raised-button color="warn" type="button" (click)="cancelEdit()">Cancel</button>
    </ng-container>
  }-->