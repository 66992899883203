import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  catchError,
  forkJoin,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {
  AttendeeInfo,
  CartItem,
  DetailedCartItem,
  isTicketCartItem,
  RequiredFields,
  TicketCartItem,
} from '../../models/cart/cart.model';
import { CartService } from '../../services/cart/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { TicketService } from '../../services/ticket/ticket.service';
import { RegistrationService } from '../../services/registration/registration.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogConfig,
  FormField,
} from '../../models/edit-dialog/edit-dialog.model';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { CardTemplateComponent } from '../../shared/components/card-template/card-template.component';
import {
  StepperOrientation,
  MatStepperModule,
  MatStepper,
} from '@angular/material/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripeService } from '../../services/stripe/stripe.service';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElements,
} from '@stripe/stripe-js';
import { environment } from 'apps/mosaic-sports/src/environments/environment';
import { AuthComponent } from '../../shared/components/auth/auth.component';
import { AuthenticationService, SupabaseService } from '@mosaicdash/services';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatExpansionModule,
    RouterLink,
    FormsModule,
    MatFormFieldModule,
    CardTemplateComponent,
    MatStepperModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltip,
    MatTableModule,
    AuthComponent,
    MatCheckboxModule
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css',
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  isLoading = false;
  isCheckoutLoading=false
  error: string | null = null;
  sessionId: string | null = null;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  card: StripeCardElement | null = null;

  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 2; // Total number of tabs  Dynamically change this based on roles and permissions

  cartItems: any[] = []; // Define appropriate type

  private _formBuilder = inject(FormBuilder);

  // Form Groups
  authFormGroup: FormGroup;
  reviewFormGroup: FormGroup; // If needed
  paymentFormGroup: FormGroup; // If needed

  stepperOrientation: Observable<StepperOrientation>;
  panelStates: boolean[][][] = [];

  cartItems$: Observable<CartItem[]>;

  isAuthenticatedOrGuest$: Observable<boolean>;

  detailedCartItems: DetailedCartItem[] = [];
  groupedItems: {
    eventName: string;
    items: DetailedCartItem[];
  }[] = [];
  totalPriceCents: number = 0;

  dataSource = new MatTableDataSource<DetailedCartItem>([]);
  displayedColumns: string[] = [
    'item',
    'itemName',
   // 'priceName',
    'price',
    'fee',
    'quantity',
    'total',
  ]; // Updated columns

  @ViewChild('checkoutStepper') stepper!: MatStepper;

  constructor(
    private fb: FormBuilder,
    private cartService: CartService,
    private ticketService: TicketService,
    private registrationService: RegistrationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private stripeService: StripeService,
    private authService: AuthenticationService
  ) {
    // Initialize Form Groups
    this.authFormGroup = this.fb.group({
      // For Login
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      // For Guest Checkout
      guestEmail: ['', [Validators.required, Validators.email]],
    });

    // Initialize other Form Groups if needed
    this.reviewFormGroup = this.fb.group({
      reviewed: [false, Validators.requiredTrue]
    });
    this.paymentFormGroup = this.fb.group({});

    this.isAuthenticatedOrGuest$ = this.authService.isAuthenticatedOrGuest$();

    this.cartItems$ = this.cartService.getCartItems();
    const breakpointObserver = inject(BreakpointObserver);

    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  async ngOnInit() {
    console.log('test', this.dataSource);
    this.loadCartItems();
    this.stripe = await loadStripe(environment.stripeKey);
    this.onTabChange({ index: this.selectedIndex } as MatTabChangeEvent);
    this.loadCart();
  }

  ngAfterViewInit() {
    this.stepper.next()
    console.log('Stepper initialized:', this.stepper);
  }

  loadCart() {
    // Fetch cart items from CartService
    this.cartService.getCartItems().subscribe((items) => {
      this.cartItems = items;
      this.calculateTotal();
    });
  }

  calculateTotal() {
    this.totalPriceCents = this.cartItems.reduce(
      (sum, item) => sum + item.priceCents * item.quantity,
      0
    );
  }

  loadCartItems(): void {
    this.cartItems$
      .pipe(
        switchMap((items) => {
          if (items.length === 0) {
            this.detailedCartItems = [];
            this.dataSource.data = [];
            this.totalPriceCents = 0;
            return of([]);
          }

          const detailedItemsObservables = items.map((item) => {
            if (item.type === 'ticket') {
              return this.ticketService
                .getTicketWithPrice(item.ticketPriceId)
                .pipe(
                  map((details) => {
                    if (!details || !details.ticket_id || !details.ticket_id.ticket_fields) {
                      return null; // Handle unexpected structure
                    }
          
                    // Sort the ticket_fields based on field_id.order
                    const sortedTicketFields = details.ticket_id.ticket_fields.sort((a, b) => {
                      const orderA = a.field_id?.order ?? 0; // Handle possible undefined
                      const orderB = b.field_id?.order ?? 0;
                      return orderA - orderB;
                    });
          
                    return {
                      cartItem: item,
                      eventName: details.ticket_id.event_id.title,
                      itemName: details.ticket_id.title,
                      priceCents: details.price,
                      feeCents: details.fee,
                      priceName: details.label,
                      ticket_fields: sortedTicketFields,
                    };
                  }),
                  catchError((error) => {
                    console.error('Error fetching ticket details:', error);
                    return of(null); // Handle error by returning null
                  })
                );
            } /*else if (item.type === 'registration') {
              return this.registrationService.getRegistrationWithPrice(item.registrationPriceId).pipe(
                map((details) => ({
                  cartItem: item,
                  eventName: details.eventName,
                  itemName: details.registrationType,
                  priceCents: details.priceCents,
                  priceName: details.priceName, // Optional
                })),
                catchError((error) => {
                  console.error('Error fetching registration details:', error);
                  return of(null); // Handle error by returning null
                })
              );
            }*/
            return of(null); // For unknown types
          });

          return forkJoin(detailedItemsObservables).pipe(
            map(
              (detailedItems) =>
                detailedItems.filter(
                  (item) => item !== null
                ) as DetailedCartItem[]
            )
          );
        })
      )
      .subscribe((detailedItems) => {
        this.detailedCartItems = detailedItems;
        this.dataSource.data = detailedItems;
        this.groupItemsByEvent();
        this.calculateTotalPrice();
      });
  }

  // New method to group items by eventName using pure TypeScript
  groupItemsByEvent(): void {
    const groups = this.detailedCartItems.reduce((acc, item) => {
      const eventName = item.eventName;
      if (!acc[eventName]) {
        acc[eventName] = [];
      }
      acc[eventName].push(item);
      return acc;
    }, {} as { [key: string]: DetailedCartItem[] });

    // Convert the groups object into an array for easier iteration in the template
    this.groupedItems = Object.keys(groups).map((eventName) => ({
      eventName,
      items: groups[eventName],
    }));
  }

  onAttendeeFieldChange(
    detailedItem: DetailedCartItem,
    attendeeIndex: number
  ): void {
    if (!isTicketCartItem(detailedItem.cartItem)) {
      return;
    }
    const attendee = detailedItem.cartItem.attendees[attendeeIndex];
    this.cartService.updateAttendee(
      detailedItem.cartItem.id,
      attendee,
      attendeeIndex
    );
  }

  calculateTotalPrice(): void {
    this.totalPriceCents = this.detailedCartItems.reduce(
      (total, item) =>
        total + (item.priceCents + item.feeCents) * item.cartItem.quantity,
      0
    );
  }

  removeItem(itemId: string): void {
    this.cartService.removeFromCart(itemId);
    /*
    this.panelStates
    find a way to remove the propper panel element
    */
    this.snackBar.open('Item removed from cart.', 'Close', { duration: 2000 });
  }

  updateQuantity(itemId: string, quantity: number): void {
    if (quantity < 1) {
      this.removeItem(itemId);
      return;
    }
    this.cartService.updateQuantity(itemId, quantity);
    this.snackBar.open('Quantity updated.', 'Close', { duration: 2000 });
  }

  clearCart(): void {
    this.cartService.clearCart();
    this.snackBar.open('Cart cleared.', 'Close', { duration: 2000 });
  }

  backToCart(): void {
    // Proceed to checkout with the validated data
    this.snackBar.open('Proceeding to cart...', 'Close', {
      duration: 2000,
    });
    this.router.navigate(['/cart']);
  }

  // Optional: Convert cents to dollars for display if needed elsewhere
  getTotalPriceDollars(totalPriceCents: number): number {
    return totalPriceCents / 100;
  }

  isTicketCartItem(item: CartItem): item is TicketCartItem {
    return item.type === 'ticket';
  }

  // Helper method to create an array based on quantity
  getQuantityArray(quantity: number): number[] {
    return Array.from({ length: quantity }, (_, i) => i + 1);
  }

  allAttendeesFilled(): Boolean {
    return this.detailedCartItems.every((item) => {
      if (isTicketCartItem(item.cartItem)) {
        const ticketItem = item.cartItem;
        const requiredFields = item.ticket_fields || [];
        return ticketItem.attendees.every((attendee) =>
          requiredFields.every((field) =>
            field.required ? !!attendee[field.field_id.name] : true
          )
        );
      }
      return true; // For non-ticket items
    });
  }

  proceedToCheckout(): void {
    // Ensure all attendee information is filled by verifying data in detailedCartItems

    if (this.allAttendeesFilled()) {
      // Proceed to checkout with the validated data
      this.snackBar.open('Proceeding to checkout...', 'Close', {
        duration: 2000,
      });
      this.router.navigate(['/checkout']);
    } else {
      this.snackBar.open(
        'Please fill out all required attendee information.',
        'Close',
        { duration: 3000 }
      );
    }
  }

  guestCheckout(): void {
    if (this.authFormGroup.get('guestEmail')?.valid) {
      const guestEmail = this.authFormGroup.get('guestEmail')?.value;
      this.authService.guestCheckout(guestEmail);
      this.snackBar.open('Proceeding as Guest...', 'Close', {
        duration: 2000,
      });
      // Optionally, navigate to the next step
      // This depends on how you want the UX to flow
    } else {
      this.snackBar.open(
        'Please enter a valid email for guest checkout.',
        'Close',
        {
          duration: 3000,
        }
      );
    }
  }

  /*
  // Open the edit dialog for attendee information
  editAttendees(detailedItem: DetailedCartItem, selectedIndex: number): void {
    if (!isTicketCartItem(detailedItem.cartItem)) {
      this.snackBar.open('Cannot edit attendees for this item.', 'Close', {
        duration: 3000,
      });
      return;
    }

    const ticketItem = detailedItem.cartItem as TicketCartItem;

    const config: DialogConfig = {
      title: 'Edit Attendee ' + (selectedIndex + 1),
      fields: detailedItem.requiredFields || [],
      isPriceDialog: false,
      data: { attendees: ticketItem.attendees[selectedIndex] },
      isBatchEdit: false,
    };

    const dialogRef = this.dialog.open(GenericEditDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: config,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // Update each attendee's information
        this.cartService.updateAttendee(ticketItem.id, result, selectedIndex);
        this.snackBar.open('Attendee information updated.', 'Close', {
          duration: 2000,
        });
        // Optionally, refresh the cart to display updated information
        console.log(this.detailedCartItems);
      }
    });
  }*/

  openPanel(i: number, j: number, k: number) {
    // Ensure the first dimension exists
    if (!this.panelStates[i]) {
      this.panelStates[i] = [];
    }

    // Ensure the second dimension exists
    if (!this.panelStates[i][j]) {
      this.panelStates[i][j] = [];
    }

    // Ensure the third dimension exists and toggle the value
    if (this.panelStates[i][j][k] === undefined) {
      this.panelStates[i][j][k] = true; // Initialize to true
    } else {
      this.panelStates[i][j][k] = true; // Toggle value
    }

    console.log(
      `Panel state at [${i}][${j}][${k}]:`,
      this.panelStates[i][j][k]
    );
  }

  closePanel(i: number, j: number, k: number) {
    // Ensure the first dimension exists
    if (!this.panelStates[i]) {
      this.panelStates[i] = [];
    }

    // Ensure the second dimension exists
    if (!this.panelStates[i][j]) {
      this.panelStates[i][j] = [];
    }

    // Ensure the third dimension exists and toggle the value
    if (this.panelStates[i][j][k] === undefined) {
      this.panelStates[i][j][k] = false; // Initialize to true
    } else {
      this.panelStates[i][j][k] = false; // Toggle value
    }

    console.log(
      `Panel state at [${i}][${j}][${k}]:`,
      this.panelStates[i][j][k]
    );
  }

  anyPanelExpanded(i: number, j?: number, k?: number): boolean {
    if (j === undefined) {
      // Check if any panel in panelStates[i] is true
      return (
        this.panelStates[i]?.some((innerArray) =>
          innerArray.some((value) => value === true)
        ) || false
      );
    } else if (k === undefined) {
      // Check if any panel in panelStates[i][j] is true
      return this.panelStates[i]?.[j]?.some((value) => value === true) || false;
    } else {
      return this.panelStates[i]?.[j]?.[k];
    }
  }

  async checkout() {
    this.isCheckoutLoading = true;
    this.error = null;

    try {
       // Save cart to Supabase using RPC and get cart ID
       const cartId = await this.cartService.saveCartToSupabase();
      const user = await this.authService.getCurrentUser(); // Replace with actual user ID from auth service
      const items = this.cartItems.map((item) => ({
        id: item.id,
        name: item.name, // Ensure name is included for Stripe Checkout
        priceCents: item.priceCents,
        quantity: item.quantity,
      }));

      // Step 1: Create Checkout Session
      const session = await lastValueFrom(
        this.stripeService.createCheckoutSession(user!.id, cartId)
      );

      if (!session.sessionId) {
        throw new Error('Failed to create Checkout Session');
      }

      // Step 2: Redirect to Stripe Checkout
      const { error } = await this.stripe!.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err: any) {
      console.error('Checkout error:', err);
      this.error =
        err.message || 'An unexpected error occurred during checkout.';
      this.snackBar.open(this.error!, 'Close', { duration: 5000 });
    } finally {
      this.isCheckoutLoading = false;
    }
  }

  // Swipe event handlers
  onSwipeLeft() {
    if (this.selectedIndex < this.tabCount - 1) {
      this.selectedIndex++;
    }
  }

  onSwipeRight() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  // Handle tab changes for conditional validation
  onTabChange(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
    if (this.selectedIndex === 0) {
      // Sign In tab
      //this.authFormGroup.get('email')?.setValidators([Validators.required, Validators.email]);
      //this.authFormGroup.get('password')?.setValidators([Validators.required]);
      this.authFormGroup.get('email')?.clearValidators();
      this.authFormGroup.get('password')?.clearValidators();
      this.authFormGroup.get('guestEmail')?.clearValidators();
      this.authFormGroup.get('guestEmail')?.setValue('');
    } else {
      // Guest Checkout tab
      this.authFormGroup
        .get('guestEmail')
        ?.setValidators([Validators.required, Validators.email]);
      this.authFormGroup.get('email')?.clearValidators();
      this.authFormGroup.get('password')?.clearValidators();
      this.authFormGroup.get('email')?.setValue('');
      this.authFormGroup.get('password')?.setValue('');
    }
    this.authFormGroup.get('email')?.updateValueAndValidity();
    this.authFormGroup.get('password')?.updateValueAndValidity();
    this.authFormGroup.get('guestEmail')?.updateValueAndValidity();
  }

  // Handle successful login from <app-auth>
  onLoginSuccess(): void {
    // Optionally, clear or set form values as needed
    this.authFormGroup.get('email')?.clearValidators();
    this.authFormGroup.get('password')?.clearValidators();
    this.authFormGroup.get('guestEmail')?.clearValidators();
    this.authFormGroup.updateValueAndValidity();

    // Manually mark the form as valid
    this.authFormGroup.markAsDirty();
    this.authFormGroup.markAsTouched();
    this.authFormGroup.setErrors(null);

    // Mark the step as completed
    if (this.stepper.selected) {
      this.stepper.selected.completed = true;
    }

    // Navigate to the next step
    this.stepper.selectedIndex=2
    console.log('hello', this.stepper.selectedIndex);

    this.snackBar.open('Successfully logged in!', 'Close', { duration: 2000 });
  }

  // Handle form submission
  submitOrder(): void {
    /*if (this.attendeeForm.valid) {
      this.loading = true;
      // Prepare order data to include in metadata
      const orderData = {
        attendees: this.attendeeForm.value.attendeesByCartItem.flatMap(item => item.attendees)
      };
      
      // Create Payment Intent with metadata
      this.paymentService.createPaymentIntent(this.totalPriceCents, orderData).subscribe({
        next: (response) => {
          const clientSecret = response.clientSecret;

          // Confirm the card payment
          this.stripeService.confirmCardPayment(clientSecret, {
            payment_method: {
              card: {}, // Reference to the Stripe Card Element if using one
              billing_details: {
                name: this.attendeeForm.get('name')?.value,
                email: this.attendeeForm.get('email')?.value,
              },
            },
          }).subscribe(result => {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              this.snackBar.open(result.error.message || 'Payment failed.', 'Close', { duration: 5000 });
              this.loading = false;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Payment succeeded, but order logging is handled via webhook
                this.snackBar.open('Payment successful! Your order is being processed.', 'Close', { duration: 3000 });
                // Optionally, clear the cart and navigate to a confirmation page
                this.cartService.clearCart();
                this.router.navigate(['/order-confirmation']);
                this.loading = false;
              }
            }
          });
        },
        error: (error) => {
          this.snackBar.open('Failed to create payment intent.', 'Close', { duration: 3000 });
          console.error(error);
          this.loading = false;
        }
      });
    } else {
      this.snackBar.open('Please complete all required fields.', 'Close', { duration: 3000 });
    }*/
  }
}
