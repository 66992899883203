import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { Event } from '../../../models/event/event.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-registration-card',
  standalone: true,
  imports: [MatCardModule,
MatFormFieldModule,
MatSlideToggleModule,
CommonModule
 ],
  templateUrl: './registration-card.component.html',
  styleUrl: './registration-card.component.css'
})
export class RegistrationCardComponent implements OnInit {
  @Input() event!: Event;
  @Input() isEditMode: boolean = false;
  @Output() save = new EventEmitter<Event>();

  registrationForm!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      registrationEnabled: [this.event.is_public, Validators.required],
    });
  }

  onSave(): void {
    if (this.registrationForm.valid) {
      const updatedEvent = {
        ...this.event,
        registration: {
          enabled: this.registrationForm.value.registrationEnabled,
          link: this.registrationForm.value.registrationLink
        }
      };
      this.save.emit(updatedEvent);
    }
  }
}
