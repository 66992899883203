import { Component, OnInit } from '@angular/core';
import { Event, EventSubEvent } from '../../models/event/event.model';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { Ticket } from '../../models/ticket/ticket.model';
import { UserRole } from '../../../../../../libs/models/src/lib/models/user/user.model';
import { AuthenticationService } from '@mosaicdash/services';
import { EventService } from '../../services/event/event.service';
import { AthleteService } from '../../services/athlete/athlete.service';
import { TicketService } from '../../services/ticket/ticket.service';
import { ActivatedRoute } from '@angular/router';
import { URLHandlerService } from '../../services/urlhandler/urlhandler.service';
import { AsyncPipe } from '@angular/common';
import { EventCardComponent } from '../../shared/components/event-card/event-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { CardTemplateComponent } from '../../shared/components/card-template/card-template.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [
    AsyncPipe,
    EventCardComponent,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule
  ],
  templateUrl: './events.component.html',
  styleUrl: './events.component.css'
})
export class EventsComponent implements OnInit {
  base64Id: string = '';
  eventId = "";
  data$!: Observable<{ events: Event[]; userRoles: UserRole[] }>;

  constructor(
    readonly authenticationService: AuthenticationService,
    private readonly eventService: EventService,
    private readonly athleteService: AthleteService,
    private readonly ticketService: TicketService,
    private route: ActivatedRoute,
    private readonly URLHandler: URLHandlerService
  ) { }

  ngOnInit(): void {
    this.data$ =
      forkJoin({
        events: this.eventService.getAllEvents(),
        userRoles: this.authenticationService.getUserRole()
      });

    // Subscribe to data$ to assign subEvents and tickets
    this.data$.subscribe({
      next: ({ userRoles }) => {
        // Assign fetched data to component properties
        // If you need to use event separately
        //this.event$ = of(event); // You might need to import 'of' from 'rxjs'

        //this.eventSubEvents = eventSubEvents;
        //this.tickets = tickets;
        //console.log('tickets',this.tickets)
        //this.userRoles = userRoles;
        //this.setPermissions();
      },
      error: (err) => {
        console.error('Error fetching event data', err);
      }
    });
  }


}

