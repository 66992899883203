<mat-card>
    <mat-card-header>
      <mat-card-title>Registration Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="!isEditMode">
        <p>Enabled: {{ event.is_public ? 'Yes' : 'No' }}</p>
      </div>
      <!--=<form [formGroup]="registrationForm" *ngIf="isEditMode">
        <mat-slide-toggle formControlName="registrationEnabled">Enable Registration</mat-slide-toggle>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Registration Link</mat-label>
          <input matInput formControlName="registrationLink">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="onSave()" [disabled]="registrationForm.invalid">Save</button>
      </form>-->
    </mat-card-content>
  </mat-card>
  