import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import {provideNgcCookieConsent, NgcCookieConsentConfig} from 'ngx-cookieconsent';


import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideNgxStripe } from 'ngx-stripe';
import { provideHttpClient } from '@angular/common/http';
import { PLUTO_ID } from './shared/services/mosaicdash.service';
import { ReactiveFormsModule } from '@angular/forms';
import 'hammerjs';
import Hammer from 'hammerjs';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';


export class HammerConfig extends HammerGestureConfig{
  override buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "pan-y"
    });
    return mc;
  }
}



const cookieConfig: NgcCookieConsentConfig = {

  cookie: {
    //domain: 'localhost' // Change to your domain
    domain: 'mosaic-sports.com' // Change to your domain
  },
  palette: {
    popup: {
      background: '#000000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Decline',
    link: 'Learn more',
    href: 'https://www.mosaic-sports.com/privacy' // Change to your privacy policy URL
  }
};
/*
  cookie: {
    domain: 'localhost:4200', // it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  type: 'opt-out',
}; 

*/
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimationsAsync(),  provideNgcCookieConsent(cookieConfig), provideHttpClient(), importProvidersFrom(ReactiveFormsModule), provideNgxStripe(), {
    provide: PLUTO_ID,
    useValue: '449f8516-791a-49ab-a09d-50f79a0678b6',
  },

   importProvidersFrom(HammerModule),{
    provide: HAMMER_GESTURE_CONFIG,
   useClass: HammerConfig
  }, ]
};

