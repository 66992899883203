import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core'
import { AppComponent } from '../../app.component';
import { AuthComponent } from '../../shared/components/auth/auth.component';
import { AccountComponent } from '../../shared/components/account/account.component';
import { AuthenticationService } from '@mosaicdash/services';
import { AuthSession } from '@supabase/supabase-js';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ AppComponent, AuthComponent, CommonModule, AccountComponent ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})


export class ProfileComponent implements OnInit {
  title = 'mosaic-sports'

  session: AuthSession | null = null;

  constructor(private readonly authenticationService: AuthenticationService,) {}



  ngOnInit() {
    this.authenticationService.authChanges((_, session) => (this.session = session))
  }
}



/*import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, ReactiveFormsModule  } from '@angular/forms'
import { AuthSession, createClient, SupabaseClient, User, } from '@supabase/supabase-js'
import { AuthService } from '../../services/supabase/auth.service'
import { Profile } from '../../services/supabase/data.service'
import { DataService } from '../../services/supabase/data.service'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {
  loading = false
  profile!: Profile
  session = this.supabase.session

  @Input()
  session1!: AuthSession

  updateProfileForm = this.formBuilder.group({
    username: '',
    website: '',
  })

  constructor(
    private readonly dataService: DataService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private readonly supabase: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getProfile()
    this.supabase.authChanges((_, session) => (this.session = session))

    const { username, website } = this.profile
    this.updateProfileForm.patchValue({
      username,
      website,
    })
  }

  async getProfile() {
    try {
      this.loading = true
      const { user } = this.session1
      const { data: profile, error, status } = await this.dataService.profile(user)

      if (error && status !== 406) {
        throw error
      }

      if (profile) {
        this.profile = profile
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async updateProfile(): Promise<void> {
    try {
      this.loading = true
      const { user } = await this.session1

      const username = this.updateProfileForm.value.username as string
      const website = this.updateProfileForm.value.website as string

      const { error } = await this.dataService.updateProfile({
        id: user.id,
        username,
        website,
      })
      if (error) throw error
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async signOut() {
    await this.auth.logout
  }

}
*/