
<div *ngIf="userTeams.length > 0" class="user-teams">
    <h3 class="white">Your Teams</h3>
    <ul>
      <li class="white" *ngFor="let team of userTeams">{{ team.name }}</li>
    </ul>
  </div>


<form [formGroup]="teamsForm" (ngSubmit)="submitForm()">
    <!-- Select Sport -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Select Team</mat-label>
        <mat-select formControlName="teamId" required>
            <mat-option *ngFor="let team of teams" [value]="team.id">
                {{ team.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="teamsForm.get('sportId')?.hasError('required')">
            Team selection is required
        </mat-error>
    </mat-form-field>

    <!-- Select Gender -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Select Gender</mat-label>
        <mat-select formControlName="gender" required>
            <mat-option value="Men">Male</mat-option>
            <mat-option value="Women">Female</mat-option>
            <mat-option value="Non-Binary">Non-Binary</mat-option>
        </mat-select>
        <mat-error *ngIf="teamsForm.get('gender')?.hasError('required')">
            Gender selection is required
        </mat-error>
    </mat-form-field>


    <!-- Select Sport Variation -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Select Sport Variation</mat-label>
        <mat-select formControlName="sportVariationId" required>
            <mat-option *ngFor="let variation of filteredSportVariations" [value]="variation.sport_variation_id.id">
                {{ variation.sport_variation_id.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="teamsForm.get('sportVariationId')?.hasError('required')">
            Sport Variation selection is required
        </mat-error>
    </mat-form-field>
    <button class="button" mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">Join Team</button>
</form>
@if(isLoading && isSportSelected){
<mat-progress-spinner diameter="40" mode="indeterminate"></mat-progress-spinner>
}