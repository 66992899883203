import { Component, OnInit } from '@angular/core';
import { StripeService } from '../../../services/stripe/stripe.service';
import { CommonModule } from '@angular/common';
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { MatButtonModule } from '@angular/material/button';
import { lastValueFrom } from 'rxjs';
import { environment } from 'apps/mosaic-sports/src/environments/environment';


@Component({
  selector: 'app-stripe-onboarding',
  standalone: true,
  imports: [CommonModule,
    MatButtonModule
  ],
  templateUrl: './stripe-onboarding.component.html',
  styleUrl: './stripe-onboarding.component.css'
})
export class StripeOnboardingComponent  implements OnInit {
  connectedAccountId: string | null = null;
  error: string | null = null;
  isLoading = false;

  stripeConnect: StripeConnectInstance | null = null;

  constructor(private stripeService: StripeService) {}

  ngOnInit(): void {}//do we need this?

  async signUp() {
    this.isLoading = true;
    this.error = null;

    try {
      // Step 1: Create a connected account via backend
      const accountResponse = await lastValueFrom( this.stripeService.createAccount());
      this.connectedAccountId = accountResponse!.account;

      // Step 2: Initialize Stripe Connect with fetchClientSecret
      this.stripeConnect = await loadConnectAndInitialize({
        publishableKey: environment.stripeKey,//put in env
        fetchClientSecret: async () => {
          if (!this.connectedAccountId) throw new Error('No connected account ID available.');

          const sessionResponse = await lastValueFrom( this.stripeService.createAccountSession(this.connectedAccountId));
          console.log('id:', this.connectedAccountId) //save this to database
          
          if (!sessionResponse || !sessionResponse.client_secret) {
            throw new Error('Failed to retrieve client secret.');
          }

          return sessionResponse.client_secret;
        },
        appearance: {
          overlays: 'dialog',
          variables: {
            colorText: "#FFFFFF",
            colorBackground: "#303030",
            buttonPrimaryColorBackground: "#2196F3",
            buttonPrimaryColorText: "#FFFFFF",
            buttonSecondaryColorBackground: "#2196F3",
            buttonSecondaryColorText: "#FFFFFF",
            fontFamily: "Roboto, Helvetica Neue, sans-serif",
          },
        },
      });

      // Step 3: Create the embedded onboarding component
      const embeddedOnboarding = this.stripeConnect.create('account-onboarding');

      // Optional: Handle exit event
      embeddedOnboarding.setOnExit(() => {
        console.log('User exited the onboarding flow');
      });

      // Step 4: Mount the component to the DOM
      const container = document.getElementById("embedded-onboarding-container");
      if (container) {
        container.appendChild(embeddedOnboarding);
      } else {
        throw new Error('Onboarding container not found.');
      }

    } catch (error: any) {
      this.error = error.message || 'An error occurred during sign-up.';
      console.error('Sign-up error:', error);
    } finally {
      this.isLoading = false;
    }
  }
}