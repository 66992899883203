import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AuthenticationService } from '@mosaicdash/services';
import { AuthSession } from '@supabase/supabase-js';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { UserService } from '../../services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { TeamSelectionDialogComponent } from '../../shared/components/team-selection-dialog/team-selection-dialog.component';
import { MusicSelectionDialogComponent } from '../../shared/components/music-selection-dialog/music-selection-dialog.component';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [

    CommonModule,

    MatButtonModule,

    MatCardModule,

    NgOptimizedImage

  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, OnDestroy {
  profile = false;
  session: AuthSession | null = null; // Updated to use reactive session handling
  athlete!: boolean


  // Subscription for authentication session
  private authSessionSubscription!: Subscription;

  constructor(
    private readonly authenticationService: AuthenticationService, private dialog: MatDialog, private readonly userService: UserService, private meta: Meta, private titleService: Title) {
    this.setMetaTags();
  }

  async ngOnInit(): Promise<void> {
    // Subscribe to authentication session changes
    this.authSessionSubscription = this.authenticationService.session$.subscribe(
      (session) => {
        this.session = session;
        // Additional logic based on session can be added here
      },
      (error) => {
        console.error('Error subscribing to session:', error);
      }
    );
    await this.isAthlete()

  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    if (this.authSessionSubscription) {
      this.authSessionSubscription.unsubscribe();
    }
  }

  async isAthlete() {
    this.athlete = await this.userService.isAthlete();
  }

  setMetaTags() {
    this.titleService.setTitle('mosaic-sports | Share Your Story!');
    this.meta.addTags([
      { name: 'description', content: 'Create trading cards, register for meets, and get paid for working events on mosaic-sports. A comprehensive platform for athletes, coaches, and event organizers.' },
      { name: 'keywords', content: 'mosaic-sports, track and field, trading cards, races, NIL, meet management, event management, Olympic sports' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'mosaic-sports' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'og:title', content: 'mosaic-sports | Share Your Story!' },
      { name: 'og:description', content: 'Join mosaic-sports to create trading cards, register for meets, and streamline athlete and officail payments. A platform tailored for Olympic sports and track and field.' },
      { name: 'og:image', content: 'https://mosaic-sports.com/assets/images/profilePhoto.jpg' },
      { name: 'og:url', content: 'https://mosaic-sports.com/home' }
    ]);
  }

  openTeamSelectionDialog(): void {
    const dialogRef = this.dialog.open(TeamSelectionDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      disableClose: false, // Prevent closing the dialog by clicking outside
      data: {}, // Pass any initial data if needed
    });
  }

  openSongSelectionDialog(): void {
    const dialogRef = this.dialog.open(MusicSelectionDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      disableClose: false, // Prevent closing the dialog by clicking outside
      data: {}, // Pass any initial data if needed
    });
  }
}
