<app-card-template [title]="event ? event.title : 'Create New Event'" [subtitle]="event.sports?.name!"
  [subsubtitle]="event.sport_variations?.name!" [formGroup]="eventForm" [showContent]="event ? true : false"
  [description]="event.description"
  [isEditing]="isEditing" [image]="'../../../../assets/images/eventPhoto.jpg'">
  <!-- Projected Content for Display Mode -->
  @if(!isEditing && event){
  <div card-body>
    <!-- Add other sub-event details -->
  </div>
  }
  <!-- Projected Content for Edit Mode -->
  @if(isEditing){
  <div card-edit>
    <!-- Add other fields as necessary -->
  </div>
  }
  <!-- Projected Action Buttons -->
  <div card-actions>
    <!-- Display Mode Action Buttons -->
    @if(!isEditing && event){
    <ng-container>
      @if(editable){
     <!-- <a mat-icon-button color="primary" aria-label="View-Event" [routerLink]="['/event', base64Id]"
        matTooltip="View Event">
        <mat-icon>open_in_full</mat-icon>
      </a>-->

      <button mat-raised-button color="primary"  aria-label="View-Event" [routerLink]="['/event', base64Id]"
      matTooltip="Open Event">
View Event Info
      </button>
      }
      <!-- Add other buttons as needed -->
    </ng-container>
    }
    <!-- Edit Mode Action Buttons -->
    @if(isEditing){
    <ng-container>
      <button mat-raised-button color="primary" type="submit" [disabled]="eventForm.invalid" (click)="saveEvent()">
        {{ event ? 'Update' : 'Create' }}
      </button>
      <button mat-raised-button color="warn" type="button" (click)="cancelEdit()">Cancel</button>
    </ng-container>
    }
  </div>
</app-card-template>

<!--<mat-card class="card">
   Display Mode 
    <mat-card-header class="card-header">
      <mat-card-title>{{ event?.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="card-body">
        <p>Distance: {{ event?.title }}</p>
        <p>Type: {{ event?.description }}</p>
        Add other sub-event details 
      </mat-card-content>
      <mat-card-actions  class="card-actions">
       -Action Buttons for Directors 
        <div class="button-grid-container">
            <div class="button-grid">
                <div id="B1">
                    <a id="edit-button" mat-icon-button color="primary" aria-label="Account" *ngIf="authenticationService.getUserRole('director')" (click)="toggleEditMode()">
                        <mat-icon>edit</mat-icon>
                    </a>-
                    <div id="B2"></div>
                    <div id="B3"></div>
                    <div id="B4"></div>
                    <div id="B5"></div>
                    <div id="B6"></div>
                    <div id="B7"></div>
        </div>
        </div>
        </div>
      </mat-card-actions>
      </mat-card>-->