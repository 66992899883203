@if(data$ | async; as data){
<header>
    <h1>Upcoming Events</h1>
</header>
<div class="center-hor">

    <div class="event-container">
        <!-- List of Sub-Events -->
        <div class="card-list">
            <!-- Existing Sub-Events -->
            @if(data.events.length>0) { @for(event of data.events; track event){ <app-event-card [event]="event">
            </app-event-card>
            }
            }@else{
            <div class="events-container">
                <div class="card-container">
                    <mat-card class="card">
                        <mat-card-header class="card-header">
                            <mat-card-title>More Events Coming Soon!</mat-card-title>
                            <mat-card-subtitle>Make an account to stay up to date!</mat-card-subtitle>
                        </mat-card-header>
                        <div class="image-size">
                            <img mat-card-image class="mosaicTwilight" src="../../../assets/images/eventPhoto.jpg"
                                alt="mosaic-sports">
                        </div>
                        <mat-card-content>
                            <p></p>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="event-button">
                                <a class="register btn" mat-raised-button color="primary"
                                    href="profile/signup">Register</a>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
            }
        </div>
    </div>
</div>
}@else {
<div class="loading-container">
    <mat-progress-spinner mode="indeterminate" diameter="50">
    </mat-progress-spinner>
    <p>Loading events...</p>
</div>
}