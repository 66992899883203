import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { StripeOnboardingComponent } from '../stripe-onboarding/stripe-onboarding.component';

@Component({
  selector: 'app-finances',
  standalone: true,
  imports: [MatCardModule,
    StripeOnboardingComponent
  ],
  templateUrl: './finances.component.html',
  styleUrl: './finances.component.css'
})
export class FinancesComponent {
  stripeOnboarded = false;

}
