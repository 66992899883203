@if(!stripeOnboarded){
<app-stripe-onboarding></app-stripe-onboarding>}@else{
<mat-card class="card">
        <mat-card-header class="card-header">
            <mat-card-title>
               <h1>Finances</h1>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-body">
           <h2>Test</h2>
        </mat-card-content>
        <mat-card-actions class="card-actions">
        </mat-card-actions>
    </mat-card>
}