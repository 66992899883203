import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProfileViewerComponent } from './pages/profile-viewer/profile-viewer.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { ViewAllComponent } from './pages/view-all/view-all.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AdaComponent } from './pages/ada/ada.component';
import { UpcomingEventsComponent } from './pages/upcoming-events/upcoming-events.component';
import { EventRegistrationComponent } from './shared/components/event-registration/event-registration.component';
import { USATFRegion2Component } from './pages/usatf-region-2/usatf-region-2.component';
import { HytekComponent } from './shared/components/hytek/hytek.component';
import { TicketViewerComponent } from './shared/components/ticket-viewer/ticket-viewer.component';
import { EmailsComponent } from './shared/components/emails/emails.component';
import { StripeOnboardingComponent } from './shared/components/stripe-onboarding/stripe-onboarding.component';
import { CreateEventComponent } from './shared/components/create-event/create-event.component';
import { EventComponent } from './pages/event/event.component';
import { CartComponent } from './shared/components/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { EventCardComponent } from './shared/components/event-card/event-card.component';
import { EventsComponent } from './pages/events/events.component';
import { ConnectComponent } from './pages/connect/connect.component';
import { SuccessComponent } from './pages/success/success.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },

  {
    path: 'contact',
    component: ContactComponent,
  },

  {
    path: 'profile/:action',
    component: ProfileComponent,
  },

  {
    path: 'profile',
    component: ProfileComponent,
  },

  {
    path: 'athlete/:id',
    component: ProfileViewerComponent,
  },

  {
    path: 'all/:id',
    component: ViewAllComponent,
  },

  {
    path: 'update-password',
    component: ForgotPasswordComponent,
  },

  {
    path: 'privacy',
    component: PrivacyComponent,
  },

  {
    path: 'terms',
    component: TermsComponent,
  },

  {
    path: 'ada',
    component: AdaComponent,
  },

  {
    path: 'create',
    children: [{ path: 'event/:type', component: CreateEventComponent }],
  },

  {
    path: 'event/:id',
    component: EventComponent,
  },

  {
    path: 'cart',
    component: CartComponent,
  },

  {
    path: 'checkout',
    component: CheckoutComponent,
  },

  /*{
    path: "event/GATFS",
    component: UpcomingEventsComponent
  },

  {
    path: "event/GATFS/hytek",
    component: HytekComponent
  },

  {
    path: "email",
    component: EmailsComponent
  },

  {
    path: "series/:id",
    component: EventRegistrationComponent
  },
  
  {
    path: "EVENT/GATFS",
    component: UpcomingEventsComponent
  },

  {
    path: "event/usatf-region-2",
    component: USATFRegion2Component
  },

  {
    path: "EVENT/usatf-region-2",
    component: USATFRegion2Component
  },

  {
    path: "event/usatf-region-2/tickets",
    component: TicketViewerComponent
  },

  {
    path: "EVENT/usatf-region-2/tickets",
    component: TicketViewerComponent
  }, */

  {
    path: 'connect',
    component: ConnectComponent,
  },

  {
    path: 'events',
    component: EventsComponent,
  },

  { 
    path: 'success',
    component: SuccessComponent },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
];
