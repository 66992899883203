<mat-stepper
  #checkoutStepper
  class="stepper"
  [orientation]="(stepperOrientation | async)!"
  [linear]="true"
>
  <mat-step
    [stepControl]="authFormGroup"
    [completed]="isAuthenticatedOrGuest$ | async"
  >
    <ng-template matStepLabel>Sign In</ng-template>
    <ng-template matStepContent>
      @if(true){

      <mat-tab-group
        class="tabs"
        dynamicHeight
        mat-stretch-tabs="false"
        mat-align-tabs="center"
        [selectedIndex]="selectedIndex"
        (swipeleft)="onSwipeLeft()"
        (swiperight)="onSwipeRight()"
      >
        <form [formGroup]="authFormGroup">
          <mat-tab class="tab">
            <ng-template mat-tab-label> Sign In </ng-template>
            <div class="center height">
              <app-auth (loginSuccess)="onLoginSuccess()"></app-auth>
            </div>
          </mat-tab>
          <mat-tab class="tab">
            <ng-template mat-tab-label> Guest Checkout </ng-template>
            <div class="height">
              <h1 class="header">mosaic-sports</h1>
              @if(!(isAuthenticatedOrGuest$ | async)){
              <div class="row flex-center flex">
                <div class="col form-widget" aria-live="polite">
                  <div class="center sign-in-form">
                    <mat-form-field appearance="fill" class="width">
                      <mat-label>Email</mat-label>
                      <input
                        matInput
                        formControlName="guestEmail"
                        type="email"
                        required
                      />
                      <mat-error
                        *ngIf="
                          authFormGroup.get('guestEmail')?.hasError('required')
                        "
                      >
                        Email is required.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          authFormGroup.get('guestEmail')?.hasError('email')
                        "
                      >
                        Enter a valid email.
                      </mat-error>
                    </mat-form-field>

                    <button
                      class="width"
                      mat-raised-button
                      color="accent"
                      (click)="guestCheckout()"
                      [disabled]="authFormGroup.get('guestEmail')?.invalid"
                    >
                      Continue as Guest
                    </button>
                  </div>
                </div>
              </div>
            }@else {
              <h1>You're Logged In!</h1>
            }
            </div>
          </mat-tab>
        </form>
      </mat-tab-group>
      }@else{
      <div class="tab"></div>
      }
      <div>
        <button
          mat-raised-button
          color="primary"
          matStepperNext
          [disabled]="!(isAuthenticatedOrGuest$ | async)"
        >
          Next
        </button>
      </div>
    </ng-template>
  </mat-step>

  <mat-step [stepControl]="reviewFormGroup">
    <ng-template matStepLabel>Review Cart</ng-template>
    <ng-template matStepContent>
      <form [formGroup]="reviewFormGroup">
      <div class="cart-container">
        @if(detailedCartItems.length > 0){
        <div class="center-hor">
          <div class="event-container">
            <!-- List of Tickets -->
            <div>
              <div>
                <div class="event-container">
                  <!-- List of Tickets -->
                  <div class="card-list">
                    @for(group of groupedItems; track group; let i = $index) {
                    @for(detailedItem of group.items; track detailedItem; let j
                    = $index){ @if(isTicketCartItem(detailedItem.cartItem)) {
                    @if (detailedItem.cartItem.attendees) { @for(attendee of
                    detailedItem.cartItem.attendees; track attendee; let k =
                    $index){

                    <app-card-template
                      [title]="detailedItem.eventName"
                      [subtitle]="
                        detailedItem.itemName.concat(
                          ' ',
                          detailedItem.priceName!
                        )
                      "
                      [subsubtitle]="
                        (detailedItem.priceCents / 100 | currency)!
                          .toString()
                          .concat(
                            '+',
                            (detailedItem.feeCents / 100 | currency)!.toString()
                          )
                      "
                      [showContent]="true"
                    >
                      <div card-body>
                        <!-- Display Attendee Summary -->
                        <div *ngIf="isTicketCartItem(detailedItem.cartItem)">
                          <!-- <div>
                                {{detailedItem.cartItem.attendees[k][detailedItem.requiredFields![0].name]}}
                              </div>-->
                          <div>
                            @if(detailedItem.cartItem.attendees[k]){ @for(field
                            of detailedItem.ticket_fields; track field;){
                            <div class="attendee-field">
                              <strong>{{ field.field_id.label }}: </strong>
                              <span>{{ attendee[field.field_id.name] }}</span>
                            </div>

                            } }
                          </div>
                        </div>
                      </div>
                    </app-card-template>
                    } } } } }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cart Summary -->
        <div class="cart-summary">
          <h3>Total: {{ getTotalPriceDollars(totalPriceCents) | currency }}</h3>
          <!-- New Checkbox for Reviewing Cart -->
          <div>
            <mat-checkbox formControlName="reviewed" required>
              I have reviewed my cart
            </mat-checkbox>
            <mat-error
              *ngIf="
                reviewFormGroup.get('reviewed')?.hasError('required') &&
                reviewFormGroup.get('reviewed')?.touched
              "
            >
              You must confirm that you have reviewed your cart.
            </mat-error>
          </div>
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="accent" (click)="backToCart()">
            Edit Cart
          </button>
          <button
            mat-raised-button
            matStepperNext
            color="primary"
            matTooltip="Make Sure All Info is Correct"
            [disabled]="!reviewFormGroup.valid"
            disabledInteractive
            [matTooltipDisabled]="reviewFormGroup.valid"
          >
            Next
          </button>
          <!--<button mat-button color="warn" (click)="clearCart()">Clear Cart</button>-->
        </div>

        }@else {
        <div class="empty-cart-message">
          <h2>Your cart is empty.</h2>
          <a mat-raised-button color="primary" [routerLink]="['/events']"
            >Browse Events</a
          >
        </div>
        }
      </div>
    </form>
    </ng-template>
  </mat-step>

  <mat-step [stepControl]="paymentFormGroup">
    <ng-template matStepLabel>Checkout</ng-template>
    <ng-template matStepContent>
      <!-- Display a payment form -->
      @if(isLoading){
      <div class="loading-indicator">
        <mat-spinner></mat-spinner>
        <p>Loading checkout...</p>
      </div>
      }@else{
      <section class="table-container mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="dataSource">
          <!-- Item Column -->
          <ng-container matColumnDef="item" sticky>
            <th mat-header-cell *matHeaderCellDef>Item</th>
            <td mat-cell *matCellDef="let element">{{ element.eventName }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Price Column -->
          <ng-container matColumnDef="itemName">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.itemName }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Price Column -->
          <ng-container matColumnDef="priceName">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element">
              {{ element.priceName }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Price Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element">
              {{ getTotalPriceDollars(element.priceCents) | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Fee Column -->
          <ng-container matColumnDef="fee">
            <th mat-header-cell *matHeaderCellDef>Fee</th>
            <td mat-cell *matCellDef="let element">
              {{ getTotalPriceDollars(element.feeCents) | currency }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Quantity Column -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cartItem.quantity }}
            </td>
            <td mat-footer-cell *matFooterCellDef>Total</td>
          </ng-container>

          <!-- Total Column -->
          <ng-container matColumnDef="total" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let element">
              {{
                getTotalPriceDollars(
                  element.cartItem.quantity *
                    (element.feeCents + element.priceCents)
                ) | currency
              }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ getTotalPriceDollars(totalPriceCents) | currency }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
      </section>}
      <button mat-raised-button color="warn" matStepperPrevious>Back</button>

      <button mat-raised-button color="primary" (click)="checkout()">
        Proceed to Payment
      </button>
      @if(isCheckoutLoading){
        <div class="loading-indicator">
          <mat-spinner></mat-spinner>
          <p>Loading checkout...</p>
        </div>
      }
    </ng-template>
  </mat-step>
</mat-stepper>
