import { Injectable } from '@angular/core';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';
import { Athlete } from '../../models/new-athlete/new-athlete.model';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import {
  CardDisplay,
  CardOptions,
  Music,
} from '../../models/new-athlete/new-athlete.model';
import { Social } from '../../models/athlete/athlete.model';
import { AuthenticationService } from '@mosaicdash/services';
import { Official, OfficialCardDisplay } from '../../models/official/official.model';

@Injectable({
  providedIn: 'root',
})
export class OfficialService {
  private supabase: SupabaseClient;

  // BehaviorSubject to hold the current CardDisplay
  private workerCardDisplaySubject = new BehaviorSubject<
    OfficialCardDisplay[] | null
  >(null);
  public workerCardDisplay$ = this.workerCardDisplaySubject.asObservable();

  constructor(
    private supabaseService: SupabaseService,
    private authenticationService: AuthenticationService
  ) {
    this.supabase = this.supabaseService.getClient();
  }

  getCardDisplays(athleteId: string): Observable<OfficialCardDisplay[]> {
    // First, get the athlete_id associated with the user_id///WE SHOULD NOT DO THIS
    /*   return this.getAthleteUser(athleteId).pipe(
       switchMap(athleteUserResponse => {
         if (athleteUserResponse.error) {
           throw athleteUserResponse.error;
         }
        Extract the athlete_id from the response
         const athleteId = athleteUserResponse.data?.[0]?.athlete_id;
         // Prepare an array of IDs to query
         const idsToQuery = [userId];
         if (athleteId) {
           idsToQuery.push(athleteId);
         }*/
    // Now query the trading_cards table using both IDs
    return from(
      this.supabase
        .from('officials')
        .select(`*`)
        .eq('id', athleteId)
        //.order('teams(name)', { ascending: false }) // Adjust ordering as needed
    ).pipe(
      map((response) => {
        if (response.error) {
          throw response.error;
        }
        const data = response.data || [];
        const workerCardDisplays: OfficialCardDisplay[] = data.map((card) => ({
          id: card.id,
          name: card.name,
          pronunciation: card.pronunciation,
          nickname: card.nickname,
          date_of_birth: card.date_of_birth,
          state: card.state,
          city: card.city,
          gender: card.gender,
          usatf_certified: card.usatf_certified,
          usatf_number: card.usatf_number,
          contact_email: card.contact_email,
          contact_phone: card.contact_phone,
          imageUrl: card.imageUrl
        }));
        this.workerCardDisplaySubject.next(workerCardDisplays);
        return workerCardDisplays;
      }),
      catchError((error) => {
        console.error('Error fetching card displays:', error);
        return of([]); // Return an empty array in case of error
      })
    );
  }

  async getEditable(athleteId: string, userId: string): Promise<boolean> {
    const adminRole = await this.authenticationService.isAdmin(userId);
    if (adminRole) {
      return true;
    } else {
      const { data, error } = await this.supabase
        .from('athlete_users')
        .select('edit_access')
        .eq('athlete_id', athleteId)
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching edit access:', error);
        return false;
      }

      return data?.edit_access ?? false;
    }
  }

  // Update CardDisplay Data
  updateCardDisplayData(
    updatedCardDisplay: OfficialCardDisplay
  ): Observable<any> {
    // need to rewrite this
    return from(
      this.supabase
        .from('trading_cards')
        .update({
          name: updatedCardDisplay.name,
          pronunciation: updatedCardDisplay.pronunciation,
          nickname: updatedCardDisplay.nickname,
          imageUrl: updatedCardDisplay.imageUrl,
        })
        .eq('id', updatedCardDisplay.id)
    ).pipe(
      map((response) => {
        if (response.error) {
          throw response.error;
        }
        // Update the BehaviorSubject with the new data
        return response.data;
      }),
      catchError((error) => {
        console.error('Error updating card display data:', error);
        throw error;
      })
    );
  }

  updateCardPhotoUrl(cardId: string, uploadedUrl: string): Observable<any> {
    return from(
      this.supabase.rpc('update_trading_card_photo', {
        p_card_id: cardId,
        p_photo_url: uploadedUrl,
      })
    ).pipe(
      map((response) => {
        if (response.error) {
          throw response.error;
        }
        // Optionally update the BehaviorSubject with the new photo URL
        /* const currentCard = this.cardDisplaySubject.getValue();
          if (currentCard) {
            currentCard.photoUrl = uploadedUrl;
            this.cardDisplaySubject.next(currentCard);
          } */
        return response.data;
      }),
      catchError((error) => {
        console.error('Error updating card photo URL:', error);
        throw error;
      })
    );
  }

    // Updated to return a Promise<string[]>
    async getOfficialUser(id: string): Promise<Official[]> {
      const { data, error } = await this.supabase
        .from('official_users')
        .select('official_id(id, name)')
        .eq('user_id', id);
  
      if (error) {
        throw new Error(error.message);
      }
  
      // Assuming each record has an athlete_id field
      return data.map((record: any) => record.official_id);
    }
}
