<!--TODO CLean this up add user search bar-->

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    [ngClass]="(isHandset$ | async) ? 'sidenavMobile' : 'sidenav'"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
    position="end"
    (swiperight)="drawer.toggle()"
  >
    <mat-toolbar
      >Menu
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
      </button>
    </mat-toolbar>
    @if(!loading && session){
    <mat-tab-group
      class="tabs"
      dynamicHeight
      mat-stretch-tabs="false"
      mat-align-tabs="center"
      [selectedIndex]="selectedIndex"
      (swipeleft)="onSwipeLeft()"
      (swiperight)="onSwipeRight()"
    >
      @if (athlete) {
      <mat-tab class="tab">
        <ng-template mat-tab-label>
          <a class="label">Athlete</a>
        </ng-template>

        <mat-nav-list>
          <mat-form-field appearance="fill" class="selector-field card">
            <mat-label>Select Athlete</mat-label>
            <mat-select
              [(value)]="selectedAthlete"
              (selectionChange)="onSelectAthlete($event.value)"
            >
              <mat-option *ngFor="let athlete of athletes" [value]="athlete">
                {{ athlete.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<app-event-box [userID]="userId"></app-event-box>-->
          @if(selectedAthlete){
          <app-athlete-card
            class="card"
            [athleteID]="selectedAthlete.id!"
          ></app-athlete-card
          ><!--[sessionSent]=true [authSession]="session" -->

          <a mat-list-item href="{{ athleteLink }}" target="_blank"
            >View {{ selectedAthlete.name }}'s Athlete Profile</a
          >

          @if(!athlete){
          <a mat-list-item (click)="openRoleSelectionDialog()"
            >Create Athlete Profile</a
          >
          }@else {
          <a mat-list-item (click)="openTeamSelectionDialog()"
            >Assign {{ selectedAthlete.name }} to Team</a
          >
          }
          <a
            mat-list-item
            (click)="onCopy()"
            [cdkCopyToClipboard]="athleteURL"
            role="button"
            style="cursor: pointer"
            >Share {{ selectedAthlete.name }}'s Profile</a
          >
          }
          <a mat-list-item href="profile">Update Your Account</a>
        </mat-nav-list>
      </mat-tab>
      } @if(coach){
      <mat-tab class="tab">
        <ng-template mat-tab-label>
          <a class="label">Coach</a>
        </ng-template>
      </mat-tab>
      } @if(official){
      <mat-tab class="tab">
        <ng-template mat-tab-label>
          <a class="label">Official</a>
        </ng-template>
        <app-official-card
          class="card"
          [officialID]="selectedOfficial.id!"
        ></app-official-card>
      </mat-tab>
      }
      <mat-tab class="tab">
        <ng-template mat-tab-label>
          <a class="label">Edit Roles</a>
        </ng-template>
        @if(roles.length > 0){ 
          <a>Your Roles</a>
          @for(role of roles; track role){
        <li>
          {{ role.roles.name | titlecase }}
        </li>
        } }
        <button
          mat-raised-button
          class="block signOut"
          (click)="openRoleSelectionDialog()"
          color="primary"
        >
          Add Role
        </button>
      </mat-tab>
    </mat-tab-group>
    }

    <div class="sign-out">
      <button
        mat-raised-button
        class="block signOut"
        (click)="signOut()"
        color="warn"
      >
        Sign Out
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    @if(session){
    <mat-toolbar class="active" color="primary">
      @if(isHandset$ | async){
      <a class="home" href="home"><span>mosaic-sports</span></a
      >}<!--Change on Mobile-->
      @if(!(isHandset$ | async)){
      <a class="home" href="home"><span>mosaic-sports</span></a
      >}
      <!--<app-progress-bar></app-progress-bar> Not Used Yet-->
      <!--@if (isHandset$ | async) {-->
      <button
        matBadgePosition="below"
        matBadgeSize="small"
        matBadge="1"
        matBadgeColor="accent"
        [matBadgeOverlap]="true"
        [matBadgeHidden]="hidden"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle(); hidden = true"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>
    } @else {
    <mat-toolbar class="notActive" color="primary">
      <a class="home" href="home"><span>mosaic-sports</span></a>
      <a class="login" href="profile/login">Login</a>
    </mat-toolbar>
    }
    <router-outlet> </router-outlet>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
