import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { Observable, catchError, from, map, throwError } from 'rxjs';
import { Team } from '../../models/team/team.model';
import { SupabaseService } from '@mosaicdash/services';
import { TradingCard } from '../../models/new-athlete/new-athlete.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Fetch all teams
  getTeams(): Observable<Team[]> {
    return from(
      this.supabase
        .from('teams')
        .select('*, team_sport_variations(*,sport_variation_id(*))')
        .order('name', { ascending: true }))
      .pipe(
        map(response => {
          if (response.error) {
            throw response.error;
          }
          let teams = response.data as Team[];

          // Find the index of "United States"
          const unassignedIndex = teams.findIndex(team => team.name.toLowerCase() === 'unassigned');

          // Remove "United States" from its current position
          teams.splice(unassignedIndex, 1);
          // Add "United States" at the beginning of the array

          return teams;
        }),
        catchError(error => {
          //this.countriesCache$ = null; // Reset cache on error
          return throwError(() => new Error(error.message));
        }),
        //shareReplay(1) // Cache the latest emitted value
      );
  }

 /* getUserTeams(): Observable<Team[]> {
    return from(
      this.supabase
      .from('athlete_users')
      .select('athlete_teams(teams(*))')
      .eq('user_id','0daed35c-e08e-4463-9134-111315626bb1')
       .returns<Team[]>()
    ).pipe(
        map(response => {
          if (response.error) {
            throw response.error;
          }
          let teams = response.data as Team[];

          // Find the index of "United States"
          const unassignedIndex = teams.findIndex(team => team.name.toLowerCase() === 'unassigned');

          // Remove "United States" from its current position
          teams.splice(unassignedIndex, 1);
          // Add "United States" at the beginning of the array
          console.log(teams)
          return teams;
        }),
        catchError(error => {
          //this.countriesCache$ = null; // Reset cache on error
          return throwError(() => new Error(error.message));
        }),
        //shareReplay(1) // Cache the latest emitted value
      );
  }*/

  joinTeam(teamId: string, gender: string, sportVariationId: string): Observable<any> {
    return from(
      this.supabase.rpc('join_team', {
        p_team_id: teamId,
        p_gender: gender,
        p_sport_variation_id: sportVariationId,
      })
    ).pipe(
      catchError((error) => {
        console.error('Error joining team:', error);
        throw error;
      })
    );
  }
}











