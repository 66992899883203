import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Event, EventSubEvent } from '../../../models/event/event.model';
import { AuthenticationService } from '@mosaicdash/services';
import { EventService } from '../../../services/event/event.service'
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CardTemplateComponent } from '../card-template/card-template.component';
import { PurchaseData, Ticket, TicketPrice } from '../../../models/ticket/ticket.model';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { TicketService } from '../../../services/ticket/ticket.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericEditDialogComponent } from '../generic-edit-dialog/generic-edit-dialog.component';
import { DialogConfig } from '../../../models/edit-dialog/edit-dialog.model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CartItem, NewCartItem, TicketCartItem } from '../../../models/cart/cart.model';
import { CartService } from '../../../services/cart/cart.service';
import { Subscription } from 'rxjs';
import { RouterLink } from '@angular/router';

function isTicketCartItem(item: CartItem): item is TicketCartItem {
  return item.type === 'ticket';
}


@Component({
  selector: 'app-ticketing-card',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    CardTemplateComponent,
    CurrencyPipe,
    JsonPipe,
    MatTooltipModule,
    RouterLink
  ],
  templateUrl: './ticketing-card.component.html',
  styleUrl: './ticketing-card.component.css'
})
export class TicketingCardComponent implements OnInit, OnDestroy {
  @Input() ticket?: Ticket; // Undefined when creating a new sub-event
  @Input() event!: Event;
  @Output() eventSubEventSaved = new EventEmitter<EventSubEvent>();
  @Output() eventSubEventCancelled = new EventEmitter<void>();
  @Input() editable: boolean = true; // Changed to @Input for flexibility

  isEditing: boolean = false;
  eventTicketBuyForm: FormGroup;
  eventTicketEditForm: FormGroup;
  isRegistered: boolean = false;

  // New properties for handling TicketPrices
  currentPriceIndex: number = 0;
  isFlipping: boolean = false;

  // Variable to store the current quantity in the cart
  currentCartQuantity: number = 0;
  currentCartItemId: string | null = null;

  latestCartItems: CartItem[] = [];
  private cartSubscription: Subscription | undefined;

  constructor(
    private cdRef: ChangeDetectorRef, // Inject ChangeDetectorRef
    private fb: FormBuilder,
    private eventService: EventService,
    private ticketService: TicketService,
    readonly authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private cartService: CartService // Inject CartService
  ) {
    // Initialize Edit Form with FormArray for ticket_prices
    this.eventTicketEditForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
      ticket_prices: this.fb.array([
        this.fb.group({
          label: ['', Validators.required],
          price: [0, [Validators.required, Validators.min(0)]],
        })
      ])
    });
    // Initialize Buy Form
    this.eventTicketBuyForm = this.fb.group({
      quantity: [1, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit(): void {
    if (this.ticket) {
      // Subscribe to cart items to get current CartItem details
      this.cartSubscription = this.cartService.getCartItems().subscribe(cartItems => {
        this.latestCartItems = cartItems;
        if (this.currentTicketPrice) {
          // Find the CartItem with the currentTicketPrice.id
          const matchingCartItem = cartItems.find(item =>
            item.type === 'ticket' &&
            (item as TicketCartItem).ticketPriceId === this.currentTicketPrice!.id
          );

          this.currentCartItemId = matchingCartItem ? matchingCartItem.id : null;
          this.currentCartQuantity = matchingCartItem ? matchingCartItem.quantity : 0;
        }
      });

      // Populate ticket_prices FormArray
      if (this.ticket.ticket_prices && this.ticket.ticket_prices.length) {
        const pricesFormArray = this.eventTicketEditForm.get('ticket_prices') as FormArray;
        pricesFormArray.clear();
        this.ticket.ticket_prices.forEach(price => {
          pricesFormArray.push(this.fb.group({
            label: [price!.label, Validators.required],
            price: [price!.price, [Validators.required, Validators.min(0)]],
          }));
        });
      }
    }
  }


  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }


  get ticketPrices(): FormArray {
    return this.eventTicketEditForm.get('ticket_prices') as FormArray;
  }



  toggleEditMode(): void {
    this.isEditing = !this.isEditing;
    if (!this.ticket) {
      // Logic to handle creating a new ticket
      // For example, open the dialog to create a new ticket
      this.openEditTicketDialog();
    }
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.eventSubEventCancelled.emit();
  }

  // Methods for navigating TicketPrices
  nextPrice(): void {
    if (this.ticket && this.ticket.ticket_prices!.length > 0) {
      this.isFlipping = true;
      setTimeout(() => {
        this.currentPriceIndex = (this.currentPriceIndex + 1) % this.ticket!.ticket_prices!.length;
        this.isFlipping = false;
        this.updateCurrentCartQuantity();
      }, 500); // Duration should match the CSS transition
    }
  }

  previousPrice(): void {
    if (this.ticket && this.ticket.ticket_prices!.length > 0) {
      this.isFlipping = true;
      setTimeout(() => {
        this.currentPriceIndex = (this.currentPriceIndex - 1 + this.ticket!.ticket_prices!.length) % this.ticket!.ticket_prices!.length;
        this.isFlipping = false;
        this.updateCurrentCartQuantity();
      }, 500); // Duration should match the CSS transition
    }
  }

  get currentTicketPrice(): TicketPrice | null {
    if (this.ticket && this.ticket.ticket_prices!.length > 0) {
      return this.ticket.ticket_prices![this.currentPriceIndex]!;
    }
    return null;
  }

  hasMultiplePrices(): boolean {
    return this.ticket!.ticket_prices!.length > 1;
  }

  displayLeftArrow(): boolean {
    return this.ticket!.ticket_prices!.length > 1 && this.currentPriceIndex > 0;
  }

  displayRightArrow(): boolean {
    return this.ticket!.ticket_prices!.length > 1 && this.currentPriceIndex < this.ticket!.ticket_prices!.length - 1;
  }

  // Method to update the currentCartQuantity based on the selected price
  updateCurrentCartQuantity(): void {
    if (this.currentTicketPrice) {
      const matchingCartItem = this.latestCartItems.find(item =>
        item.type === 'ticket' &&
        (item as TicketCartItem).ticketPriceId === this.currentTicketPrice!.id
      );

      this.currentCartItemId = matchingCartItem ? matchingCartItem.id : null;
      this.currentCartQuantity = matchingCartItem ? matchingCartItem.quantity : 0;
    } else {
      this.currentCartItemId = null;
      this.currentCartQuantity = 0;
    }

    // Trigger change detection to update the UI
    this.cdRef.detectChanges();
  }

  // Updated Method to Add Ticket to Cart
  addToCart(): void {
    if (!this.ticket || !this.currentTicketPrice) {
      this.snackBar.open('Ticket information is unavailable.', 'Close', { duration: 3000 });
      return;
    }

    const quantity = this.eventTicketBuyForm.value.quantity;

    if (quantity < 1) {
      this.snackBar.open('Quantity must be at least 1.', 'Close', { duration: 3000 });
      return;
    }

    const ticketItem: NewCartItem = {
      type: 'ticket',
      ticketId: this.ticket.id!,
      ticketPriceId: this.currentTicketPrice.id!,
      quantity: quantity,
      attendees: [quantity]
    };

    this.cartService.addToCart(ticketItem);
    this.snackBar.open('Ticket added to cart!', 'Close', { duration: 3000 });

    // Optionally, reset the quantity to 1
    this.eventTicketBuyForm.reset({ quantity: 1 });
  }

  // Open Dialogs Using the Generic Dialog Component
  openEditTicketDialog(): void {
    const config: DialogConfig = {
      title: this.ticket ? 'Edit Ticket' : 'Create Ticket',
      fields: [
        {
          name: 'title',
          label: 'Event Name',
          type: 'text',
          required: true
        },
        /* {
           name: 'description',
           label: 'Description',
           type: 'text',
           validators: [Validators.required]
         },
         {
           name: 'type',
           label: 'Type',
           type: 'text',
           validators: [Validators.required]
         },*/
        // Add other fields as necessary
      ],
      data: this.ticket,
      isPriceDialog: false
    };

    const dialogRef = this.dialog.open(GenericEditDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: config
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.ticket) {
          // Update existing ticket
          const updatedTicket: Ticket = {
            ...this.ticket,
            title: result.title,
            //description: result.description,
            //type: result.type,
          };

          this.ticketService.updateTicket(this.ticket.id!, updatedTicket).subscribe({
            next: (updated) => {
              this.snackBar.open('Ticket updated successfully!', 'Close', { duration: 3000 });
              this.ticket = updated;
              this.eventSubEventSaved.emit(); // Emit event if necessary
            },
            error: (error) => {
              this.snackBar.open('Failed to update ticket.', 'Close', { duration: 3000 });
              console.error(error);
            }
          });
        } else {
          // Create new ticket
          const newTicket: Partial<Ticket> = {
            title: result.title,
            //description: result.description,
            //type: result.type,
            ticket_prices:[
              {
                label: 'Standard',
                price: 0,
                fee: 0,
              }
            ], // Initialize as needed
            // Add other necessary fields
          };

          this.ticketService.createTicket(this.event.id!, newTicket).subscribe({
            next: (createdTicket) => {
              this.snackBar.open('Ticket created successfully!', 'Close', { duration: 3000 });
              this.ticket = createdTicket;
              this.eventSubEventSaved.emit(); // Emit event if necessary
            },
            error: (error) => {
              this.snackBar.open('Failed to create ticket.', 'Close', { duration: 3000 });
              console.error(error);
            }
          });
        }
      }
    });
  }

  editTicket(): void {
    this.openEditTicketDialog();
  }

  editTicketPrice(): void {
    if (!this.currentTicketPrice) {
      this.snackBar.open('No price available to edit.', 'Close', { duration: 3000 });
      return;
    }

    const config: DialogConfig = {
      title: 'Edit Ticket Price',
      fields: [
        {
          name: 'label',
          label: 'Price Label',
          type: 'text',
          required: true
        },
        {
          name: 'price',
          label: 'Price ($)',
          type: 'number',
          validators: [Validators.min(0)],
          required: true
        },
        // Add other fields as necessary
      ],
      data: this.currentTicketPrice,
      isPriceDialog: true
    };

    const dialogRef = this.dialog.open(GenericEditDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: config
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.currentTicketPrice) {
        const updatedPrice: TicketPrice = {
          ...this.currentTicketPrice,
          label: result.label,
          price: result.price,
        };

        this.ticketService.updateTicketPrice(updatedPrice).subscribe({
          next: (updated) => {
            this.snackBar.open('Ticket price updated successfully!', 'Close', { duration: 3000 });
            // Update the local ticket_prices array
            const index = this.ticket!.ticket_prices!.findIndex(p => p!.id === updated.id);
            if (index !== -1) {
              this.ticket!.ticket_prices![index] = updated;
            }
            this.eventSubEventSaved.emit(); // Emit event if necessary
          },
          error: (error) => {
            this.snackBar.open('Failed to update ticket price.', 'Close', { duration: 3000 });
            console.error(error);
          }
        });
      }
    });
  }

  // Save New Ticket
  saveNewTicket(): void {
    if (this.eventTicketEditForm.invalid) {
      this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
      return;
    }

    const ticketData: Partial<Ticket> = {
      title: this.eventTicketEditForm.value.title,
      description: this.eventTicketEditForm.value.description,
      type: this.eventTicketEditForm.value.type,
      ticket_prices: [], // Initially empty, manage via dialogs
      // Add other necessary fields
    };

    this.ticketService.createTicket(this.event.id!, ticketData).subscribe({
      next: (newTicket) => {
        this.snackBar.open('Ticket created successfully!', 'Close', { duration: 3000 });
        this.ticket = newTicket;
        this.isEditing = false;
        this.eventSubEventSaved.emit(); // Emit event if necessary
      },
      error: (error) => {
        this.snackBar.open('Failed to create ticket.', 'Close', { duration: 3000 });
        console.error(error);
      }
    });
  }

  // Method to increase quantity in the cart
  increaseQuantity(): void {
    if (this.currentCartItemId) {
      const newQuantity = this.currentCartQuantity + 1;
      this.cartService.updateQuantity(this.currentCartItemId, newQuantity);
      this.snackBar.open('Quantity updated.', 'Close', { duration: 2000 });
    }
  }

  // Method to decrease quantity in the cart
  decreaseQuantity(): void {
    if (this.currentCartItemId) {
      const newQuantity = this.currentCartQuantity - 1;
      if (newQuantity <= 0) {
        this.removeFromCart();
      } else {
        this.cartService.updateQuantity(this.currentCartItemId, newQuantity);
        this.snackBar.open('Quantity updated.', 'Close', { duration: 2000 });
      }
    }
  }

  // Method to remove the ticket from the cart
  removeFromCart(): void {
    if (this.currentCartItemId) {
      this.cartService.removeFromCart(this.currentCartItemId);
      this.snackBar.open('Ticket removed from cart.', 'Close', { duration: 2000 });
    }
  }
}

// Saving the Ticket (Create or Update)
/*   saveTicket(): void {
     if (this.eventTicketEditForm.invalid) {
       this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
       return;
     }
 
     const ticketData: Partial<Ticket> = {
       id: this.ticket?.id ,
       title: this.eventTicketEditForm.value.title,
       description: this.eventTicketEditForm.value.description,
       type: this.eventTicketEditForm.value.type,
       ticket_prices: this.eventTicketEditForm.value.ticket_prices.map((price: any) => ({
         id: price.id || null, // Assuming you handle ID assignment in the backend
         label: price.label,
         price: price.price,
         ticket_id: this.ticket?.id || null, // Set appropriately
       })),
       // Add other necessary fields
     };
 
     if (this.ticket) {
       // Update existing ticket
       this.ticketService.updateTicket(this.ticket.id!, ticketData).subscribe({
         next: (updatedTicket) => {
           this.snackBar.open('Ticket updated successfully!', 'Close', { duration: 3000 });
           this.ticket = updatedTicket;
           this.isEditing = false;
           this.eventSubEventSaved.emit(); // Emit event if necessary
         },
         error: (error) => {
           this.snackBar.open('Failed to update ticket.', 'Close', { duration: 3000 });
           console.error(error);
         }
       });
     } else {
       // Create new ticket
       this.ticketService.createTicket(this.event.id!, ticketData).subscribe({
         next: (newTicket) => {
           this.snackBar.open('Ticket created successfully!', 'Close', { duration: 3000 });
           this.ticket = newTicket;
           this.isEditing = false;
           this.eventSubEventSaved.emit(); // Emit event if necessary
         },
         error: (error) => {
           this.snackBar.open('Failed to create ticket.', 'Close', { duration: 3000 });
           console.error(error);
         }
       });
     }
   }
 }*/

/*saveEventTicket(): void {
  if (this.eventTicketForm.invalid) {
    this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
    return;
  }

  const subEventData: Partial<Ticket> = this.eventTicketForm.value;
  subEventData.id=this.ticket?.id
  console.log(subEventData)
    this.eventService.upsertEventSubEvent(this.event.id!, subEventData).subscribe({
      next: (updatedEventSubEvent) => {
        this.snackBar.open('Sub-event updated successfully!', 'Close', { duration: 3000 });
        this.isEditing = false;
        // Optionally, emit an event or refresh data
        console.log("test:",updatedEventSubEvent)
        this.eventSubEventSaved.emit(updatedEventSubEvent); // Emit the event with the updated sub-event
      },
      error: (error) => {
        console.error('Error updating sub-event:', error);
        this.snackBar.open('Failed to update sub-event.', 'Close', { duration: 3000 });
      }
    });
 
}*/


