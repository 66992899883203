<div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>Effective Date: 05/20/24</p>
  
    <h2>1. Introduction</h2>
    <p>Welcome to mosaic-sports, a project by mosaicdash LLC ("we", "our", "us"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at <a href="mailto:contact@mosaic-sports.com">contact&#64;mosaic-sports.com</a>.</p>
  
    <h2>2. Information We Collect</h2>
    <p>We collect personal information that you provide to us when you register for an event, create a user profile, or make a payment. This information includes:</p>
    <ul>
      <li>Personal Identification Information: Name, email address, phone number, and other contact details.</li>
      <li>Financial Information: Payment details to facilitate transactions.</li>
    </ul>
  
    <h2>3. How We Use Your Information</h2>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
      <li>To provide and manage our services, including event registration and user profile creation.</li>
      <li>To process payments and manage transactions.</li>
      <li>To improve our services and user experience.</li>
      <li>To communicate with you regarding updates, promotions, and other relevant information.</li>
    </ul>
  
    <h2>4. Sharing Your Information</h2>
    <p>We do not share your personal information with third parties except as necessary to facilitate event registration and to display your public profiles. This may include:</p>
    <ul>
      <li>Third-party service providers involved in event management and payment processing (e.g., Stripe).</li>
      <li>Analytics providers (e.g., Google Analytics) to help us understand how our services are used.</li>
    </ul>
  
    <h2>5. Cookies and Tracking Technologies</h2>
    <p>We use cookies and similar tracking technologies to track activity on our service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.</p>
    <p>We use cookies for the following purposes:</p>
    <ul>
      <li>Google Analytics: To analyze website usage and improve our services.</li>
      <li>Supabase: For authentication and data management.</li>
      <li>Stripe: To facilitate payment processing.</li>
    </ul>
  
    <h2>6. Your Data Protection Rights</h2>
    <p>You have the following rights regarding your personal information:</p>
    <ul>
      <li>The right to access – You have the right to request copies of your personal information.</li>
      <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
      <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
      <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
      <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
      <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>
  
    <h2>7. Security of Your Information</h2>
    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
  
    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new privacy policy on this page.</p>
    <p>This privacy policy was last updated on 05/20/24.</p>
  
    <h2>9. Contact Us</h2>
    <p>If you have any questions about this privacy policy, please contact us:</p>
    <ul class="contact-info">
      <li>By email: <a href="mailto:contact@mosaic-sports.com">contact&#64;mosaic-sports.com</a></li>
      <li>By phone: 347-746-7653</li>
    </ul>
  </div>
  