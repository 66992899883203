<mat-card class="card">
    @if(cardDisplays){
    <!--<div class="card-selector" *ngIf="!isLoading">
        <mat-form-field appearance="fill" class="selector-field">
            <mat-label>Select Card</mat-label>
            <mat-select [(value)]="selectedCard" (selectionChange)="onSelectCard($event.value)">
                <mat-option *ngFor="let card of cardDisplays" [value]="card">
                   @if(card.season_id?.sport_id?.name==='Athlete'){
                    Default
                    }@else {
                    {{ card.team_id?.name}} - {{ card.season_id?.start_date | date: 'yyyy'}}-{{card.season_id?.end_date
                    | date: 'yyyy' }} - {{card.season_id?.sport_variation_id?.name}}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>-->

    @if(selectedCard&&!isLoading){
    <app-card-template [title]="selectedCard.name ? selectedCard.name : 'Click The Pencil To Edit!'"
        [subtitle]="selectedCard.pronunciation ? selectedCard.pronunciation: ''"
        [subsubtitle]="(selectedCard.nickname)? ('\u0022'+selectedCard.nickname +'\u0022'): ''"
        [image]="selectedCard.imageUrl|| defaultAvatar" [showContent]="selectedCard ? true : false"
        [showImage]="(section.match('front')?true:false)&&!isEditingImage">
        <div card-body>
           <!-- @if(section.match("front")){
            <p><strong>{{selectedCard.line1}}</strong></p>
            <p><strong>{{selectedCard.team_name}} {{selectedCard.team_mascot}}</strong></p>
            <p><strong>{{selectedCard.line2}}</strong></p>
            <p *ngIf="selectedCard.coach"><strong>Coached By {{selectedCard.coach}}</strong></p>
            }@else if (section.match("back")) {
            <p><strong>Notes</strong></p>
            @if(editable && !selectedCard.notes){
            <p>Click The Pencil to Add Notes!</p>
            }
            <p>{{selectedCard.notes}}</p>}
            @else if (section.match("music")) {
            <p><strong>Music</strong></p>
        }-->

            <div *ngIf="isEditingImage" class="user-avatar">
                <!-- Use the ImageCropperComponent -->
                <app-image-cropper
                #imageCropper
                  [imageUrl]="imageUrl"
                  (imageCropped)="onImageCropped($event)"
                  (croppingCanceled)="onCroppingCanceled()"
                ></app-image-cropper>
              </div>
        </div>
        <div card-actions>
            <mat-card-actions class="card-actions">
                <div class="button-grid-container">
                    <div class="button-grid">
                        @if(!isEditingImage){
                        <div id="B1">
                            @if(editable){
                            <a id="edit-button" mat-icon-button color="primary" aria-label="Account"
                                (click)="openEditDialog()">
                                <mat-icon>edit</mat-icon>
                            </a>
                            }
                        </div>
                        <div id="B2">
                            @if(officialID)
                            {
                            <a href="{{userLink}}" id="profile" mat-icon-button color="primary"
                                aria-label="Account"><mat-icon>
                                    person</mat-icon></a>
                            }<!--@else if(tempID){
                            <a href="profile" id="claimButton" mat-raised-button color="primary" aria-label="Account">
                                Claim
                                Your Profile!</a>
                            }-->
                        </div>
                        @if(music && editable){
                        <a id="music" mat-icon-button color="primary" aria-label="Music"
                            (click)="section='music'"><mat-icon>music_note</mat-icon></a>
                        }@else{
                        <div id="B4"></div>
                        }
                        <!-- <a href="" id="B4" mat-icon-button color="primary" aria-label="Account"><mat-icon>shop</mat-icon></a>
                    <a href="" id="B5" mat-icon-button color="primary"
                        aria-label="Account"><mat-icon>event</mat-icon></a>-->
                        <div id="B4"></div>
                        <div id="B5"></div>
                        <!--<div id="B6">
                            @if(selectedCard.notes || editable|| !section.match("front"))
                            {
                            @if(section.match("front")){
                            <button matBadgePosition="above before" matBadgeSize="small" matBadge="!"
                                matBadgeColor="accent" [matBadgeOverlap]=true
                                [matBadgeHidden]="(selectedCard.notes || cardBack)"
                                (click)="section='back'; reloadData();" id="front" mat-icon-button color="primary"
                                aria-label="Flip card"><mat-icon>
                                    auto_stories</mat-icon></button>
                            } @else {
                            <button matBadgePosition="above before" matBadgeSize="small" matBadge="!"
                                matBadgeColor="accent" [matBadgeOverlap]=true
                                [matBadgeHidden]="(selectedCard.notes || cardBack)"
                                (click)="section='front'; reloadData();" id="back" mat-icon-button color="primary"
                                aria-label="Flip card"><mat-icon>
                                    auto_stories</mat-icon></button>
                            }
                            }
                        </div>-->
                        <div id="B7" *ngIf="editable">
                            <button mat-icon-button color="primary" aria-label="Edit Image" id="photo"
                                (click)="toggleImageEdit()">
                                <mat-icon>photo_camera</mat-icon>
                            </button>
                        </div>
                        }
                        <!-- Add Save and Cancel buttons when editing image -->
                        @if(isEditingImage){

                        <button mat-icon-button color="primary" (click)="onSaveCrop()"
                            [disabled]="isUploading"><mat-icon>
                                save</mat-icon></button>
                        <button mat-icon-button color="warn" (click)="onCancelCrop()"><mat-icon>
                                cancel</mat-icon></button>
                        <div *ngIf="isUploading" class="loading-indicator">
                            <mat-spinner diameter="24"></mat-spinner>
                        </div>
                        }
                    </div>
                </div>
            </mat-card-actions>
        </div>
    </app-card-template>
    }@else if(isLoading) {
    <div class="loading-container">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
        <p>Loading Card...</p>
    </div>
    }@else {
    @if(editable){
        <div class="center-container">
            <div class="center">No Cards Yet!</div>
            <button mat-raised-button color="primary" (click)="createAthlete()" class="role-button">Select Role</button>
        </div><!--Make a blank Card TODO-->
    }@else {


    <div class="center-container">
        <div class="center">No Cards Yet!</div>
    </div><!--Make a blank Card TODO-->
    }
    }
    } @else {
    <div class="loading-container">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
        <p>Loading Data...</p>
    </div>
    }
</mat-card>
