import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Sport, SportVariation } from '../../models/sport/sport.model';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';

@Injectable({
  providedIn: 'root'
})
export class SportService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Fetch all sports
  getSports(): Observable<Sport[]> {
    return from(
      this.supabase
        .from('sports')
        .select('*')
        .order('name', { ascending: true })
        .then(response => {
          if (response.error) {
            throw response.error;
          }
          return response.data;
        })
    );
  }

  // Fetch variations based on sport ID
  getSportVariations(sportId: number): Observable<SportVariation[]> {
    return from(
      this.supabase
        .from('sport_variations')
        .select('*')
        .eq('sport_id', sportId)
        .order('display_order', { ascending: true })
        .then(response => {
          if (response.error) {
            throw response.error;
          }
          return response.data;
        })
    );
  }
}