import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Event } from '../../../models/event/event.model';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CardTemplateComponent } from '../card-template/card-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@mosaicdash/services';
import { EventService } from '../../../services/event/event.service';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-event-card',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    CardTemplateComponent,
    RouterLink
  ],
  templateUrl: './event-card.component.html',
  styleUrl: './event-card.component.css'
})
export class EventCardComponent {
  @Input() event!: Event
  @Output() eventSaved = new EventEmitter<Event>();
  @Output() eventCancelled = new EventEmitter<void>();
  editable = true // add input

  isEditing: boolean = false;
  eventForm: FormGroup;
  isRegistered: boolean = false;

  base64Id: string = '';

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    readonly authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private readonly URLHandler: URLHandlerService
  ) {
    this.eventForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      event_type: ['', Validators.required],
      // Add other necessary fields
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.event) {
      this.base64Id = await this.URLHandler.hexToBase64(this.event.id!)
      this.eventForm.patchValue(this.event);
      // Optionally, check if the current user is registered
      //this.checkRegistration();
    }
  }

  toggleEditMode(): void {
    this.isEditing = !this.isEditing;
    if (!this.event) {
      this.eventForm.reset();
    }
  }
  saveEvent(): void {
    if (this.eventForm.invalid) {
      this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
      return;
    }

    const eventData: Partial<Event> = this.eventForm.value;
    console.log(eventData)
    eventData.id = this.event.id
    this.eventService.upDateEvent(eventData).subscribe({
      next: (updatedEvent) => {
        this.snackBar.open('Sub-event updated successfully!', 'Close', { duration: 3000 });
        this.isEditing = false;
        // Optionally, emit an event or refresh data
        console.log("test:", updatedEvent)
        this.eventSaved.emit(updatedEvent); // Emit the event with the updated sub-event
      },
      error: (error) => {
        console.error('Error updating sub-event:', error);
        this.snackBar.open('Failed to update sub-event.', 'Close', { duration: 3000 });
      }
    });

  }


  cancelEdit(): void {
    this.isEditing = false;
    this.eventCancelled.emit();
  }


}
