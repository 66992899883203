<div class="container">
    <h2>On Your Mark! Get Set! Get Paid!</h2>
    <p>
      Mosaic Sports is the world's leading Olympic Sport payment platform: Sign up to get paid faster.
    </p>
  
    <button mat-raised-button (click)="signUp()" [disabled]="isLoading" color="primary">
      {{ isLoading ? 'Signing up...' : 'Sign up' }}
    </button>
  
    <div id="embedded-onboarding-container"></div>
  
    <p *ngIf="connectedAccountId">
      Your connected account ID is: <strong>{{ connectedAccountId }}</strong>
    </p>
  
    <p *ngIf="error" class="error">{{ error }}</p>
  </div>
  