<app-card-template 
  [title]="eventSubEvent ? eventSubEvent.name : 'Create New Sub-Event'"
  [subtitle]="eventSubEvent ? 'Edit Mode' : 'Create Mode'"
  [description]="eventSubEvent ? eventSubEvent.description : 'Fill in the details for the new sub-event.'"
  [isEditing]="isEditing"
  [formGroup]="eventSubEventForm" 
  [showContent]="eventSubEvent ? true : false">
<!--[image]="eventSubEvent ? 'assets/sub-event-image.jpg' : 'assets/create-sub-event.jpg'" [isEditing]="isEditing"-->
  <!-- Projected Content for Display Mode -->
  @if(!isEditing && eventSubEvent){
  <div card-body>
    <p>Distance: {{ eventSubEvent.name }}</p>
    <p>Type: {{ eventSubEvent.name }}</p>
    <!-- Add other sub-event details -->
  </div>
  }
  <!-- Projected Content for Edit Mode -->
  @if(isEditing){
  <div card-edit>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Event Name</mat-label>
      <input matInput formControlName="name" />
      @if(eventSubEventForm.get('name')?.invalid){
      <mat-error>Name is required</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      @if(eventSubEventForm.get('description')?.invalid){
      <mat-error>Description is required</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Type</mat-label>
      <input matInput formControlName="type" />
      @if(eventSubEventForm.get('type')?.invalid){
      <mat-error>Type is required</mat-error>
      }
    </mat-form-field>
    <!-- Add other fields as necessary -->
  </div>
  }
  <!-- Projected Action Buttons -->
  <div card-actions>
    <!-- Display Mode Action Buttons -->
    @if(!isEditing && eventSubEvent){
    <ng-container>
      @if(editable){
      <a id="edit-button" mat-icon-button color="primary" aria-label="Edit Sub-Event"
        (click)="toggleEditMode()">
        <mat-icon>edit</mat-icon>
      </a>
    }
      <!-- Add other buttons as needed -->
    </ng-container>
    }
    <!-- Edit Mode Action Buttons -->
    @if(isEditing){
    <ng-container>
      <button mat-raised-button color="primary" type="submit" [disabled]="eventSubEventForm.invalid" (click)="saveEventSubEvent()">
        {{ eventSubEvent ? 'Update' : 'Create' }}
      </button>
      <button mat-raised-button color="warn" type="button" (click)="cancelEdit()">Cancel</button>
    </ng-container>
  }
  </div>
</app-card-template>

<!-- Add New Sub-Event Button -->
 @if(!eventSubEvent && !isEditing && editable){
<div class="add-button">
  <button mat-raised-button color="accent" (click)="toggleEditMode()">Add New
    {{event.sport_variations?.sub_event_name}}</button>
</div>
 }



<!-- sub-event-card.component.html with Angular Material 
 @if(eventSubEvent || isEditing){
<mat-card class="card">
  Display Mode
    @if(!isEditing && eventSubEvent){
    <mat-card-header class="card-header">
      <mat-card-title>{{ eventSubEvent.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="card-body">
        <p>Distance: {{ eventSubEvent.name }}</p>
        <p>Type: {{ eventSubEvent.description }}</p>
       Add other sub-event details
      </mat-card-content>
      <mat-card-actions  class="card-actions">
         Action Buttons for Directors 
        <div class="button-grid-container">
            <div class="button-grid">
                <div id="B1">
                    <a id="edit-button" mat-icon-button color="primary" aria-label="Account" *ngIf="authenticationService.getUserRole('director')" (click)="toggleEditMode()">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <div id="B2"></div>
                    <div id="B3"></div>
                    <div id="B4"></div>
                    <div id="B5"></div>
                    <div id="B6"></div>
                    <div id="B7"></div>
        </div>
        </div>
        </div>

  
        Action Buttons for Competitors 
        <button mat-button color="primary" *ngIf="authenticationService.getUserRole('competitor') && !isRegistered" (click)="register()">
          Register
        </button>
        <button mat-button color="warn" *ngIf="authenticationService.getUserRole('competitor') && isRegistered" (click)="deregister()">
          Deregister
        </button>
      </mat-card-actions>
    }
  
    Create/Edit Mode
    @if(isEditing){
        <mat-card-header class="card-header">
      <mat-card-title>{{ eventSubEvent ? 'Edit Sub-Event' : 'Create New Sub-Event' }}</mat-card-title>
      </mat-card-header><form [formGroup]="eventSubEventForm" (ngSubmit)="saveEventSubEvent()">
      <mat-card-content class="card-body">
        
          <mat-form-field appearance="fill">
            <mat-label>Event Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="eventSubEventForm.get('name')?.invalid">Name is required</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
            <mat-error *ngIf="eventSubEventForm.get('description')?.invalid">Description is required</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <input matInput formControlName="type" />
            <mat-error *ngIf="eventSubEventForm.get('type')?.invalid">Type is required</mat-error>
          </mat-form-field>
     
      </mat-card-content>
           Add other fields as necessary
          <mat-card-actions  class="card-actions">
          Action Buttons for Directors
            <div class="button-grid-container">
                <div class="button-grid">
                    <div id="B1">
          <button mat-raised-button color="primary" type="submit" [disabled]="eventSubEventForm.invalid">
            {{ eventSubEvent ? 'Update' : 'Create' }}
          </button></div>
          <div id="B2">
          <button mat-raised-button color="primary" type="button" (click)="cancelEdit()">Cancel</button>
          </div>
          </div>
          </div>
          </mat-card-actions>
     </form>
    }
  </mat-card>
 }
       Add New Sub-Event Button (only for directors and when creating a new sub-event)
    <div *ngIf="!eventSubEvent && !isEditing && authenticationService.getUserRole('director')" class="add-button">
      <button mat-raised-button color="accent" (click)="toggleEditMode()">Add New {{event.sport_variations?.sub_event_name}}</button>
    </div> -->