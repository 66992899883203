<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<div mat-dialog-content class="content">
  <form [formGroup]="editForm">

    @if(isBatchEdit){
    <div formArrayName="entries">
      @for(attendee of attendees.controls; let i = $index; track attendee){
      <div [formGroupName]="i" class="entry-group">
        <h3>Attendee {{ i + 1 }}</h3>
        @for(field of fields; track field){
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ field.label }}</mat-label>

          <!-- Dynamic Input Types -->
          @switch(field.type){
          @case('text'){<input matInput [type]="field.type" formControlName="{{field.name}}">}
          @case('email'){<input matInput [type]="field.type" formControlName="{{field.name}}">}
          @case('number'){<input matInput [type]="field.type" formControlName="{{field.name}}"
            [step]="field.type === 'number' ? '0.01' : null">}
          @case('select'){<mat-select formControlName="{{field.name}}">
            @for(option of field.options; track option){
            <mat-option [value]="option.value">
              {{ option.value }}
            </mat-option>
            }
          </mat-select>}
          <!-- Add other input types as needed -->
          @default{<input matInput [type]="field.type" formControlName="{{field.name}}">}
          }

          <!-- Validation Errors -->
          @if(attendee.get(field.name)?.hasError('required')){
          <mat-error>
            {{ field.label }} is required
          </mat-error>
          }
          @if(attendee.get(field.name)?.hasError('email')){
          <mat-error>
            Please enter a valid email address
          </mat-error>
          }
          <!-- Add more error messages as needed -->
        </mat-form-field>
        }
        <!-- Remove Entry Button -->
         @if(attendees.length > 1){
        <button mat-button color="warn" type="button" (click)="removeAttendee(i)">Remove
          Attendee</button>
        }
        <!--<mat-divider></mat-divider>-->

        <!-- Add Entry Button -->
        <button mat-button color="primary" type="button" (click)="addAttendee()">Add Another Attendee</button>
      </div>
      }
    </div>

    }@else{

    }
    @for(field of fields; track field){
    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ field.label }}</mat-label>

        <!-- Dynamic Input Types -->
        @if(field.type === 'text' || field.type === 'number' || field.type === 'email'){
        <input matInput [type]="field.type" [formControlName]="field.name"
          [step]="field.type === 'number' ? '0.01' : null" />
        }
        @if(field.type === 'select'){
        <mat-select [formControlName]="field.name">
          @for(option of field.options; track option){
          <mat-option [value]="option.key">
            {{ option.value }}
          </mat-option>
          }
        </mat-select>
        }
        <!-- Add other input types as needed -->
        @if(editForm.get(field.name)?.invalid){
        <mat-error>
          {{ field.label }} is required
        </mat-error>
        }
      </mat-form-field>
    </div>
    }
  </form>
</div>
<div mat-dialog-actions>
  <button class="button" mat-raised-button color="primary" (click)="onSave()">Save</button>
  <button class="button" mat-raised-button color="primary" (click)="onCancel()">Cancel</button>
</div>