import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';
import { catchError, from, map, Observable, of, throwError } from 'rxjs';
import { PurchaseData, Ticket, TicketInput, TicketPrice, TicketPriceInput, TicketWithPrice } from '../../models/ticket/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }
  /*createTicket(eventData: Partial<Event>, userId: string): Observable<string> {
    return from(
      this.supabase
      .rpc('create_event', {
        p_description: eventData.description,
        p_end_date: eventData.end_date,
        p_online: eventData.online,
        p_sport_id: eventData.sport_id,
        p_sport_variation_id: eventData.sport_variation_id,
        p_start_date: eventData.start_date,
        p_title: eventData.title,
        p_venue_id: eventData.venue_id,
        p_created_by: userId
      })
        .then(response => {
          if (response.error) {
            throw response.error;
          }
          if (!response.data) {
            throw new Error('No event ID returned from RPC.');
          }
          return response.data as string; // Assuming the function returns UUID as string
        })
    );
  }*/

  upsertTicket(EventId: string, eventSubEventData: Partial<Ticket>): Observable<Ticket> {
    // Ensure 'id' and 'event_id' are included
    const dataToUpsert = {
      event_id: EventId, // event_id
      ...eventSubEventData // Spread the rest of the data
    };

    return from(this.supabase
      .from('event_sub_events')
      .upsert(dataToUpsert)
      .select()
      .single() // Assuming you want a single object returned
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket;
      }),
      catchError(error => {
        console.error('Error updating sub-event:', error);
        return throwError(() => new Error('Failed to update sub-event.'));
      })
    );
  }

  /*addTicket(eventSubEventData: Partial<Ticket>): Observable<Ticket>{

    return from(this.supabase
      .from('event_sub_events')
      .insert(eventSubEventData)
      .single() // Assuming you want a single object returned
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket;
      }),
      catchError(error => {
        console.error('Error adding sub-event:', error);
        return throwError(() => new Error('Failed to add sub-event.'));
      })
    );
  }*/

  /*getTicket(ticketID: string): Observable<Ticket> {

    return from(this.supabase
      .from('tickets')
      .select('*, ticket_prices(*)')
      .eq('id', ticketID)
      .returns<Ticket>()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket;
      }),
      catchError(error => {
        console.error('Error fetching ticket:', error);
        return throwError(() => new Error('Failed to fetch ticket.'));
      })
    );
  }*/

  getTicketWithPrice(ticketPriceID: string): Observable<TicketWithPrice> {

    return from(this.supabase
      .from('ticket_prices')
      .select('*, ticket_id(*, event_id(*),ticket_fields(*, field_id(*)))')
      .eq('id', ticketPriceID)
      //.is('ticket_id.deleted_at', null)
      //.is('deleted_at', null)//might not want to do this this way
     .order('order', { referencedTable: 'ticket_id.ticket_fields.field_id' }) // Add this line for ordering
      .returns<TicketWithPrice>()
      .single()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        console.log(response.data)
        return response.data as TicketWithPrice;
      }),
      catchError(error => {
        console.error('Error fetching ticket:', error);
        return throwError(() => new Error('Failed to fetch ticket.'));
      })
    );
  }




  getTicketByEventId(eventId: string): Observable<Ticket[]> {
    return from(this.supabase
      .from('tickets')
      .select('*, ticket_prices(*)')
      .eq('event_id', eventId)
      //.is('ticket_prices.deleted_at', null)
      //.is('deleted_at', null)
      .order('title')
      .returns<Ticket[]>()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket[];
      }),
      catchError(error => {
        console.error('Error fetching ticket:', error);
        return throwError(() => new Error('Failed to fetch ticket.'));
      })
    );
  }



  // Updated updateTicket method
  updateTicket(ticketId: string, ticketData: Ticket): Observable<Ticket> {
    const { title } = ticketData;

    return from(
      this.supabase
        .from('tickets')
        .update({ title, updated_at: new Date() })
        .eq('id', ticketId)
        .select('*, ticket_prices(*)')
        .order('ticket_prices.price', { ascending: true })
        .single()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket;
      }),
      catchError(error => {
        console.error('Error updating ticket:', error);
        return throwError(() => new Error('Failed to update ticket.'));
      })
    );
  }
  // Updated createTicket method
  createTicket(eventId: string, ticketData: Partial<Ticket>): Observable<Ticket> {
    const { title } = ticketData;

    return from(
      this.supabase
        .from('tickets')
        .insert([{ event_id: eventId, title }])
        .select()
        .single()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Ticket;
      }),
      catchError(error => {
        console.error('Error creating ticket:', error);
        return throwError(() => new Error('Failed to create ticket.'));
      })
    );
  }


  updateTicketPrice(priceData: TicketPrice): Observable<TicketPrice> {
    const { label, price } = priceData;

    return from(
      this.supabase
        .from('ticket_prices')
        .update({ label, price, updated_at: new Date() })
        .eq('id', priceData.id)
        .select()
        .single()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as TicketPrice;
      }),
      catchError(error => {
        console.error('Error updating ticket price:', error);
        return throwError(() => new Error('Failed to update ticket price.'));
      })
    );
  }


  // Create a new ticket price
  createTicketPrice(ticketId: string, priceData: TicketPriceInput): Observable<TicketPrice> {
    const { label, price } = priceData;

    return from(
      this.supabase
        .from('ticket_prices')
        .insert([{ ticket_id: ticketId, label, price }])
        .select()
        .single()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as TicketPrice;
      }),
      catchError(error => {
        console.error('Error creating ticket price:', error);
        return throwError(() => new Error('Failed to create ticket price.'));
      })
    );
  }

  // Delete an existing ticket price
  deleteTicketPrice(priceId: string): Observable<void> {
    return from(
      this.supabase
        .from('ticket_prices')
        .update({ deleted_at: new Date() })
        .eq('id', priceId)
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return;
      }),
      catchError(error => {
        console.error('Error deleting ticket price:', error);
        return throwError(() => new Error('Failed to delete ticket price.'));
      })
    );
  }

    /* purchaseTicket(purchaseData: PurchaseData): Observable<Ticket[]> {
     return from(this.supabase
       .from('tickets')
       .select('*, ticket_prices(*)')
       .eq('id', purchaseData.ticket_id)
       .returns<Ticket[]>()
     ).pipe(
         map(response => {
           if (response.error) {
             throw response.error;
           }
           return response.data as Ticket[];
         }),
         catchError(error => {
           console.error('Error buying ticket:', error);
           return throwError(() => new Error('Failed to buy ticket.'));
         })
       );
   }*/


}