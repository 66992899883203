import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddEventComponent } from '../add-event/add-event.component';
import { Router } from '@angular/router';
import { EventService } from '../../../services/event/event.service';
import { Event } from '../../../models/event/event.model';
import { SportService } from '../../../services/sport/sport.service';
import { Sport, SportVariation } from '../../../models/sport/sport.model';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';
import { AuthenticationService } from '@mosaicdash/services';

@Component({
  selector: 'app-create-event',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    AddEventComponent,
    MatProgressSpinner 
  ],
  templateUrl: './create-event.component.html',
  styleUrl: './create-event.component.css'
})
export class CreateEventComponent  implements OnInit {

  eventForm: FormGroup;
  isLoading = false;
  isFlatFee: boolean = false;

  sports: Sport[] = [];
  sportVariations: SportVariation[] = [];
  selectedEventName: string = 'Event'; // Default label
  selectedSubEventName: string = 'Sub Event'; // Default sub-event label

   // New properties to track selection status
   isSportSelected: boolean = false;
   isSportVariationSelected: boolean = false;

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private sportService: SportService, // Inject SportsService
    //private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthenticationService, // Inject AuthService
    private readonly URLHandler: URLHandlerService
  ) {
    this.eventForm = this.fb.group({
      sportId: [null, Validators.required], // Add sportId control
      sportVariationId: [null, Validators.required], // Add sportVariationId control
      title: ['', Validators.required],
      description: [''],
      venueId: ['', Validators.required],
      isMultiDay: [false], // Multi-Day Event Toggle
      startDate: ['', Validators.required],
      endDate: [{ value: '', disabled: true }, Validators.required],
      isFlatFee: [false],
      isOnline: [false],
      flatFeeAmount: [{ value: 0, disabled: true }, [Validators.min(0)]],
      events: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loadSports();

    // Listen to changes on the 'isFlatFee' checkbox to enable/disable the 'flatFeeAmount' field
    this.eventForm.get('isFlatFee')?.valueChanges.subscribe(value => {
      this.isFlatFee = value;
      if (value) {
        this.eventForm.get('flatFeeAmount')?.enable();
      } else {
        this.eventForm.get('flatFeeAmount')?.disable();
        this.eventForm.get('flatFeeAmount')?.setValue(0);
      }
    });

    // Listen to changes on the 'sportId' to load variations
    this.eventForm.get('sportId')?.valueChanges.subscribe(sportId => {
      this.loadSportVariations(sportId);
    });

    // Listen to changes on the 'sportVariationId' to update labels
    this.eventForm.get('sportVariationId')?.valueChanges.subscribe(variationId => {
      this.updateLabels(variationId);
    });

      // Listen to changes on the 'isMultiDay' to toggle 'endDate' field
      this.eventForm.get('isMultiDay')?.valueChanges.subscribe(isMultiDay => {
        if (isMultiDay) {
          this.eventForm.get('endDate')?.enable();
          this.eventForm.get('endDate')?.setValidators([Validators.required]);
        } else {
          this.eventForm.get('endDate')?.disable();
          this.eventForm.get('endDate')?.clearValidators();
          this.eventForm.get('endDate')?.setValue('');
        }
        this.eventForm.get('endDate')?.updateValueAndValidity();
      });
  }

  

  // Fetch all sports
  loadSports(): void {
    this.sportService.getSports().subscribe({
      next: (sports) => {
        this.sports = sports;
      },
      error: (error) => {
        this.snackBar.open(`Error loading sports: ${error.message}`, 'Close', { duration: 5000 });
      }
    });
  }

  // Fetch variations based on selected sport and update labels
  loadSportVariations(sportId: number): void {
    if (sportId) {
      this.isLoading = true;
      this.sportService.getSportVariations(sportId).subscribe({
        next: (variations) => {
          this.sportVariations = variations;
          // Reset sportVariationId when sport changes
          this.eventForm.get('sportVariationId')?.setValue(null);
          // Update selection status
          this.isSportSelected = true;
          this.isSportVariationSelected = false;
          // Reset labels to defaults until a variation is selected
          this.selectedEventName = 'Event';
          this.selectedSubEventName = 'Sub Event';
          this.isLoading = false;
        },
        error: (error) => {
          this.snackBar.open(`Error loading sport variations: ${error.message}`, 'Close', { duration: 5000 });
          // Fallback to default labels
          this.selectedEventName = 'Event';
          this.selectedSubEventName = 'Sub Event';
          this.isSportSelected = false;
          this.isSportVariationSelected = false;
          this.isLoading = false;
        }
      });
    } else {
      this.sportVariations = [];
      this.eventForm.get('sportVariationId')?.setValue(null);
      // Reset labels to default
      this.selectedEventName = 'Event';
      this.selectedSubEventName = 'Sub Event';
      this.isSportSelected = false;
      this.isSportVariationSelected = false;
      this.isLoading = false;
    }
  }

   // Update labels based on selected sport
   updateLabels(variationId: number): void {
    if (variationId) {
      const selectedVariation = this.sportVariations.find(variation => variation.id === variationId);
      if (selectedVariation) {
        this.selectedEventName = selectedVariation.event_name || 'Event';
        this.selectedSubEventName = selectedVariation.sub_event_name || 'Sub Event';
        this.isSportVariationSelected = true;
      } else {
        // Fallback to default labels if variation not found
        this.selectedEventName = 'Event';
        this.selectedSubEventName = 'Sub Event';
        this.isSportVariationSelected = false;
      }
    } else {
      // Reset labels if no variation is selected
      this.selectedEventName = 'Event';
      this.selectedSubEventName = 'Sub Event';
      this.isSportVariationSelected = false;
    }
  }
  // Add getter for events FormArray
  get events(): FormArray {
    return this.eventForm.get('events') as FormArray;
  }

   // Method to create a new event FormGroup
   createEvent(): FormGroup {
    return this.fb.group({
      eventName: ['', Validators.required],
      eventSponsor: [''],
      eventType: ['', Validators.required], // relay, field, individual
      eligibleAges: ['', Validators.required],
      price: [0, [Validators.required, Validators.min(0)]]
    });
  }

  // Method to add a new event to the events FormArray
  addEvent() {
    this.events.push(this.createEvent());
  }

  // Method to remove an event from the events FormArray
  removeEvent(index: number) {
    this.events.removeAt(index);
  }

  async onSubmit() {
    if (this.eventForm.invalid) {
      this.snackBar.open('Please fill in all required fields.', 'Close', { duration: 3000 });
      return;
    }
  
    this.isLoading = true;

    const currentUser =  await this.authService.getCurrentUser(); // Implement this method to get the logged-in user
    if (!currentUser) {
      this.snackBar.open('User not authenticated.', 'Close', { duration: 3000 });
      this.isLoading = false;
      return;
    }

  
    const eventData: Partial<Event> = {
      title: this.eventForm.value.title,
      description: this.eventForm.value.description,
      start_date: new Date( this.eventForm.value.startdate),
      sport_id: this.eventForm.value.sportId,
      sport_variation_id: this.eventForm.value.sportVariationId,
      end_date: this.eventForm.value.isMultiDay ? this.eventForm.value.endDate : null,
      online: this.eventForm.value.isOnline,
      venue_id: !this.eventForm.value.isOnline ? this.eventForm.value.venueId : null,
      status: '',
    };
    
 /* const currentUser = this.authService.getCurrentUser(); // Implement this method to get the logged-in user
    if (!currentUser) {
      this.snackBar.open('User not authenticated.', 'Close', { duration: 3000 });
      this.isLoading = false;
      return;
    }*/
    this.eventService.createEvent(eventData, currentUser.id).subscribe({
      next: async (event) => {
        this.isLoading = false;
        this.snackBar.open('Event created successfully!', 'Close', { duration: 3000 });
        // Navigate to MeetDetailComponent with the newly created meet_id
        console.log(event)
        const eventId64 = await this.URLHandler.hexToBase64(event);
        console.log(eventId64)
        this.router.navigate(['/event', eventId64]);
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(`Error: ${error.error.message || 'Failed to create event.'}`, 'Close', { duration: 5000 });
      }
    });
  }

}