import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../../services/cart/cart.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [CommonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  templateUrl: './success.component.html',
  styleUrl: './success.component.css',
})
export class SuccessComponent implements OnInit {
  sessionId: string | null = null;
  errorMessage: string | null = null;
  isLoading: boolean = false; //switch to use

  constructor(
    private cartService: CartService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.cartService.handlePaymentSuccess();
    // Subscribe to query parameters to get the session_id
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['session_id'] || null;
      if (this.sessionId) {
        this.fetchCheckoutSession(this.sessionId);
      } else {
        this.errorMessage = 'No session ID provided.';
        this.isLoading = false;
      }
    });
  }

  fetchCheckoutSession(sessionId: string): void {
    
  }
}