import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleSelectionDialogComponent } from '../role-selection-dialog/role-selection-dialog.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AthleteService } from '../../../services/athlete/athlete.service';
import { Music } from '../../../models/new-athlete/new-athlete.model';

@Component({
  selector: 'app-music-selection-dialog',
  standalone: true,
  imports: [CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressSpinnerModule],
  templateUrl: './music-selection-dialog.component.html',
  styleUrl: './music-selection-dialog.component.css'
})
export class MusicSelectionDialogComponent implements OnInit {

  songsForm: FormGroup;
 
  isLoading = false
  // New properties to track selection status


  athleteMusic: Music[] = [];  // Add property to hold user's teams


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RoleSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private athleteService: AthleteService,
  ) {
    // Initialize forms
    this.songsForm = this.fb.group({
      songName: [null, Validators.required],
      artistName: [null, Validators.required],
    });
  }

  ngOnInit(): void {
   this.getMusic()
  }
  getMusic(): void {
   this.athleteService.getMusic().subscribe({
    next: (athleteMusic) => {
      console.log(athleteMusic)
      this.athleteMusic = athleteMusic;
    },
    error: (error) => {
      this.snackBar.open(`Error loading teams: ${error.message}`, 'Close', { duration: 5000 });
    }
  });
}


  submitForm(): void {
    console.log('yes')
    if (this.songsForm.valid) {
      const formData = this.songsForm.value;

      this.isLoading = true; // Show loading spinner
      this.athleteService.saveMusic(formData.songName, formData.artistName)
        .subscribe({
          next: (response) => {
            this.isLoading = false; // Hide loading spinner
            this.snackBar.open('Successfully added music!', 'Close', { duration: 5000 });
            this.dialogRef.close(true); // Close dialog with success
          },
          error: (error) => {
            this.isLoading = false; // Hide loading spinner
            this.snackBar.open(`Failed to add music: ${error.message}`, 'Close', { duration: 5000 });
          }
        });
    } else {
      this.snackBar.open('Please fill in all required fields.', 'Close', { duration: 5000 });
    }
  }
  cancel() {
    this.dialogRef.close(null);
  }
}
