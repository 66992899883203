import { Component, Input, inject, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { AthleteCardComponent } from '../athlete-card/athlete-card.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { AuthSession } from '@supabase/supabase-js';
import { AppComponent } from '../../../app.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';
import { MatBadgeModule } from '@angular/material/badge';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AuthenticationService } from '@mosaicdash/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleSelectionDialogComponent } from '../role-selection-dialog/role-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user/user.service';
import { TeamSelectionDialogComponent } from '../team-selection-dialog/team-selection-dialog.component';
import { AthleteService } from '../../../services/athlete/athlete.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { Athlete } from '../../../models/new-athlete/new-athlete.model';
import { RoleWithDetails, UserRole } from '@mosaicdash/models';
import { MatTabsModule } from '@angular/material/tabs';
import { OfficialService } from '../../../services/official/official.service';
import { Official } from '../../../models/official/official.model';
import { OfficialCardComponent } from '../official-card/official-card.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterOutlet,
    FooterComponent,
    AthleteCardComponent,
    //ProgressBarComponent,
    CommonModule,
    //AppComponent,
    ClipboardModule,
    MatBadgeModule,
    NgcCookieConsentModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatTabsModule,
    OfficialCardComponent
  ],
})
export class NavigationComponent implements OnInit {
  hidden = false;
  copied = false;
  userId = '';
  workerID = '';
  officialID = '';
  athletes: Athlete[] = [];
  officials: Official[] = [];
  loading = false;
  testV = 'bad';
  userIDBase64 = '';
  userLink = '';
  athleteLink = '';
  athleteURL = '';
  @Input()
  session!: AuthSession;
  athlete!: boolean;
  coach!: boolean;
  official!: boolean;
  selectedAthlete!: Athlete;
  selectedOfficial!: Official;
  roles!: UserRole[];
  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 1; // Total number of tabs  Dynamically change this based on roles and permissions

  private breakpointObserver = inject(BreakpointObserver);

  private excludedRoutes: string[] = [
    '/login',
    '/signup',
    '/update-password',
    '/terms',
    '/ada',
    '/cart',
    '/checkout',
    // Add other routes as needed
  ];

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly URLHandler: URLHandlerService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private readonly athleteService: AthleteService,
    private readonly OfficialService: OfficialService,
    private router: Router
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  async ngOnInit(): Promise<void> {
    this.loading = true;
    //this.supabase.authChanges((_, session) => (this.session = session))
    // await this.getSession()
    if (this.session) {
      await this.getUserID();
      await this.getAthletes();
      await this.getOfficials();
      this.selectedAthlete = this.athletes[0]!;
      this.selectedOfficial = this.officials[0]!;
      await this.handleUserRoles();
    }

    await this.URLHandler.hexToBase64(this.userId).then((res) => {
      this.userIDBase64 = res;
    });

    await this.URLHandler.base64ToAthleteLink(this.userIDBase64).then((res) => {
      this.userLink = res;
    });

    if (this.selectedAthlete) {
      await this.URLHandler.base64ToAthleteLink(
        await this.URLHandler.hexToBase64(this.selectedAthlete.id!)
      ).then((res) => {
        this.athleteLink = res;
      });

      await this.URLHandler.linkToURL(this.athleteLink).then((res) => {
        this.athleteURL = res;
      });
    }
    this.loading = false;
  }

  async onCopy() {
    this._snackBar.open('Profile Link Copied!', 'Dismiss');
  }

  async getAthletes() {
    try {
      // Await the promise returned by getAthleteUser
      const athletes = await this.athleteService.getAthleteUser(this.userId);

      if (athletes && athletes.length > 0) {
        this.athletes = athletes;
      } else {
        // Handle the case where no athlete IDs are found
        this.testV = 'No athlete IDs found.';
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(`Error fetching athlete IDs: ${error.message}`);
      } else {
        alert('An unexpected error occurred.');
      }
    } finally {
    }
  }

  async getOfficials() {
    try {
      // Await the promise returned by getAthleteUser
      const officials = await this.OfficialService.getOfficialUser(this.userId);

      if (officials && officials.length > 0) {
        this.officials = officials;
      } else {
        // Handle the case where no athlete IDs are found
        this.testV = 'No official IDs found.';
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(`Error fetching official IDs: ${error.message}`);
      } else {
        alert('An unexpected error occurred.');
      }
    } finally {
    }
  }

  async getUserID() {
    try {
      this.testV = 'good';
      const userID = this.session.user.id; //switch to public id maybe add qr code
      if (userID) {
        this.testV = 'vgood';
        this.userId = userID;
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    } finally {
    }
  }

  async signOut() {
    await this.authenticationService.signOut();
  }

  // Handle User Roles
  async handleUserRoles(): Promise<void> {
    try {
      this.roles = await lastValueFrom(this.authService.getUserRole());

      if (this.roles.length === 0) {
        console.warn('No roles found for the user.');
        // Prompt the user to select a role if necessary
        await this.promptRoleSelection();
        return;
      }

      // Extract role names and convert them to lowercase for consistency
      const roleNames = this.roles.map((userRole) =>
        userRole.roles.name.toLowerCase()
      );

      // Define a mapping between role names and their handler functions
      const roleHandlers: { [key: string]: () => Promise<void> } = {
        admin: this.handleAdminRole.bind(this),
        coach: this.handleCoachRole.bind(this),
        athlete: this.handleAthleteRole.bind(this),
        official: this.handleOfficialRole.bind(this),
        // Add more roles and their handlers as needed
      };

      // Use a Set to keep track of executed roles to avoid duplicates
      const executedRoles = new Set<string>();

      // Iterate over each role and execute the corresponding handler
      for (const roleName of roleNames) {
        if (roleHandlers[roleName] && !executedRoles.has(roleName)) {
          await roleHandlers[roleName]();
          executedRoles.add(roleName);
        } else if (!roleHandlers[roleName]) {
          console.warn(`No handler defined for role: ${roleName}`);
        }
      }
    } catch (error) {
      console.error('Error handling user roles:', error);
      // Optionally, handle errors (e.g., show a notification)
    }
  }

  // Prompt Role Selection Dialog
  private async promptRoleSelection(): Promise<void> {
    const currentUrl = this.router.url;
    console.log('Current URL:', currentUrl); // For debugging

    // Check if the current URL is in the excluded routes
    if (this.isExcludedRoute(currentUrl)) {
      console.log('Current route is excluded from showing the popup.');
      return; // Do not show the popup
    }
    const lastPopupTimeString = localStorage.getItem('lastPopupShownTime');
    const now = new Date();
    let showPopup = false;

    if (!lastPopupTimeString) {
      // Popup has never been shown before
      showPopup = true;
    } else {
      const lastPopupTime = new Date(lastPopupTimeString);
      const diffInMs = now.getTime() - lastPopupTime.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays >= 1) {
        // More than a day has passed
        showPopup = true;
      }
    }

    if (showPopup) {
      await this.openRoleSelectionDialog();
      // Update the lastPopupShownTime
      localStorage.setItem('lastPopupShownTime', now.toISOString());
    }
  }

  // Handle Admin Role
  private async handleAdminRole(): Promise<void> {
    // Implement admin-specific logic
    console.log('User is an Admin.');
    // Example: Load admin dashboard
  }

  // Handle Coach Role
  private async handleCoachRole(): Promise<void> {
    // Implement coach-specific logic
    this.coach = true;
    console.log('User is a Coach.');
    this.coach = true;
    this.tabCount++;
    // Example: Load coach dashboard
  }

  // Handle Athlete Role
  private async handleAthleteRole(): Promise<void> {
    // Implement athlete-specific logic
    console.log('User is an Athlete.');
    this.athlete = true;
    this.tabCount++;
    // Example: Load athlete dashboard
  }

  // Handle Athlete Role
  private async handleOfficialRole(): Promise<void> {
    // Implement athlete-specific logic
    console.log('User is an Official.');
    this.official = true;
    this.tabCount++;
    // Example: Load athlete dashboard
  }

  openRoleSelectionDialog(): void {
    const dialogRef = this.dialog.open(RoleSelectionDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      disableClose: true, // Prevent closing the dialog by clicking outside
      data: { existingRoles: this.roles } // Pass any initial data if needed
    });

    dialogRef.afterClosed().subscribe((result: any | null) => {
      console.log('Dialog result:', result); // Debugging
      if (result) {
        // Check if "Official" role was added
        const officialRoleAdded = result.roles.some((role: { roleName: string; }) => role.roleName === 'Official');
  
        if (officialRoleAdded) {
          console.log('official')
          // Navigate to Stripe onboarding page
          this.router.navigate(['/connect']); // Adjust the route as necessary
        } else {
          // Handle other cases or show a success message
          console.log('Roles updated without adding Official.');
        }
      } else {
        // Dialog was closed without saving (e.g., canceled)
        console.log('Role selection dialog was closed without changes.');
      }
    });
  }
  onSelectAthlete(athlete: Athlete): void {
    this.selectedAthlete = athlete;
  }

  openTeamSelectionDialog(): void {
    const dialogRef = this.dialog.open(TeamSelectionDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      disableClose: false, // Prevent closing the dialog by clicking outside
      data: {}, // Pass any initial data if needed
    });

    dialogRef.afterClosed().subscribe();
  }

  // Swipe event handlers
  onSwipeLeft() {
    if (this.selectedIndex < this.tabCount - 1) {
      this.selectedIndex++;
    }
  }

  onSwipeRight() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

    // Helper method to check if the route is excluded
    private isExcludedRoute(url: string): boolean {
      // Exact match
      if (this.excludedRoutes.includes(url)) {
        return true;
      }
  
      // If you have dynamic segments or query params, you might need more sophisticated checks
      // For example, using startsWith or regex
      return this.excludedRoutes.some(route => url.startsWith(route));
    }
}
