import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogConfig, FormField } from '../../../models/edit-dialog/edit-dialog.model';
import { MatOptionModule } from '@angular/material/core';
import { AttendeeInfo } from '../../../models/cart/cart.model';


@Component({
  selector: 'app-generic-edit-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule
  ],
  templateUrl: './generic-edit-dialog.component.html',
  styleUrl: './generic-edit-dialog.component.css'
})
export class GenericEditDialogComponent implements OnInit {
  editForm: FormGroup;
  dialogTitle: string;
  fields: FormField[];
  isPriceDialog: boolean;
  isBatchEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<GenericEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: DialogConfig,
    private fb: FormBuilder
  ) {
    this.dialogTitle = config.title;
    this.fields = config.fields;
    this.isPriceDialog = config.isPriceDialog || false;
    this.isBatchEdit = config.isBatchEdit || false;

    if (this.isBatchEdit) {
      this.editForm = this.fb.group({
        entries: this.fb.array([])
      });
      this.initializeBatchEdit(config.data);
    } else {
      const formGroupConfig: any = {};
      this.fields.forEach(field => {
        let fieldValue = config.data ? config.data[field.name] : '';
        if (this.isPriceDialog && field.name === 'price' && fieldValue) {
          // Convert cents to dollars for display
          fieldValue = (fieldValue / 100).toFixed(2);
        }
        formGroupConfig[field.name] = [
          fieldValue,
          field.validators || []
        ];
      });
      this.editForm = this.fb.group(formGroupConfig);
    }
  }

  ngOnInit(): void { }

  private initializeBatchEdit(data: any): void {
    if (data && Array.isArray(data.attendees)) {
      const entriesArray = this.editForm.get('entries') as FormArray;
      data.attendees.forEach((attendee: AttendeeInfo) => {
        const attendeeGroup = this.fb.group({});
        this.fields.forEach(field => {
          let fieldValue = attendee ? attendee[field.name] : '';
          if (this.isPriceDialog && field.name === 'price' && fieldValue) {
            // Convert cents to dollars for display
            fieldValue = (fieldValue / 100).toFixed(2);
          }

          // Prepare validators array
          const validators = [];
          if (field.required) validators.push(Validators.required);
          if (field.type === 'email') validators.push(Validators.email);
          // Add other validators based on field.type if necessary

          // Add the FormControl to the attendeeGroup
          attendeeGroup.addControl(
            field.name,
            this.fb.control(fieldValue, validators)
          );
        });
        entriesArray.push(attendeeGroup);
      });
    }
  }

  // Getter for attendees FormArray
  get attendees(): FormArray {
    return this.editForm.get('attendees') as FormArray;
  }
  
  // Add a new attendee in batch edit
  addAttendee(): void {
    const attendeeGroup = this.fb.group({});
    this.fields.forEach(field => {
      // Prepare validators array
      const validators = [];
      if (field.required) validators.push(Validators.required);
      if (field.type === 'email') validators.push(Validators.email);
      // Add other validators based on field.type if necessary

      // Add the FormControl to the attendeeGroup
      attendeeGroup.addControl(
        field.name,
        this.fb.control('', validators)
      );
    });
    this.attendees.push(attendeeGroup);
  }

  // Remove an attendee from batch edit
  removeAttendee(index: number): void {
    this.attendees.removeAt(index);
  }

  onSave(): void {
    if (this.editForm.valid) {
      const formValue = this.editForm.value;
      if (this.isPriceDialog && this.isBatchEdit) {
        // Convert price to cents for all entries
        formValue.entries.forEach((entry: any) => {
          if (entry.price) {
            entry.price = Math.round(parseFloat(entry.price) * 100);
          }
        });
      } else if (this.isPriceDialog) {
        // Convert price to cents for single entry
        if (formValue.price) {
          formValue.price = Math.round(parseFloat(formValue.price) * 100);
        }
      }
      this.dialogRef.close(formValue);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}