<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>404 - Not Found</title>
</head>
<main>


    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>404 - Not Found</mat-card-title>
        </mat-card-header>
        <div class="image-size">
            <img src="../../../../assets/images/stickFigure.svg" alt="Stick Figure" color="#000000">
        </div>
        <mat-card-content>
            <p>You're running in the wrong direction!</p>
        </mat-card-content>
    </mat-card>
</main>


</html>