import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, throwError } from 'rxjs';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';
import { Event, EventSubEvent} from '../../models/event/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }
  createEvent(eventData: Partial<Event>, userId: string): Observable<string> {
    return from(
      this.supabase
      .rpc('create_event', {
        p_description: eventData.description,
        p_end_date: eventData.end_date,
        p_online: eventData.online,
        p_sport_id: eventData.sport_id,
        p_sport_variation_id: eventData.sport_variation_id,
        p_start_date: eventData.start_date,
        p_title: eventData.title,
        p_venue_id: eventData.venue_id,
        p_created_by: userId
      })
        .then(response => {
          if (response.error) {
            throw response.error;
          }
          if (!response.data) {
            throw new Error('No event ID returned from RPC.');
          }
          return response.data as string; // Assuming the function returns UUID as string
        })
    );
  }

  upsertEventSubEvent(EventId: string, eventSubEventData: Partial<EventSubEvent>): Observable<EventSubEvent>{
    // Ensure 'id' and 'event_id' are included
    const dataToUpsert = {
      event_id: EventId, // event_id
      ...eventSubEventData // Spread the rest of the data
    };
  
    return from(this.supabase
      .from('event_sub_events')
      .upsert(dataToUpsert)
      .select()
      .single() // Assuming you want a single object returned
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as EventSubEvent;
      }),
      catchError(error => {
        console.error('Error updating sub-event:', error);
        return throwError(() => new Error('Failed to update sub-event.'));
      })
    );
  }

  upDateEvent( eventData: Partial<Event>): Observable<Event>{
    // Ensure 'id' and 'event_id' are included
    const dataToUpdate = {
      ...eventData // Spread the rest of the data
    };
  
    return from(this.supabase
      .from('events')
      .update(dataToUpdate)
      .eq('id', eventData.id)
      .select()
      .single() // Assuming you want a single object returned
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as Event;
      }),
      catchError(error => {
        console.error('Error updating event:', error);
        return throwError(() => new Error('Failed to update event.'));
      })
    );
  }

  addEventSubEvent(eventSubEventData: Partial<EventSubEvent>): Observable<EventSubEvent>{

    return from(this.supabase
      .from('event_sub_events')
      .insert(eventSubEventData)
      .single() // Assuming you want a single object returned
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as EventSubEvent;
      }),
      catchError(error => {
        console.error('Error adding sub-event:', error);
        return throwError(() => new Error('Failed to add sub-event.'));
      })
    );
  }

  getSubEvents(eventId: string): Observable<EventSubEvent[]>{
    return from(this.supabase
      .from('event_sub_events')
      .select('*')
      .eq('event_id', eventId)
      .order('created_at', { ascending: false }) // Optional: order by creation date
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as EventSubEvent[];
      }),
      catchError(error => {
        console.error('Error fetching sub-events:', error);
        return throwError(() => new Error('Failed to fetch sub-events.'));
      })
    );
  }

  
  getEventById(eventId: string): Observable<Event> {
    return from(this.supabase
      .from('events')
      .select('*, sports(*),sport_variations(*)')
      .eq('id', eventId)
      .returns<Event>()
      .single()
    ).pipe(
        map(response => {
          if (response.error) {
            throw response.error;
          }
          return response.data as Event;
        }),
        catchError(error => {
          console.error('Error adding sub-event:', error);
          return throwError(() => new Error('Failed to add sub-event.'));
        })
      );
  }

  getAllEvents(): Observable<Event[]> {
    return from(this.supabase
      .from('events')
      .select('*, sports(*),sport_variations(*)')
      .is('is_public', true)
      .is('deleted_at', null)
      .returns<Event[]>()
    ).pipe(
        map(response => {
          if (response.error) {
            throw response.error;
          }
          return response.data as Event[];
        }),
        catchError(error => {
          console.error('Error fetching events:', error);
          return throwError(() => new Error('Failed to fetch events.'));
        })
      );
  }
/*
  getAllEvents(): Observable<Meet[]> {
    return this.http.get<Meet[]>(this.apiUrl);
  }

  

  updateEvent(meetId: string, meet: MeetDTO): Observable<Meet> {
    return this.http.put<Meet>(`${this.apiUrl}/${meetId}`, meet);
  }

  deleteEvent(meetId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${meetId}`);
  }*/
}