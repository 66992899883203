import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AthleteService } from '../../services/athlete/athlete.service';
import { AuthenticationService } from '@mosaicdash/services';
import { URLHandlerService } from '../../services/urlhandler/urlhandler.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { RegistrationCardComponent } from '../../shared/components/registration-card/registration-card.component';
import { TicketingCardComponent } from '../../shared/components/ticketing-card/ticketing-card.component';
import { EventSubEvent, Event } from '../../models/event/event.model';
import { EventService } from '../../services/event/event.service'
import { SubEventCardComponent } from "../../shared/components/sub-event-card/sub-event-card.component";
import { MatTabsModule } from '@angular/material/tabs';
import { EventCardComponent } from '../../shared/components/event-card/event-card.component';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Ticket } from '../../models/ticket/ticket.model';
import { TicketService } from '../../services/ticket/ticket.service';
import { Job, UserRole } from '../../../../../../libs/models/src/lib/models/user/user.model';
import { OfficialCardComponent } from '../../shared/components/official-card/official-card.component';
import { JobCardComponent } from '../../shared/components/job-card/job-card.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    DatePipe,
    RegistrationCardComponent,
    TicketingCardComponent,
    SubEventCardComponent,
    OfficialCardComponent,
    JobCardComponent,
    MatTabsModule,
    EventCardComponent,
    MatProgressSpinner,
    JsonPipe,
    AsyncPipe,
    RouterLink
  ],
  templateUrl: './event.component.html',
  styleUrl: './event.component.css'
})
export class EventComponent implements OnInit {
  base64Id: string = '';
  test = false;
  data$!: Observable<{ event: Event; eventSubEvents: EventSubEvent[]; tickets: Ticket[]; userRoles: UserRole[] }>;
  //eventSubEvents: EventSubEvent[] = [];
  //ticket$!: Observable<Ticket>;
  isEditor: boolean = false;
  isEditMode: boolean = false;
  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 0; // Total number of tabs  Dynamically change this based on roles and permissions 
  eventSubEvents: EventSubEvent[] = []; // Initialize as empty array
  tickets: Ticket[] = []; // Initialize tickets if needed
  userRoles: UserRole[] = [];
  jobs: Job[] = [];//might want to rename jobs


  // Permission flags
  canEditTickets: boolean = false;
  canEditJobs: boolean = false;
  canEditSubEvents: boolean = false;

  canViewTickets: boolean = true;
  canViewJobs: boolean = false;
  canViewSubEvents: boolean = false;
  canViewFinancials: boolean = false;
  canViewEvent: boolean = false;


  eventId = "";

  constructor(
    readonly authenticationService: AuthenticationService,
    private readonly eventService: EventService,
    private readonly athleteService: AthleteService,
    private readonly ticketService: TicketService,
    private route: ActivatedRoute,
    private readonly URLHandler: URLHandlerService
  ) { }

  ngOnInit(): void {
    this.data$ = this.route.params.pipe(
      map(params => {
        const base64Id = params['id'];  // Store the original base64 ID
        this.base64Id = base64Id;  // Save it in the component to use in the template
        return base64Id;
      }),
      switchMap(id => this.URLHandler.base64ToHex(id)),
      switchMap(hexId => {
        this.eventId = hexId;
        return forkJoin({
          event: this.eventService.getEventById(hexId),
          eventSubEvents: this.eventService.getSubEvents(hexId),
          tickets: this.ticketService.getTicketByEventId(hexId),
          userRoles: this.authenticationService.getUserRole()
        });
      })
    );
    // Subscribe to data$ to assign subEvents and tickets
    this.data$.subscribe({
      next: ({ userRoles, eventSubEvents, tickets }) => {
        // Assign fetched data to component properties
        // If you need to use event separately
        //this.event$ = of(event); // You might need to import 'of' from 'rxjs'

        this.eventSubEvents = eventSubEvents;
        this.tickets = tickets;
        console.log('tickets',this.tickets)
        this.userRoles = userRoles;
        this.setPermissions();
      },
      error: (err) => {
        console.error('Error fetching event data', err);
      }
    });
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  handleSubEventSaved(eventSubEvent: EventSubEvent): void {
    if (this.eventSubEvents.some(se => se.id === eventSubEvent.id)) {
      // Update existing sub-event in the list
      const index = this.eventSubEvents.findIndex(se => se.id === eventSubEvent.id);
      if (index !== -1) {
        this.eventSubEvents[index] = eventSubEvent;
      }
    } else {
      // Add new sub-event to the list
      this.eventSubEvents.push(eventSubEvent);
    }
  }

  handleSubEventCancelled(): void {
    // Handle cancellation if needed
  }

  setPermissions(): void {
    //add search for user_events
    this.canEditTickets = this.hasRole(['admin']);
    this.canEditJobs = this.hasRole(['admin']);
    this.canEditSubEvents = this.hasRole(['admin']);
    // Adjust roles and permissions as needed
    //this.canViewTickets = this.canEditTickets || this.tickets.length > 1 || this.hasRole(['admin']);
    this.canViewJobs = this.hasRole(['admin']);
    this.canViewSubEvents = this.hasRole(['admin']);
    this.canViewEvent = this.hasRole(['admin']);
    this.canViewFinancials = this.hasRole(['admin']);

    this.numberOfTabs();
  }

  hasRole(requiredRoles: String[]): boolean {
    return this.userRoles.some(userRole => requiredRoles.includes(userRole.roles.name));
  }

  numberOfTabs() {

    // Manually increment the count for each true variable
    if (this.canViewSubEvents) this.tabCount++;
    if (this.canViewTickets) this.tabCount++;
    if (this.canViewJobs) this.tabCount++;
    if (this.canViewEvent) this.tabCount++;
    if (this.canViewFinancials) this.tabCount++;
    console.log(this.tabCount)
    if (this.tabCount % 2 === 0) {
      this.selectedIndex = (this.tabCount) / 2 - 1
    }
    else {
      this.selectedIndex = (this.tabCount - 1) / 2
    }
    console.log(this.selectedIndex)
  }

  // Swipe event handlers
  onSwipeLeft() {
    if (this.selectedIndex < this.tabCount - 1) {
      this.selectedIndex++;
    }
  }

  onSwipeRight() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

}

// Initialize eventSubEvents$
/*this.eventSubEvents$ = this.route.params.pipe(
  map(params => params['id']),
  switchMap(id => this.URLHandler.base64ToHex(id)),
  switchMap(hexId => this.eventService.getSubEvents(hexId))
);
 
// Subscribe to eventSubEvents$ to populate eventSubEvents
this.eventSubEvents$.subscribe({
  next: (data) => {
    this.eventSubEvents = data;
  },
  error: (error) => {
    console.error('Error fetching sub-events:', error);
  }
});*/
/*
  
  async ngOnInit(): Promise<void> {
   
    this.route.params.subscribe(params => {
      this.eventId = params['id']; // Access the 'id' parameter from the URL
    });
    await this.URLHandler.base64ToHex(this.eventId).then(res => {
      this.eventId = res;
    })
    console.log(this.eventId)
    await this.loadEvent(this.eventId)
    await this.loadSubEvents(this.eventId);
  }

 

  async loadEvent(eventId: string): Promise<void> {
     this.eventService.getEventById(eventId).subscribe(
      (data) => { console.log(data)
        this.event = data;
        console.log(this.event)
        console.log("test",this.event.sports?.name)
      },
      (error) => {
        console.error('Error fetching sub-events:', error);
      }
    );
   
  }

 async loadSubEvents(eventId: string): Promise<void> {
    this.eventService.getSubEvents(eventId).subscribe(
      (data) => {
        this.eventSubEvents = data;
      },
      (error) => {
        console.error('Error fetching sub-events:', error);
      }
    );
  }
*/

