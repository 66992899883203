import { Component } from '@angular/core';
import { StripeOnboardingComponent } from '../../shared/components/stripe-onboarding/stripe-onboarding.component';

@Component({
  selector: 'app-connect',
  standalone: true,
  imports: [StripeOnboardingComponent],
  templateUrl: './connect.component.html',
  styleUrl: './connect.component.css'
})
export class ConnectComponent {

}
