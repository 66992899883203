import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@mosaicdash/services';
import { EventService } from '../../../services/event/event.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CardTemplateComponent } from '../card-template/card-template.component';
import { EventSubEvent, Event } from '../../../models/event/event.model';

@Component({
  selector: 'app-job-card',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    CardTemplateComponent,
  ],
  templateUrl: './job-card.component.html',
  styleUrl: './job-card.component.css'
})
export class JobCardComponent{
  @Input() eventSubEvent?: EventSubEvent; // Undefined when creating a new sub-event
  @Input() event!: Event 
  @Output() eventSubEventSaved = new EventEmitter<EventSubEvent>();
  @Output() eventSubEventCancelled = new EventEmitter<void>();
  editable = true // add input
  
  isEditing: boolean = false;
  eventSubEventForm: FormGroup;
  isRegistered: boolean = false;

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    readonly authenticationService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {
    this.eventSubEventForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
      // Add other necessary fields
    });
  }

  ngOnInit(): void {
    if (this.eventSubEvent) {
      this.eventSubEventForm.patchValue(this.eventSubEvent);
      // Optionally, check if the current user is registered
      //this.checkRegistration();
    }
  }

  toggleEditMode(): void {
    this.isEditing = !this.isEditing;
    if (!this.eventSubEvent) {
      this.eventSubEventForm.reset();
    }
  }
  saveEventSubEvent(): void {
    if (this.eventSubEventForm.invalid) {
      this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
      return;
    }

    const subEventData: Partial<EventSubEvent> = this.eventSubEventForm.value;
    subEventData.id=this.eventSubEvent?.id
    console.log(subEventData)
      this.eventService.upsertEventSubEvent(this.event.id!, subEventData).subscribe({
        next: (updatedEventSubEvent) => {
          this.snackBar.open('Sub-event updated successfully!', 'Close', { duration: 3000 });
          this.isEditing = false;
          // Optionally, emit an event or refresh data
          console.log("test:",updatedEventSubEvent)
          this.eventSubEventSaved.emit(updatedEventSubEvent); // Emit the event with the updated sub-event
        },
        error: (error) => {
          console.error('Error updating sub-event:', error);
          this.snackBar.open('Failed to update sub-event.', 'Close', { duration: 3000 });
        }
      });
   
  }


  cancelEdit(): void {
    this.isEditing = false;
    this.eventSubEventCancelled.emit();
  }
}