import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart/cart.service';
import { CartItem } from '../../models/cart/cart.model';
import { FormGroup, FormBuilder, FormArray, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { AuthenticationService } from '@mosaicdash/services';
import { StripeService } from '../../services/stripe/stripe.service';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatCardModule
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent implements OnInit {
  cartItems: CartItem[] = [];
  attendeeForm: FormGroup;
  totalPriceCents: number = 0;
 /* cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };*/
  loading: boolean = false;

  constructor(
    private cartService: CartService,
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    //private stripeService: StripeService,
    //private paymentService: PaymentService,
    private authService: AuthenticationService,
  ) {
    this.attendeeForm = this.fb.group({
      attendeesByCartItem: this.fb.array([]),
      name: ['', Validators.required], // Cardholder name
      email: ['', [Validators.required, Validators.email]], // Payer email
      // Additional billing fields if needed
    });
  }

  ngOnInit(): void {
    this.cartService.getCartItems().subscribe(items => {
      this.cartItems = items;
      this.initializeAttendeeForms();
      this.calculateTotalPrice();
    });

    // Recalculate total price whenever the form changes
    this.attendeeForm.valueChanges.subscribe(() => {
      this.calculateTotalPrice();
    });
  }

  // Getter for attendeesByCartItem FormArray
  get attendeesByCartItemArray(): FormArray {
    return this.attendeeForm.get('attendeesByCartItem') as FormArray;
  }

  initializeAttendeeForms(): void {
    const attendeesByCartItem = this.attendeesByCartItemArray;
    this.cartItems.forEach(item => {
      attendeesByCartItem.push(this.createCartItemGroup(item));
    });
  }

  createCartItemGroup(item: CartItem): FormGroup {
    return this.fb.group({
      cartItem: [item], // Store the cart item data
      attendees: this.fb.array(this.createAttendeeGroups(item))
    });
  }

  createAttendeeGroups(item: CartItem): FormGroup[] {
    const attendeesArray: FormGroup[] = [];
    for (let i = 0; i < item.quantity; i++) {
      attendeesArray.push(this.createAttendeeGroup(item));
    }
    return attendeesArray;
  }

  createAttendeeGroup(item: CartItem): FormGroup {
    return this.fb.group({
      ticketPriceId: [item.id],//here
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // Add other attendee fields as needed
    });
  }

  // Method to add an attendee for a specific cart item
  addAttendee(cartItemIndex: number): void {
    const attendeesArray = this.getAttendeesArray(cartItemIndex);
    attendeesArray.push(this.createAttendeeGroup(this.cartItems[cartItemIndex]));
    this.updateCartItemQuantity(cartItemIndex);
    this.calculateTotalPrice();
  }

  // Method to remove an attendee for a specific cart item
  removeAttendee(cartItemIndex: number, attendeeIndex: number): void {
    const attendeesArray = this.getAttendeesArray(cartItemIndex);
    attendeesArray.removeAt(attendeeIndex);
    this.updateCartItemQuantity(cartItemIndex);
    this.calculateTotalPrice();
  }

  // Helper method to get the attendees FormArray for a specific cart item
  getAttendeesArray(cartItemIndex: number): FormArray {
    return (this.attendeesByCartItemArray.at(cartItemIndex) as FormGroup).get('attendees') as FormArray;
  }

  // Update the quantity in cart based on the attendees form
  updateCartItemQuantity(cartItemIndex: number): void {
    const newQuantity = this.getAttendeesArray(cartItemIndex).length;
    this.cartService.updateQuantity(this.cartItems[cartItemIndex].id, newQuantity);//here
  }

  // Calculate total price in cents
  calculateTotalPrice(): void {
    this.totalPriceCents = this.cartItems.reduce((total, item) => {
      const attendees = this.getAttendeesArray(this.cartItems.indexOf(item)).length;
      return total + (10 * attendees);//here
    }, 0);
  }

  // Autofill user information based on email
  onEmailBlur(email: string, cartItemIndex: number): void {
    if (email && this.isEmailValid(email)) {
      this.fetchUserData(email, cartItemIndex);
    }
  }

  isEmailValid(email: string): boolean {
    const emailControl = this.attendeeForm.get('email');
    return emailControl ? !emailControl.invalid : false;
  }

  fetchUserData(email: string, cartItemIndex: number): void {
    this.authService.fetchUser(email)
      .subscribe(user => {
        if (user) {
          // Autofill the name and email fields
          this.attendeeForm.patchValue({
            name: user.name,
            email: user.email
          });

          // Optionally, autofill attendee names and emails
          this.getAttendeesArray(cartItemIndex).controls.forEach((control: AbstractControl) => {
            const attendeeGroup = control as FormGroup;
            attendeeGroup.patchValue({
              name: user.name,
              email: user.email
            });
          });
          this.snackBar.open('User information autofilled.', 'Close', { duration: 2000 });
        }
      });
  }

  // Handle form submission
  submitOrder(): void {
    /*if (this.attendeeForm.valid) {
      this.loading = true;
      // Prepare order data to include in metadata
      const orderData = {
        attendees: this.attendeeForm.value.attendeesByCartItem.flatMap(item => item.attendees)
      };
      
      // Create Payment Intent with metadata
      this.paymentService.createPaymentIntent(this.totalPriceCents, orderData).subscribe({
        next: (response) => {
          const clientSecret = response.clientSecret;

          // Confirm the card payment
          this.stripeService.confirmCardPayment(clientSecret, {
            payment_method: {
              card: {}, // Reference to the Stripe Card Element if using one
              billing_details: {
                name: this.attendeeForm.get('name')?.value,
                email: this.attendeeForm.get('email')?.value,
              },
            },
          }).subscribe(result => {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              this.snackBar.open(result.error.message || 'Payment failed.', 'Close', { duration: 5000 });
              this.loading = false;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Payment succeeded, but order logging is handled via webhook
                this.snackBar.open('Payment successful! Your order is being processed.', 'Close', { duration: 3000 });
                // Optionally, clear the cart and navigate to a confirmation page
                this.cartService.clearCart();
                this.router.navigate(['/order-confirmation']);
                this.loading = false;
              }
            }
          });
        },
        error: (error) => {
          this.snackBar.open('Failed to create payment intent.', 'Close', { duration: 3000 });
          console.error(error);
          this.loading = false;
        }
      });
    } else {
      this.snackBar.open('Please complete all required fields.', 'Close', { duration: 3000 });
    }*/
  }
}