import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/mosaic-sports/src/environments/environment';
import { Observable } from 'rxjs';
interface CheckoutSessionResponse {
  sessionId: string;
}
@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private onboardUrl =  "https://stripe-onboarding-worker.michael-twist.workers.dev";
  private paymentUrl =  environment.stripePaymentsWorker;

  constructor(private http: HttpClient) {}

  // Endpoint to create a new connected account
  createAccount(): Observable<{ account: string }> {
    return this.http.post<{ account: string }>(`${this.onboardUrl}/account`, {});
  }

  // Endpoint to create an account session and retrieve client_secret
  createAccountSession(accountId: string): Observable<{ client_secret: string }> {
    return this.http.post<{ client_secret: string }>(`${this.onboardUrl}/account_session`, { account: accountId });
  }


  createPaymentIntent(userId: string, items: any[]): Observable<{ clientSecret: string }> {
    return this.http.post<{ clientSecret: string }>(`${this.paymentUrl}/create-payment-intent`, {
      userId,
      items,
    });
  }

  /**
   * Optionally, verify payment status after checkout.
   */
  verifyPayment(sessionId: string): Observable<any> {
    return this.http.get<any>(`${this.paymentUrl}/verify-payment`, {
      params: { sessionId },
    });
  }

  createCheckoutSession(userId: string, cartId: string): Observable<CheckoutSessionResponse> {
    return this.http.post<CheckoutSessionResponse>(`${this.paymentUrl}/create-checkout-session`, {
      userId,
      cartId,
    });
  }
}