import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { PaymentIntent } from '@stripe/stripe-js';

export const PLUTO_ID = new InjectionToken<string>('[PLUTO] ClientID');



@Injectable({ providedIn: 'root' })

export class MosaicdashService {
  private static readonly BASE_URL = 'https://mosaic-sports-server-4axj2ujyfq-uc.a.run.app';
  //private static readonly BASE_URL = 'http://127.0.0.1:8080';


  constructor(
    @Inject(PLUTO_ID) private readonly clientId: string,
    private readonly http: HttpClient
  ) { }



  createPaymentIntent(params: any): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${MosaicdashService.BASE_URL}/payments/create-payment-intent`,
      params,
      { headers: { merchant: this.clientId } }

    );

  }

  createPaymentCheckoutIntent(params: any): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${MosaicdashService.BASE_URL}/create-payment-intent`,
      { params },
      {
        headers: { "Content-Type": "application/json" },
      }

    );

  }

  createCheckoutSession(params: any): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${MosaicdashService.BASE_URL}/create-checkout-session`,
      { params },
      {
        headers: { "Content-Type": "application/json" },
      }

    );

  }

  createTicketCheckoutSession(params: any): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${MosaicdashService.BASE_URL}/create-ticket-checkout-session`,
      { params },
      {
        headers: { "Content-Type": "application/json" },
      }

    );

  }



}
//export const STRIPE_PUBLIC_KEY = 'pk_test_51O7lEzCreZRsfGkIosnIBx6P0Ki8CsJgk3lrWlHbNPfSbCZi436cskPajchwU6EPAX96bE1bME1U6kRUEQcg8ZJU00sADiEw1v';
export const STRIPE_PUBLIC_KEY = 'pk_live_51O7lEzCreZRsfGkIJ7X6NW1NyENnapujpaalYvQsQDWb7nZkW7gJJGNuxXmY5dWtT9ZwA1dNofomQ50zn26sblB100OLHjWT5N';

