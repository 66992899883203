<!-- checkout.component.html -->
<div class="checkout-container">
    <h2>Checkout</h2>
    <h3>Coming Soon!</h3>
    <!--<form [formGroup]="attendeeForm" (ngSubmit)="submitOrder()">
      <div formArrayName="attendeesByCartItem" *ngFor="let cartGroup of attendeesByCartItemArray.controls; let cartIndex = index">
        <div [formGroupName]="cartIndex" class="cart-item-group">
          <mat-card class="cart-item-card">
            <mat-card-header>
              <mat-card-title>{{ cartGroup.get('cartItem')?.value.title }}</mat-card-title>
              <mat-card-subtitle>
                Price: {{ (cartGroup.get('cartItem')?.value.priceCents / 100) | currency:'USD' }}
              </mat-card-subtitle>
            </mat-card-header>
            
            <mat-card-content>
              <p><strong>Event:</strong> {{ cartGroup.get('cartItem')?.value.eventName }}</p> Display Event Name 
              <div formArrayName="attendees">
                <div *ngFor="let attendee of getAttendeesArray(cartIndex).controls; let attendeeIndex = index" [formGroupName]="attendeeIndex" class="attendee-form-group">
                  <h4>Attendee {{ attendeeIndex + 1 }}</h4>
                  <mat-form-field appearance="fill">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                    <mat-error *ngIf="attendee.get('name')?.hasError('required')">
                      Name is required
                    </mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required (blur)="onEmailBlur(attendee.get('email')?.value, cartIndex)">
                    <mat-error *ngIf="attendee.get('email')?.hasError('required')">
                      Email is required
                    </mat-error>
                    <mat-error *ngIf="attendee.get('email')?.hasError('email')">
                      Enter a valid email
                    </mat-error>
                  </mat-form-field>
  
                  Add other attendee fields as necessary
  
                  <button mat-icon-button color="warn" type="button" (click)="removeAttendee(cartIndex, attendeeIndex)" aria-label="Remove Attendee">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
  
              <button mat-button color="primary" type="button" (click)="addAttendee(cartIndex)">
                <mat-icon>add</mat-icon> Add Attendee
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
  
   Payment Details
    <mat-card class="payment-card">
        <mat-card-header>
          <mat-card-title>Payment Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field appearance="fill">
            <mat-label>Name on Card</mat-label>
            <input matInput formControlName="name" required>
            <mat-error *ngIf="attendeeForm.get('name')?.hasError('required')">
              Name on card is required
            </mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" required>
            <mat-error *ngIf="attendeeForm.get('email')?.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="attendeeForm.get('email')?.hasError('email')">
              Enter a valid email
            </mat-error>
          </mat-form-field>
  
          <label for="card-element">Credit or debit card</label>
          <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
        </mat-card-content>
      </mat-card>
  
      Total Price Display
      <div class="checkout-summary">
        <h3>Total: {{ (totalPriceCents / 100) | currency:'USD' }}</h3>
      </div>
  
      <div class="checkout-actions">
        <button mat-raised-button color="primary" type="submit" [disabled]="attendeeForm.invalid || loading">
          {{ loading ? 'Processing...' : 'Submit Order' }}
        </button>
      </div>
    </form>
  </div>-->