@if(session){
<div class="row flex-center flex">
    <div class="col form-widget" aria-live="polite">

        <p class="description">Reset your password below</p>
        <form class="sign-in-form" (ngSubmit)="passwordChangeSubmit()" class="form-widget">
            <mat-form-field class="width">
                <mat-label>New Password</mat-label>
                <input matInput [formControl]="password" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                @if (password.hasError('minlength') && !password.hasError('required')) {
                <mat-error>Password must be a minimum of 8 characters</mat-error>
                }
                @if (password.hasError('required')) {
                <mat-error>Password is <strong>required</strong></mat-error>
                }
            </mat-form-field>
            <button mat-raised-button type="submit"
                [disabled]="loading || password.hasError('minlength') || password.hasError('required')">
                {{ loading ? 'Loading' : 'Reset Password' }}
            </button>
        </form>
    </div>
</div>
}
@if (!session) { <div class="row flex-center flex">
    <div class="col form-widget" aria-live="polite">

      <p class="description">Reset your password with your email below</p>
      <form class="sign-in-form" (ngSubmit)="passwordResetSubmit()" class="form-widget">
        <mat-form-field class="width">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
          @if (email.hasError('email') && !email.hasError('required')) {
          <mat-error>Please enter a valid email address</mat-error>
          }
          @if (email.hasError('required')) {
          <mat-error>Email is <strong>required</strong></mat-error>
          }
        </mat-form-field>
        <button mat-raised-button type="submit"
          [disabled]="loading || email.hasError('email') || email.hasError('required')">
          {{ loading ? 'Loading' : 'Reset Password' }}
        </button>
      </form>
    </div>
  </div>}