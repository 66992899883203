import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@mosaicdash/services';
import { UserService } from '../../../services/user/user.service';
import { RoleSelectionDialogComponent } from '../role-selection-dialog/role-selection-dialog.component';
import { SportService } from '../../../services/sport/sport.service';
import { Sport, SportVariation } from '../../../models/sport/sport.model';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TeamsService } from '../../../services/teams/teams.service';
import { Team } from '../../../models/team/team.model';

@Component({
  selector: 'app-team-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './team-selection-dialog.component.html',
  styleUrl: './team-selection-dialog.component.css'
})
export class TeamSelectionDialogComponent implements OnInit {

  teamsForm: FormGroup;
  sports: Sport[] = [];
  teams: Team[] = []
  sportVariations: SportVariation[] = [];
  filteredSportVariations: SportVariation[] = []; // New array to hold filtered variations
  isLoading = false
  // New properties to track selection status
  isSportSelected: boolean = false;
  isSportVariationSelected: boolean = false;

  userTeams: Team[] = [];  // Add property to hold user's teams


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RoleSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private userService: UserService,
    private sportService: SportService,
    private teamService: TeamsService,
  ) {
    // Initialize forms
    this.teamsForm = this.fb.group({
      teamId: [null, Validators.required], // Add teamId control
      gender: [null, Validators.required],
      sportVariationId: [null, Validators.required], // Add sportVariationId control
    });
  }

  ngOnInit(): void {
    this.loadUserTeams();  // Load user's teams on initialization
    this.loadTeams();

    // Listen to changes on 'teamId' to load sport variations
    this.teamsForm.get('teamId')?.valueChanges.subscribe(teamId => {
      this.loadSportVariations(teamId);
    });

    // Listen to changes on 'gender' to filter sport variations based on selected gender
    this.teamsForm.get('gender')?.valueChanges.subscribe(selectedGender => {
      this.filterSportVariations(selectedGender);
    });
  }
  loadTeams(): void {
    this.teamService.getTeams().subscribe({
      next: (teams) => {
        this.teams = teams;
      },
      error: (error) => {
        this.snackBar.open(`Error loading teams: ${error.message}`, 'Close', { duration: 5000 });
      }
    });
  }

  loadUserTeams(): void {
   /*
    this.teamService.getUserTeams().subscribe({
      next: (teams) => {
        this.userTeams = teams; // Set the user's teams
      },
      error: (error) => {
        this.snackBar.open(`Error loading user's teams: ${error.message}`, 'Close', { duration: 5000 });
      }
    });*/
  }


  loadSportVariations(teamId: string): void {
    const selectedTeam = this.teams.find(team => team.id === teamId);

    if (selectedTeam) {
      this.sportVariations = selectedTeam.team_sport_variations || []; // Set sport variations from selected team
      this.teamsForm.get('sportVariationId')?.setValue(null); // Reset selection
      this.filterSportVariations(this.teamsForm.get('gender')?.value); // Filter based on current gender
    } else {
      this.sportVariations = [];
      this.filteredSportVariations = [];
    }
  }
  filterSportVariations(selectedGender: string): void {
    this.filteredSportVariations = this.sportVariations.filter(variation =>
      variation.gender === selectedGender
    );
  }

  submitForm(): void {
    console.log('yes')
    if (this.teamsForm.valid) {
      const formData = this.teamsForm.value;
  
      this.isLoading = true; // Show loading spinner
      this.teamService.joinTeam(formData.teamId, formData.gender, formData.sportVariationId)
        .subscribe({
          next: (response) => {
            this.isLoading = false; // Hide loading spinner
            this.snackBar.open('Successfully joined the team!', 'Close', { duration: 5000 });
            this.dialogRef.close(true); // Close dialog with success
          },
          error: (error) => {
            this.isLoading = false; // Hide loading spinner
            this.snackBar.open(`Failed to join team: ${error.message}`, 'Close', { duration: 5000 });
          }
        });
    } else {
      this.snackBar.open('Please fill in all required fields.', 'Close', { duration: 5000 });
    }
  }
  cancel() {
    this.dialogRef.close(null);
  }
}
/* loadSports(): void {
    this.sportService.getSports().subscribe({
      next: (sports) => {
        this.sports = sports;
      },
      error: (error) => {
        this.snackBar.open(`Error loading sports: ${error.message}`, 'Close', { duration: 5000 });
      }
    });
  }

  // Fetch variations based on selected sport and update labels
  loadSportVariations(sportId: number): void {
    if (sportId) {
      this.isLoading = true;
      this.sportService.getSportVariations(sportId).subscribe({
        next: (variations) => {
          this.sportVariations = variations;
          // Reset sportVariationId when sport changes
          this.teamsForm.get('sportVariationId')?.setValue(null);
          // Update selection status
          this.isSportSelected = true;
          this.isSportVariationSelected = false;
          
          this.isLoading = false;
        },
        error: (error) => {
          this.snackBar.open(`Error loading sport variations: ${error.message}`, 'Close', { duration: 5000 });
          // Fallback to default labels
          this.isSportSelected = false;
          this.isSportVariationSelected = false;
          this.isLoading = false;
        }
      });
    } else {
      this.sportVariations = [];
      this.teamsForm.get('sportVariationId')?.setValue(null);
      // Reset labels to default
      this.isSportSelected = false;
      this.isSportVariationSelected = false;
      this.isLoading = false;
    }
  }*/
