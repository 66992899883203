@if(data$ | async; as data)
{
<mat-card>
    <mat-card-header>
        <mat-card-title>{{ data.event.title }}</mat-card-title>
        <mat-card-subtitle>{{ data.event.start_date| date }}</mat-card-subtitle>
        @if(isEditor){
        <div class="edit-button">
            <button mat-icon-button (click)="toggleEditMode()">
                <mat-icon>{{ isEditMode ? 'close' : 'edit' }}</mat-icon>
            </button>
        </div>
        }
    </mat-card-header>
    <mat-card-content>
        <p>{{ data.event.description }}</p>
    </mat-card-content>
    <mat-card-actions>
        <!--<a class="action" mat-raised-button color="primary" [routerLink]="['/event', base64Id, 'workers']">
            View All Workers
        </a>
        <a class="action" mat-raised-button color="primary" [routerLink]="['/event', base64Id, 'workers']">
            View All Teams
        </a>
        <a class="action" mat-raised-button color="primary" [routerLink]="['/event', base64Id, 'workers']">
            View All Athletes
        </a>-->
    </mat-card-actions>
</mat-card>

<mat-tab-group class="tabs" dynamicHeight mat-stretch-tabs="false" mat-align-tabs="center"
    [selectedIndex]="selectedIndex" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
    @if(canViewEvent){
    <mat-tab class="tab">
        <ng-template mat-tab-label>
            <a class="label">{{data.event.sport_variations?.event_name}}</a>
        </ng-template>
        <div class="center">
            <app-event-card [event]="data.event"></app-event-card>
        </div>
    </mat-tab>
    }
    @if(canViewSubEvents){
    <mat-tab class="tab">
        <ng-template mat-tab-label>
            <a class="label">{{data.event.sport_variations?.sub_event_name}}s</a>
        </ng-template>
        <div class="center-hor">
            <div class="event-container">

                <!-- List of Sub-Events -->
                <div class="card-list">
                    <!-- Existing Sub-Events -->
                    @for(eventSubEvent of data.eventSubEvents; track eventSubEvent){
                    <app-sub-event-card [eventSubEvent]="eventSubEvent" [event]="data.event"
                        (eventSubEventSaved)="handleSubEventSaved($event)"
                        (eventSubEventCancelled)="handleSubEventCancelled()">
                    </app-sub-event-card>
                    }

                    <!-- Add New Sub-Event Card (only for directors) -->
                    @if(canEditSubEvents){
                    <app-sub-event-card [eventSubEvent]="undefined" [event]="data.event"
                        (eventSubEventSaved)="handleSubEventSaved($event)"
                        (eventSubEventCancelled)="handleSubEventCancelled()">
                    </app-sub-event-card>
                    }
                </div>
            </div>
        </div>
    </mat-tab>
    }
    @if(canViewTickets){
    <mat-tab class="tab">
        <ng-template mat-tab-label>
            <a class="label">Tickets</a>
        </ng-template>
        <div class="center-hor">
            <div class="event-container">
                <!-- List of Tickets -->
                <div class="card-list">
                    <!-- Existing Sub-Events -->
                    @for(ticket of data.tickets; track ticket){
                    <app-ticketing-card [ticket]="ticket" [event]="data.event" [editable]="canEditTickets">
                    </app-ticketing-card>
                    }
                    @if(canEditTickets){
                    <app-ticketing-card [ticket]="undefined" [event]="data.event">
                    </app-ticketing-card>
                    }
                </div>
            </div>
        </div>
    </mat-tab>
    }
    @if(canViewFinancials){
    <mat-tab class="tab">
        <ng-template mat-tab-label>
            <a class="label">Financials</a>
        </ng-template>
        <div class="center">
        </div>
    </mat-tab>
    }
    @if(canViewJobs){
    <!--MIGHT WANT TO CHANGE TO JOBS-->
    <mat-tab class="tab">
        <ng-template mat-tab-label>
            <a class="label">Jobs</a>
        </ng-template>
        <div class="center-hor">
            <div class="event-container">

                <!--List of Workers-->
                <div class="card-list">

                    <!--Existing Workers-->
                    @for(job of jobs; track job){
                    <app-job-card>
                    </app-job-card>
                    }
                    @if(canEditJobs){
                    <app-job-card>
                    </app-job-card>
                    }
                </div>
            </div>
        </div>
    </mat-tab>
    }

</mat-tab-group>
}@else {
<div class="loading-container">
    <mat-progress-spinner mode="indeterminate" diameter="50">
    </mat-progress-spinner>
    <p>Loading event details...</p>
</div>
}
<!-- <app-registration-card>
  </app-registration-card>

 -->

<!--<app-registration-card
      [event]="event"
      [isEditMode]="isEditMode && isEditor"
      (save)="saveEvent($event)">
    </app-registration-card>
  
    <app-ticketing-card
      [event]="event"
      [isEditMode]="isEditMode && isEditor"
      (save)="saveEvent($event)">
    </app-ticketing-card>-->