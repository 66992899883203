<div *ngIf="athleteMusic.length > 0" class="user-teams">
    <h3 class="white">Your Songs</h3>
    <ul>
      <li class="white" *ngFor="let music of athleteMusic">{{ music.song_title }} by {{ music.artist }}</li>
    </ul>
  </div>
  
  <form [formGroup]="songsForm" (ngSubmit)="submitForm()">
    <!-- Song Title -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Song Title</mat-label>
      <input matInput formControlName="songName" />
      <mat-error *ngIf="songsForm.get('songName')?.hasError('required')">
        Song title is required
      </mat-error>
    </mat-form-field>
  
    <!-- Artist Name -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Artist Name</mat-label>
      <input matInput formControlName="artistName" />
      <mat-error *ngIf="songsForm.get('artistName')?.hasError('required')">
        Artist name is required
      </mat-error>
    </mat-form-field>
  
    <button class="button" mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">Submit Song</button>
  </form>
  
  <!-- Loading Spinner -->
  <div *ngIf="isLoading" class="spinner-container">
    <mat-progress-spinner diameter="40" mode="indeterminate"></mat-progress-spinner>
  </div>