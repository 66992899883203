<div class="cart-container">
  @if(detailedCartItems.length > 0){
  <div class="center-hor">
    <div class="event-container">
      <!-- List of Tickets -->
      <div>
        <div>
          <h4>Cart:</h4>
          @for(group of groupedItems; track group)
          {


          <mat-expansion-panel>
            <mat-expansion-panel-header>{{group.eventName}}</mat-expansion-panel-header>
            <mat-accordion>
              @for(detailedItem of group.items; track detailedItem){
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{detailedItem.itemName}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{detailedItem.priceName}}
                  </mat-panel-description>
                </mat-expansion-panel-header>

                @if(isTicketCartItem(detailedItem.cartItem))
                {
                @if (detailedItem.cartItem.attendees)
                {
                @for(attendee of detailedItem.cartItem.attendees; track attendee; let i = $index){
                <mat-expansion-panel>

                  <mat-expansion-panel-header class="header-align">
                    <p>{{ detailedItem.cartItem.attendees[i][detailedItem.requiredFields![0].name] ?
                      detailedItem.cartItem.attendees[i][detailedItem.requiredFields![0].name]:'Attendee ' + (i+1) }}
                    </p>
                  </mat-expansion-panel-header>
                  <div>
                    @if(detailedItem.cartItem.attendees[i]){
                    @for(field of detailedItem.requiredFields; track field; let j = $index){
                    <mat-form-field appearance="fill">
                      <mat-label>{{ field.label }}</mat-label>
                      <input matInput [type]="field.type" [(ngModel)]="attendee[field.name]"
                        (change)="onAttendeeFieldChange(detailedItem, i)" [required]="field.required" />
                    </mat-form-field>

                    }
                    }

                  </div>

                </mat-expansion-panel>
                }
                }
                }
              </mat-expansion-panel>
              }
            </mat-accordion>
          </mat-expansion-panel>
          }
        </div>
      </div>
    </div>
  </div>
  <!-- Cart Summary -->
  <div class="cart-summary">
    <h3>Total: {{ getTotalPriceDollars(totalPriceCents) | currency }}</h3>
    <button mat-raised-button color="primary" (click)="proceedToCheckout()">Proceed to Checkout</button>
    <button mat-button color="warn" (click)="clearCart()">Clear Cart</button>
  </div>

  }@else {
  <div class="empty-cart-message">
    <h2>Your cart is empty.</h2>
    <a mat-raised-button color="primary" [routerLink]="['/events']">Browse Events</a>
  </div>
  }



  <!-- <app-card-template [title]="detailedItem.eventName" [subtitle]="detailedItem.itemName"
          [subsubtitle]="(detailedItem.priceCents/100| currency)!.toString()" [showContent]="true">
          <div card-body>
            <-- Display Attendee Summary ->
            <div *ngIf="isTicketCartItem(detailedItem.cartItem)">
              @if( detailedItem.cartItem.attendees){
              <--@for(attendee of detailedItem.cartItem.attendees; track attendee){->
              <h4>Attendees:</h4>
              <div class="scrollable-content">
                <mat-accordion displayMode="flat">

                  @for(quantity of getQuantityArray(detailedItem.cartItem.quantity); track quantity; let i = $index){
                  <mat-expansion-panel togglePosition="before">

                    <mat-expansion-panel-header class="header-align">
                      <p>{{ detailedItem.cartItem.attendees[i] ?
                        detailedItem.cartItem.attendees[i][detailedItem.requiredFields![0].name]:'Attendee ' + (i+1) }}
                      </p>
                      <button mat-icon-button color="primary" aria-label="Edit Attendees" matTooltip="Edit Attendees"
                        (click)="editAttendees(detailedItem, i)" class="right-align">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </mat-expansion-panel-header>
                    <div>
                      @if(detailedItem.cartItem.attendees[i]){
                      @for(requiredField of detailedItem.requiredFields; track requiredField; let j = $index){
                      @if(!$first){
                      <mat-expansion-panel class="remove-mat-expansion-panel-padding">
                        <mat-expansion-panel-header expandedHeight="30px" collapsedHeight="30px">
                          {{ detailedItem.requiredFields![j].label }}
                        </mat-expansion-panel-header>
                        <p>{{ detailedItem.cartItem.attendees[i] ?
                          detailedItem.cartItem.attendees[i][requiredField.name]:'' }}</p>
                      </mat-expansion-panel>
                      }
                      }
                      }

                    </div>

                  </mat-expansion-panel>
                  }
                </mat-accordion>
              </div>
              <--}->

              }
              @else{
              <p>No attendee information provided.</p>
              }
            </div>
          </div>

          <div card-actions>
            <button mat-icon-button color="primary" aria-label="Decrease Quantity"
              (click)="updateQuantity(detailedItem.cartItem.id, detailedItem.cartItem.quantity - 1)">
              <mat-icon>remove</mat-icon>
            </button>
            <span class="quantity-display">{{ detailedItem.cartItem.quantity }}</span>
            <button mat-icon-button color="primary" aria-label="Increase Quantity"
              (click)="updateQuantity(detailedItem.cartItem.id, detailedItem.cartItem.quantity + 1)">
              <mat-icon>add</mat-icon>
            </button>
            <button mat-icon-button color="warn" aria-label="Remove Item"
              (click)="removeItem(detailedItem.cartItem.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </app-card-template>
 -->