import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private apiUrl =  "https://stripe-onboarding-worker.michael-twist.workers.dev";

  constructor(private http: HttpClient) {}

  // Endpoint to create a new connected account
  createAccount(): Observable<{ account: string }> {
    return this.http.post<{ account: string }>(`${this.apiUrl}/account`, {});
  }

  // Endpoint to create an account session and retrieve client_secret
  createAccountSession(accountId: string): Observable<{ client_secret: string }> {
    return this.http.post<{ client_secret: string }>(`${this.apiUrl}/account_session`, { account: accountId });
  }
}