<mat-grid-list cols={{value}} rowHeight="520px" class="grid"><!--Change to row per heat-->
    @for (allUser of allUsers; track allUser ){
    <mat-grid-tile>
        @defer (on viewport) {
        <app-athlete-card class="card" [athleteID]="allUser.id"></app-athlete-card><!--[sessionSent]=false-->
        } @placeholder {
        <app-card-template
          [image]="'../../../assets/images/profilePhoto.jpg'" >
        </app-card-template>
        }
    </mat-grid-tile>
    }
</mat-grid-list>


<!--<mat-card class="cards">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
               
                <mat-card-title>Events: {{allUser.event}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>-->

<!-- </mat-card-content>-->
<!--<mat-card-actions> Add link to athlete profile
  <button mat-button>LIKE</button>
  <button mat-button>SHARE</button>
</mat-card-actions>-->
<!--</mat-card>-->