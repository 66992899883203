<!-- src/app/components/create-event/create-event.component.html -->

<div class="event-creation-container">
  <h2>Create a New {{ selectedEventName }}</h2>
  <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">

    <!-- Select Sport -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Select Sport</mat-label>
      <mat-select formControlName="sportId" required>
        <mat-option *ngFor="let sport of sports" [value]="sport.id">
          {{ sport.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="eventForm.get('sportId')?.hasError('required')">
        Sport selection is required
      </mat-error>
    </mat-form-field>

    <!-- Select Sport Variation -->
    <mat-form-field appearance="fill" class="full-width" *ngIf="isSportSelected">
      <mat-label>Select Sport Variation</mat-label>
      <mat-select formControlName="sportVariationId" required>
        <mat-option *ngFor="let variation of sportVariations" [value]="variation.id">
          {{ variation.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="eventForm.get('sportVariationId')?.hasError('required')">
        Sport Variation selection is required
      </mat-error>
    </mat-form-field>
@if(isLoading && isSportSelected){
    <mat-progress-spinner diameter="40" mode="indeterminate"></mat-progress-spinner>
}

    <!-- Conditionally Render the Rest of the Form -->
    <div *ngIf="isSportSelected && isSportVariationSelected">
      <!-- Title -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{selectedEventName}} Title</mat-label>
        <input matInput formControlName="title" required>
        <mat-error *ngIf="eventForm.get('title')?.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="4"></textarea>
      </mat-form-field>

      <!-- Online Event Checkbox -->
      <mat-checkbox formControlName="isOnline">
        Is this a virtual {{ selectedEventName.toLowerCase() }}?
      </mat-checkbox>

      
      <!-- Location -->
      @if(!eventForm.get('isOnline')?.value){
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Venue</mat-label>
        <input matInput formControlName="venueId" required>
        <mat-error *ngIf="eventForm.get('venueId')?.hasError('required')">
          Location is required
        </mat-error>
      </mat-form-field>
    }

      <!-- Date -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startDate" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="eventForm.get('date')?.hasError('required')">
          Start Date is required
        </mat-error>
      </mat-form-field>

      <!-- Multi-Day Event Checkbox -->
      <mat-checkbox formControlName="isMultiDay">
        Is this a multi-day {{ selectedEventName.toLowerCase() }}?
      </mat-checkbox>

      @if(eventForm.get('isMultiDay')?.value){
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="endDate" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="eventForm.get('date')?.hasError('required')">
          Date is required
        </mat-error>
      </mat-form-field>
    }

      <!-- Flat Fee Checkbox -->
      <mat-checkbox formControlName="isFlatFee">
        Apply Flat Fee for Multiple {{ selectedSubEventName.toLowerCase() }}s
      </mat-checkbox>

      <!-- Flat Fee Amount -->
      <mat-form-field appearance="fill" class="full-width" *ngIf="isFlatFee">
        <mat-label>Flat Fee Amount ($)</mat-label>
        <input matInput type="number" formControlName="flatFeeAmount">
        <mat-error *ngIf="eventForm.get('flatFeeAmount')?.hasError('min')">
          Fee must be a positive number
        </mat-error>
      </mat-form-field>

    </div>

    <!-- Submit Button -->
    @if(isSportSelected && isSportVariationSelected){
      <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">
      {{ isLoading ? 'Creating...' : ('Create ' + selectedEventName) }}
    </button>}
  </form>
</div>


      <!--

       Button to Add a New Sub-Event/Variation 
      <button mat-raised-button color="accent" type="button" (click)="addEvent()">
        Add {{ selectedSubEventName === 'Meet' ? 'Sub-Meet' : 'Sub Event' }}
      </button>

       Display the List of Sub-Events/Variations 
      <div formArrayName="events" *ngFor="let event of events.controls; let i = index">
        <mat-card class="event-card">
          <mat-card-header>
            <mat-card-title>{{ selectedSubEventName }} {{ i + 1 }}</mat-card-title>
            <button mat-icon-button color="warn" type="button" (click)="removeEvent(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content [formGroupName]="i">
          
             Event Name 
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ selectedSubEventName }} Name</mat-label>
              <input matInput formControlName="eventName" required>
              <mat-error *ngIf="event.get('eventName')?.hasError('required')">
                {{ selectedEventName }} name is required
              </mat-error>
            </mat-form-field>

             Event Sponsor 
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ selectedSubEventName }} Sponsor</mat-label>
              <input matInput formControlName="eventSponsor">
            </mat-form-field>

             Event Type 
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ selectedSubEventName }} Type</mat-label>
              <mat-select formControlName="eventType" required>
                <mat-option value="individual">Individual</mat-option>
                <mat-option value="relay">Relay</mat-option>
                <mat-option value="field">Field</mat-option>
              </mat-select>
              <mat-error *ngIf="event.get('eventType')?.hasError('required')">
                {{ selectedEventName }} type is required
              </mat-error>
            </mat-form-field>

             Eligible Ages 
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Eligible Ages</mat-label>
              <input matInput formControlName="eligibleAges" required placeholder="e.g., 12-15">
              <mat-hint>Specify age range</mat-hint>
              <mat-error *ngIf="event.get('eligibleAges')?.hasError('required')">
                Eligible ages are required
              </mat-error>
            </mat-form-field>

             Price 
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Price ($)</mat-label>
              <input matInput type="number" formControlName="price" required>
              <mat-error *ngIf="event.get('price')?.hasError('required')">
                Price is required
              </mat-error>
              <mat-error *ngIf="event.get('price')?.hasError('min')">
                Price must be a positive number
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>-->
