import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AthleteCardComponent } from '../../shared/components/athlete-card/athlete-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthenticationService } from '@mosaicdash/services';
import { Athlete } from '../../models/athlete/athlete.model';
import { AthleteService } from '../../services/athlete/athlete.service';
import { URLHandlerService } from '../../services/urlhandler/urlhandler.service';
import { AuthSession } from '@supabase/supabase-js';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-profile-viewer',
  standalone: true,
  imports: [AthleteCardComponent, MatButtonModule, MatTabsModule, MatProgressSpinnerModule],
  templateUrl: './profile-viewer.component.html',
  styleUrl: './profile-viewer.component.css'
})
export class ProfileViewerComponent implements OnInit {
  session: AuthSession | null = null; // Updated to use reactive session handling
  loading = false;
  userID = "";
  testId1 = "one"
  testId2 = "0daed35c-e08e-4463-9134-111315626bb1";
  userIDBase64 = "hi"
  userIDHex = "2"
  url = ""
  athlete!: Athlete
  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 3; // Total number of tabs change when we turn on finance

  constructor(private readonly authenticationService: AuthenticationService,
    private readonly athleteService: AthleteService, 
    private route: ActivatedRoute, 
    private readonly URLHandler: URLHandlerService) { }


  async ngOnInit(): Promise<void> {
    this.loading=true
    this.route.params.subscribe(params => {
      this.userID = params['id']; // Access the 'id' parameter from the URL
    });
    await this.URLHandler.base64ToHex(this.userID).then(res => {
      
      this.userID = res;console.log(this.userID)
    })
    await this.getAthleteData()
   this.loading=false
  }

  async getAthleteData() {
    try {
      const { data: athlete, error, status } = await this.athleteService.getAthlete(this.userID)

      if (error && status !== 406) {
        throw error
      }

      if (athlete) {
        this.athlete = athlete
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
    }
  }

      // Swipe event handlers
      onSwipeLeft() {
        if (this.selectedIndex < this.tabCount - 1) {
          this.selectedIndex++;
        }
      }
    
      onSwipeRight() {
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
      }
}


    /* Tests
    await this.hexToBase64(this.userID).then(res => {
      this.userIDBase64 = res;
    })

    this.testId1 = this.userIDBase64.replace(/=/g, "")

    await this.base64ToHex(this.userIDBase64).then(res => {
      this.userIDHex = res;
    })

  await this.base64ToHex(this.testId1).then(res => {
      this.testId2 = res;
    })
    const { name, nickname, strava, world_athletics, milesplit, tfrrs, instagram } = this.athlete

    /*this.userIDBase64=  Buffer.from("0daed35c-e08e-4463-9134-111315626bb1").toString('base64');
    this.userIDBinary= Buffer.from(this.userIDBase64, 'base64').toString('binary');*/


/*
    this.userIDBase64 = this.testId1.toString()
    this.userIDBase64 = window.btoa(this.userIDBase64)
    this.userIDBinary = window.atob(this.userIDBase64)
    */

  


//if the card does not exist it breaks our code!
//fixed by adding @if to usercard html should probaby make a blank card