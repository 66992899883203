import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SupabaseService } from '@mosaicdash/services';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { NgcCookieConsentService, NgcInitializingEvent, NgcInitializationErrorEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { bootstrapApplication } from '@angular/platform-browser';
import { AuthenticationService } from '@mosaicdash/services';
import { environment } from '../environments/environment';
import { AuthSession } from '@supabase/supabase-js';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    FooterComponent, 
    NavigationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})


export class AppComponent implements OnInit, OnDestroy {
  title = 'mosaic-sports-standalone';
  session: AuthSession | null = null; // Updated to use reactive session handling

  // Subscriptions for cookie consent events
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  // Subscription for authentication session
  private authSessionSubscription!: Subscription;

  constructor(
    private readonly authenticationService: AuthenticationService, 
    private ccService: NgcCookieConsentService, 
    private gaService: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    if (environment.passwordProtection) {
      this.promptForPassword();
    }
    // Subscribe to authentication session changes
    this.authSessionSubscription = this.authenticationService.session$.subscribe(
      (session) => {
        this.session = session;
        // Additional logic based on session can be added here
      },
      (error) => {
        console.error('Error subscribing to session:', error);
      }
    );

    // Subscribe to cookie consent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // You can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen');
      }
    );

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // You can use this.ccService.getConfig() to do stuff...
        console.log('popupClose'); // Corrected typo from 'popuClose'
      }
    );

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // The cookieconsent is initializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      }
    );

    this.initializedSubscription = this.ccService.initialized$.subscribe(
      (event: any) => { // Adjusted to receive event if available
        // The cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`); // Correctly using event parameter
      }
    );

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // The cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // You can use this.ccService.getConfig() to do stuff...
        console.log(`statusChange: ${JSON.stringify(event)}`);
        this.handleConsentChange(event.status);
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // You can use this.ccService.getConfig() to do stuff...
        console.log(`revokeChoice`);
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // You can use this.ccService.getConfig() to do stuff...
        console.log(`noCookieLaw: ${JSON.stringify(event)}`);
      }
    );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.authSessionSubscription) {
      this.authSessionSubscription.unsubscribe();
    }
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  private handleConsentChange(status: string): void {
    if (status === 'allow') {
      this.gaService.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    } else {
      this.gaService.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    }
  }
  promptForPassword(): void {
    const password = prompt('Please enter the beta access password:');
    if (password !== environment.loginPassword) {
      alert('Incorrect password. You will be redirected.');
      window.location.href = 'https://mosaic-sports.com';
    }
  }
}