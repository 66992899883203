import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SupabaseService } from '@mosaicdash/services'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@mosaicdash/services';
import { async, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIcon,
    AsyncPipe
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})
export class AuthComponent implements OnInit {
  @Output() loginSuccess = new EventEmitter<void>();
  hide = true;
  loading = false
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required,])
  newPassword = new FormControl('', [Validators.required, Validators.minLength(8)])
  selectedTab = 0;
  isAuthenticatedOrGuest$: Observable<boolean>;


  constructor(private readonly supabase: SupabaseService,
    private route: ActivatedRoute,
    private readonly authentication: AuthenticationService) {    this.isAuthenticatedOrGuest$ = this.authentication.isAuthenticatedOrGuest$();
    }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const action = params['action'];
      if (action === 'signup') {
        this.selectedTab = 0;
      } else {
        this.selectedTab = 1; // Assuming the default is login
      }
    });
  }

  /*async magicLinkSubmit(): Promise<void> {
    try {
      this.loading = true
      const email = this.email.value as string
      const { error } = await this.supabase.signIn(email)
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.email.reset
      this.loading = false
    }
  }*/

  async passwordSubmit(): Promise<void> {
    try {
      this.loading = true
      const email = this.email.value as string
      const password = this.password.value as string
      const { error } = await this.authentication.signInPassword(email, password)
      if (error) throw error
      //alert('Check your email for the login link!')
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loginSuccess.emit(); // Emit the event to notify parent component
      this.email.reset
      this.password.reset
      this.loading = false
    }
  }


  async signUpSubmit(): Promise<void> {
    try {
      this.loading = true
      const email = this.email.value as string
      const newPassword = this.newPassword.value as string
      const { error } = await this.authentication.signUp(email, newPassword)
      if (error) throw error
      alert('Check your email for the confirmation link!')
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loginSuccess.emit(); // Emit the event to notify parent component
      this.email.reset
      this.newPassword.reset
      this.loading = false
    }
  }

  async signOut() {
    await this.authentication.signOut();
  }
}

