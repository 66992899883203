<div class="contact-container">
    <h1>Contact Us</h1>
    <div class="contact-info">
      <div class="contact-item">
        <h2>General Inquiries</h2>
        <p>If you have any questions or need assistance, please reach out to us:</p>
        <p>Email: <a href="mailto:contact@mosaic-sports.com">contact&#64;mosaic-sports.com</a></p>
        <p>Phone: <a href="tel:+13477467653">347-746-7653</a></p>
      </div>
      <div class="contact-item">
        <h2>ADA Support</h2>
        <p>We are committed to providing the best experience for all our users. If you encounter any issues or need assistance with accessibility, please contact us:</p>
        <p>Email: <a href="mailto:ada@mosaic-sports.com">ada&#64;mosaic-sports.com</a></p>
        <p>Phone: <a href="tel:+13477467653">347-746-7653</a></p>
      </div>
    </div>
    <!--
    <div class="contact-form">
      <h2>Contact Form</h2>
      <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" name="name" ngModel required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" ngModel required>
        </div>
        <div class="form-group">
          <label for="message">Message:</label>
          <textarea id="message" name="message" rows="5" ngModel required></textarea>
        </div>
        <button type="submit" [disabled]="!contactForm.valid">Submit</button>
      </form>
    </div>-->
  </div>
  