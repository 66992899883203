import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AttendeeInfo,
  CartItem,
  NewCartItem,
  PhysicalCartItem,
  RegistrationCartItem,
  TicketCartItem,
} from '../../models/cart/cart.model';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique IDs
import { SupabaseService } from '@mosaicdash/services';
import { SupabaseClient } from '@supabase/supabase-js';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private supabase: SupabaseClient;

  private cartId: string;

  private cartItems: CartItem[] = [];
  private cartItemsSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject<
    CartItem[]
  >([]);

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();

    // Initialize cartId
    const storedCartId = localStorage.getItem('cartId');
    if (storedCartId) {
      this.cartId = storedCartId;
    } else {
      this.cartId = uuidv4();
      localStorage.setItem('cartId', this.cartId);
    }

    // Initialize cart items
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      this.cartItems = JSON.parse(storedCart) as CartItem[];
      this.cartItemsSubject.next(this.cartItems);
    }
  }

  // Get the current cart items as an Observable
  getCartItems(): Observable<CartItem[]> {
    return this.cartItemsSubject.asObservable();
  }

  // Add an item to the cart
  addToCart(item: NewCartItem): void {
    const newItem: CartItem = {
      ...item,
      id: uuidv4(),
      ...(item.type === 'ticket'
        ? { attendees: [] }
        : item.type === 'registration'
        ? { registrants: [] }
        : item.type === 'physical'
        ? { attendees: [] }
        : {}),
    };
    console.log(newItem);
    const existingItemIndex = this.cartItems.findIndex(
      (ci) => ci.type === newItem.type && this.identifiersMatch(ci, newItem)
    );

    if (existingItemIndex > -1) {
      // If the item already exists, increment the quantity
      this.cartItems[existingItemIndex].quantity += newItem.quantity;
    } else {
      // Otherwise, add the new item to the cart

      if (newItem.type === 'ticket') {
        const ticketItem = newItem as TicketCartItem;
        ticketItem.attendees = [];
        for (let i = 0; i < newItem.quantity; i++) {
          ticketItem.attendees.push({});
        }
      }
      this.cartItems.push(newItem);
    }
    this.cartItemsSubject.next([...this.cartItems]); // Emit a new array reference
    this.saveCart();
  }

  // Remove an item from the cart
  removeFromCart(itemId: string): void {
    this.cartItems = this.cartItems.filter((ci) => ci.id !== itemId);
    this.cartItemsSubject.next([...this.cartItems]);
    this.saveCart();
  }

  // Update the quantity of an item in the cart
  updateQuantity(itemId: string, quantity: number): void {
    const item = this.cartItems.find((ci) => ci.id === itemId);
    if (item) {
      item.quantity = quantity;
      if (item.quantity <= 0) {
        this.removeFromCart(itemId);
      } else {
        // Adjust attendees array length if item is a ticket
        if (item.type === 'ticket') {
          const ticketItem = item as TicketCartItem;
          ticketItem.attendees = ticketItem.attendees || [];
          while (ticketItem.attendees.length < quantity) {
            ticketItem.attendees.push({});
          }
          while (ticketItem.attendees.length > quantity) {
            ticketItem.attendees.pop();
          }
        }

        this.cartItemsSubject.next([...this.cartItems]); // Emit a new array reference
        this.saveCart();
      }
    }
  }

  // Clear the entire cart
  clearCart(): void {
    this.cartItems = [];
    this.cartItemsSubject.next([...this.cartItems]);
    this.saveCart();
  }

  // Calculate the total price in cents
  getTotalPriceCents(): number {
    // Placeholder: Replace with actual implementation to fetch current prices
    let total = 0;
    this.cartItems.forEach((item) => {
      const currentPrice = this.getCurrentPriceForItem(item);
      total += currentPrice * item.quantity;
    });
    return total;
  }

  // Placeholder method to fetch current price; replace with actual implementation
  private getCurrentPriceForItem(item: CartItem): number {
    // Example logic:
    // - Make an API call based on item.type and relevant IDs to get the latest price
    // - For simplicity, returning a fixed value here
    if (item.type === 'ticket') {
      return 5000; // Example: $50.00
    } else if (item.type === 'registration') {
      return 3000; // Example: $30.00
    }
    return 0;
  }

  // Get quantity of a specific item
  getQuantity(itemId: string): number {
    const item = this.cartItems.find((ci) => ci.id === itemId);
    return item ? item.quantity : 0;
  }

  // Update attendee information for a specific ticket
  updateAttendee(
    itemId: string,
    attendeeInfo: AttendeeInfo,
    selectedIndex: number
  ): void {
    console.log(itemId, attendeeInfo);
    const item = this.cartItems.find(
      (ci) => ci.id === itemId && ci.type === 'ticket'
    ) as TicketCartItem;
    if (item && item.attendees !== undefined) {
      item.attendees[selectedIndex] = attendeeInfo;
      this.cartItemsSubject.next([...this.cartItems]);
      console.log('working', this.cartItemsSubject);
      this.saveCart();
    }
  }

  // Persist cart to localStorage
  private saveCart(): void {
    localStorage.setItem('cart', JSON.stringify(this.cartItems));
    localStorage.setItem('cartId', this.cartId); // Ensure cartId is saved
  }

  // Helper method to match item identifiers based on type
  private identifiersMatch(ci1: CartItem, ci2: NewCartItem): boolean {
    if (ci1.type === ci2.type) {
      switch (ci1.type) {
        case 'ticket':
          return (
            ci1.ticketPriceId ===
            (ci2 as Omit<TicketCartItem, 'id'>).ticketPriceId
          );
        case 'registration':
          return (
            ci1.registrationPriceId ===
            (ci2 as Omit<RegistrationCartItem, 'id'>).registrationPriceId
          );
        case 'physical':
          return (
            ci1.physicalPriceId ===
            (ci2 as Omit<PhysicalCartItem, 'id'>).physicalPriceId
          );
        default:
          return false;
      }
    }
    return false;
  }

  async saveCartToSupabase(): Promise<string> {
    // Prepare cart items as JSON array
    const cartItemsJson = this.cartItems.map((item) => {
      // Base structure
      const baseItem = {
        id: item.id, // Include the item's ID
        type: item.type,
        quantity: item.quantity,
      };

      // Add type-specific fields
      switch (item.type) {
        case 'ticket':
          return {
            ...baseItem,
            ticket_id: item.ticketId,
            ticket_price_id: item.ticketPriceId,
            attendees: item.attendees,
          };
        case 'registration':
          return {
            ...baseItem,
            registration_id: item.registrationId,
            registration_price_id: item.registrationPriceId,
            registrants: item.registrants,
          };
        case 'physical':
          return {
            ...baseItem,
            ticket_id: item.ticketId,
            physical_price_id: item.physicalPriceId,
            attendees: item.attendees,
          };
        default:
          return baseItem;
      }
    });

    const { data, error } = await this.supabase.rpc('save_cart', {
      p_cart_id: this.cartId,
      p_cart_items: cartItemsJson,
    });

    if (error) {
      console.error('Error saving cart to Supabase:', error);
      throw new Error('Failed to save cart to Supabase.');
    }

    const cartId = data as string;

    // Optionally, clear the local cart after saving
    // this.clearCart();

    return cartId;
  }

  async handlePaymentSuccess() {
    try {
      // Perform any necessary operations before clearing the cart
      // For example, saving the order to a database

      // Clear the cart and cart ID
      this.clearCartAfterPurchase();

      // Optionally, navigate the user to a confirmation page
      // this.router.navigate(['/order-confirmation']);
    } catch (error) {
      console.error('Error handling payment success:', error);
      // Handle error appropriately
    }
  }

  clearCartAfterPurchase(): void {
    // Clear the in-memory cart items
    this.cartItems = [];

    // Emit the updated (empty) cart to all subscribers
    this.cartItemsSubject.next([...this.cartItems]);

    // Remove cart data from localStorage
    localStorage.removeItem('cart');
    localStorage.removeItem('cartId');
  }
}
