import { Component, OnInit } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { AttendeeInfo, CartItem, DetailedCartItem, isTicketCartItem, RequiredField, TicketCartItem } from '../../../models/cart/cart.model';
import { CartService } from '../../../services/cart/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { CardTemplateComponent } from '../card-template/card-template.component';
import { TicketService } from '../../../services/ticket/ticket.service';
import { RegistrationService } from '../../../services/registration/registration.service';
import { FormArray, FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfig, FormField } from '../../../models/edit-dialog/edit-dialog.model';
import { GenericEditDialogComponent } from '../generic-edit-dialog/generic-edit-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';


@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    CardTemplateComponent,
    MatExpansionModule,    
    MatTooltip,
    RouterLink,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent implements OnInit {
  cartItems$: Observable<CartItem[]>;

  detailedCartItems: DetailedCartItem[] = [];
  groupedItems: { eventName: string; items: DetailedCartItem[] }[] = [];
  totalPriceCents: number = 0;

  selectedIndex: number = 0; // Tracks the current active tab index
  tabCount: number = 0; // Total number of tabs  Dynamically change this based on roles and permissions 

  dataSource = new MatTableDataSource<DetailedCartItem>([]);
  displayedColumns: string[] = ['event', 'item', 'price', 'quantity', 'total', 'remove']; // Updated columns

  constructor(
    private cartService: CartService,
    private ticketService: TicketService,
    private registrationService: RegistrationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,

  ) {
    this.cartItems$ = this.cartService.getCartItems();
  }

  ngOnInit(): void {
    this.loadCartItems()
  }

  loadCartItems(): void {
    this.cartItems$
      .pipe(
        switchMap((items) => {
          if (items.length === 0) {
            this.detailedCartItems = [];
            this.dataSource.data = [];
            this.totalPriceCents = 0;
            return of([]);
          }

          const detailedItemsObservables = items.map((item) => {
            if (item.type === 'ticket') {
              return this.ticketService.getTicketWithPrice(item.ticketPriceId).pipe(
                map((details) => ({
                  cartItem: item,
                  eventName: details.ticket_id.event_id.title,
                  itemName: details.ticket_id.title,
                  priceCents: details.price,
                  priceName: details.label,
                  requiredFields: {
                    "fields": [
                      {
                        "name": "fullName",
                        "label": "Full Name",
                        "type": "text",
                        "required": true
                      },
                      {
                        "name": "email",
                        "label": "Email Address",
                        "type": "email",
                        "required": true
                      },
                      {
                        "name": "club",
                        "label": "Club Affiliation",
                        "type": "text",
                        "required": true
                      }
                    ]
                  }.fields
                })),
                catchError((error) => {
                  console.error('Error fetching ticket details:', error);
                  return of(null); // Handle error by returning null
                })
              );
            } /*else if (item.type === 'registration') {
              return this.registrationService.getRegistrationWithPrice(item.registrationPriceId).pipe(
                map((details) => ({
                  cartItem: item,
                  eventName: details.eventName,
                  itemName: details.registrationType,
                  priceCents: details.priceCents,
                  priceName: details.priceName, // Optional
                })),
                catchError((error) => {
                  console.error('Error fetching registration details:', error);
                  return of(null); // Handle error by returning null
                })
              );
            }*/
            return of(null); // For unknown types
          });

          return forkJoin(detailedItemsObservables).pipe(
            map((detailedItems) => detailedItems.filter((item) => item !== null) as DetailedCartItem[])
          );
        })
      )
      .subscribe((detailedItems) => {
        this.detailedCartItems = detailedItems;
        this.dataSource.data = detailedItems;
        this.groupItemsByEvent();
        this.calculateTotalPrice();
      });
  }

   // New method to group items by eventName using pure TypeScript
   groupItemsByEvent(): void {
    const groups = this.detailedCartItems.reduce((acc, item) => {
      const eventName = item.eventName;
      if (!acc[eventName]) {
        acc[eventName] = [];
      }
      acc[eventName].push(item);
      return acc;
    }, {} as { [key: string]: DetailedCartItem[] });

    // Convert the groups object into an array for easier iteration in the template
    this.groupedItems = Object.keys(groups).map((eventName) => ({
      eventName,
      items: groups[eventName],
    }));
  }



  onAttendeeFieldChange(detailedItem: DetailedCartItem, attendeeIndex: number): void {
    if (!isTicketCartItem(detailedItem.cartItem)) {
      return;
    }
    const attendee = detailedItem.cartItem.attendees[attendeeIndex];
    this.cartService.updateAttendee(detailedItem.cartItem.id, attendee, attendeeIndex);
  }



  calculateTotalPrice(): void {
    this.totalPriceCents = this.detailedCartItems.reduce(
      (total, item) => total + item.priceCents * item.cartItem.quantity,
      0
    );
  }

  removeItem(itemId: string): void {
    this.cartService.removeFromCart(itemId);
    this.snackBar.open('Item removed from cart.', 'Close', { duration: 2000 });
  }

  updateQuantity(itemId: string, quantity: number): void {
    if (quantity < 1) {
      this.removeItem(itemId);
      return;
    }
    this.cartService.updateQuantity(itemId, quantity);
    this.snackBar.open('Quantity updated.', 'Close', { duration: 2000 });
  }

  clearCart(): void {
    this.cartService.clearCart();
    this.snackBar.open('Cart cleared.', 'Close', { duration: 2000 });
  }

  proceedToCheckout(): void {
    // Ensure all attendee information is filled by verifying data in detailedCartItems
    const allAttendeesFilled = this.detailedCartItems.every(item => {
      if (isTicketCartItem(item.cartItem)) {
        const ticketItem = item.cartItem;
        const requiredFields = item.requiredFields || [];
        return ticketItem.attendees.every(attendee =>
          requiredFields.every(field =>
            field.required ? !!attendee[field.name] : true
          )
        );
      }
      return true; // For non-ticket items
    });
  
    if (allAttendeesFilled) {
      // Proceed to checkout with the validated data
      this.snackBar.open('Proceeding to checkout...', 'Close', { duration: 2000 });
      this.router.navigate(['/checkout']);
    } else {
      this.snackBar.open('Please fill out all required attendee information.', 'Close', { duration: 3000 });
    }
  }


  // Open the edit dialog for attendee information
  editAttendees(detailedItem: DetailedCartItem, selectedIndex: number): void {
    if (!isTicketCartItem(detailedItem.cartItem)) {
      this.snackBar.open('Cannot edit attendees for this item.', 'Close', { duration: 3000 });
      return;
    }

    const ticketItem = detailedItem.cartItem as TicketCartItem;

    const config: DialogConfig = {
      title: 'Edit Attendee '+ (selectedIndex+1) ,
      fields: detailedItem.requiredFields || [],
      isPriceDialog: false,
      data: { attendees: ticketItem.attendees[selectedIndex] },
      isBatchEdit: false
    };

    const dialogRef = this.dialog.open(GenericEditDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: config
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        // Update each attendee's information
          this.cartService.updateAttendee(ticketItem.id, result, selectedIndex);
        this.snackBar.open('Attendee information updated.', 'Close', { duration: 2000 });
        // Optionally, refresh the cart to display updated information
        console.log(this.detailedCartItems)
      }
    });
  }

  // Optional: Convert cents to dollars for display if needed elsewhere
  getTotalPriceDollars(totalPriceCents: number): number {
    return totalPriceCents / 100;
  }

  isTicketCartItem(item: CartItem): item is TicketCartItem {
    return item.type === 'ticket';
  }

   // Helper method to create an array based on quantity
   getQuantityArray(quantity: number): number[] {
    return Array.from({ length: quantity }, (_, i) => i + 1);
  }
    // Swipe event handlers
    onSwipeLeft() {
      if (this.selectedIndex < this.tabCount - 1) {
        this.selectedIndex++;
      }
    }
  
    onSwipeRight() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    }
}