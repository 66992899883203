<div class="event-management-container">
    <h3>Add Events</h3>
    <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Event Name</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="eventForm.get('name')?.hasError('required')">
          Event name is required
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Fee ($)</mat-label>
        <input matInput type="number" formControlName="fee" required>
        <mat-error *ngIf="eventForm.get('fee')?.hasError('required')">
          Fee is required
        </mat-error>
        <mat-error *ngIf="eventForm.get('fee')?.hasError('min')">
          Fee must be a positive number
        </mat-error>
      </mat-form-field>
  
      <mat-checkbox formControlName="isFlatFee">Apply Flat Fee for Multiple Events</mat-checkbox>
  
      <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">
        {{ isLoading ? 'Adding...' : 'Add Event' }}
      </button>
    </form>
  
    <!-- Display Existing Events -->
    <div class="events-list" *ngIf="events.length > 0">
      <h4>Existing Events</h4>
      <mat-list>
        <mat-list-item *ngFor="let event of events">
          <div matLine>
            <strong>{{ event.name }}</strong> - ${{ event.fee }} 
            <span *ngIf="event.is_flat_fee">(Flat Fee)</span>
          </div>
          <div matLine>{{ event.description }}</div>
          <!-- Add delete button or edit functionality as needed -->
        </mat-list-item>
      </mat-list>
    </div>
  </div>