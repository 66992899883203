<div class="ada-page">
    <h1>Accessibility and Assistance</h1>
    <p>At mosaic-sports, we are committed to providing a website that is accessible to the widest possible audience, regardless of technology or ability. We aim to comply with all applicable standards.</p>
  
    <h2>Our Commitment</h2>
    <p>We want to best serve our community and ensure everyone can access our services with ease. If you are experiencing difficulty using our website, we are here to help.</p>
  
    <h2>Contact Us for Assistance</h2>
    <p>We do our best to make the site ADA compliant and are open to suggestions. Your feedback is invaluable in helping us improve the accessibility of our site. If you encounter any issues or have any suggestions on how we can improve the accessibility of our site, please reach out to us. We are here to assist you and appreciate your feedback.</p>

    <p>If you need assistance with account creation or event registration, please do not hesitate to contact us. We are here to help you navigate through the process and ensure you can fully participate in our events and activities.</p>
  
    <p>You can contact us through the following methods:</p>
    <ul class="contact-info">
      <li>By email: <a href="mailto:ada@mosaic-sports.com">ada&#64;mosaic-sports.com</a></li>
      <li>By phone: 347-746-7653</li>
    </ul>
    
    <h2>Thank You</h2>
    <p>Thank you for visiting mosaic-sports. We appreciate your support and are committed to providing an inclusive and accessible experience for all users.</p>
  </div>
  